import { Delete, Edit, Visibility } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Popover } from '@mui/material';

import moment, { invalid } from 'moment';
import { Link } from 'react-router-dom';

export const columns = [
  {
    field: 'vehicleId',
    headerName: 'Plaka',
    width: 150,
  },
  { field: 'firm', headerName: 'Firma', width: 150 },
  { field: 'policyNumber', headerName: 'Poliçe No', width: 150 },
  {
    field: 'typeId',
    headerName: 'Sigorta Türü',
    width: 250,
    renderCell: (e) =>
      e.row.typeId === '0F8AMKSAV84FG'
        ? 'Zorunlu Trafik Sigortası'
        : e.row.typeId === '0F8AMHDTV84FF'
          ? 'Kasko'
          : e.row.typeId === '0F8AJBREQ84FD'
            ? 'Koltuk Sigortası'
            : 'Belirsiz',
  },
  {
    field: 'validityEndsAt',
    headerName: 'Bitiş Tarihi',
    width: 100,
    renderCell: (e) => moment(e.row.validityEndsAt).format('DD.MM.YYYY'),
  },

  {
    field: 'actions',
    headerName: '',
    width: 50,
    notFilter: true,
    renderCell: (e) => (
      <Link to={`/fleet/vehicles/edit/${e.row.vehicleId}`}>
        <IconButton size="small">
          <Edit />
        </IconButton>
      </Link>
    ),
  },
];
