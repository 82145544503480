export const inspection_fields = () => [
    {
        key: "inspectionFirm",
        type: "text",
        validation: (value) => {
            if (!value) return true
            else false
        },
        placeHolder: "Muayene Firması*",
        defaultValue: null,
    },
    {
        key: "inspectionValidityMileage",
        type: "number",
        validation: (value) => {
            if (!value) return true
            else false
        },
        placeHolder: "Muayene  Kilometre*",
        defaultValue: null,
    },
    {
        key: "inspectionPolicyNumber",
        type: "text",
        validation: (value) => {
            if (!value) return true
            else false
        },
        placeHolder: "Muayene Poliçe Numarası*",
        defaultValue: null,
    },
    // {
    //     key: "inspectionValidityBeginsAt",
    //     type: "date",
    //     validation: (value) => {
    //         if (!value) return true
    //         else false
    //     },
    //     placeHolder: "Muayene  Başlangıç Tarihi*",
    //     defaultValue: null,
    // },
    {
        key: "inspectionValidityEndsAt",
        type: "date",
        validation: (value) => {
            if (!value) return true
            else false
        },
        placeHolder: "Muayene  Bitiş Tarihi*",
        defaultValue: null,
    },
]