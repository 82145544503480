import React, { useEffect, useState, useContext } from 'react';
import { DashboardContainer } from '../../components/dashboard';
import { useBoundStore } from '../../stores/index';
import { Modal, Box, Typography } from '@mui/material';
import { appConfig } from '../../constants/appConfig';
import { websocket_config } from '../../constants/wsConfig';
import logoSmall from '../../assets/images/logoSmall.svg';
import { toast } from 'react-toastify';
import FullLayout from '../../layouts/fullLayout';
import instances from '../../constants/instances';
import { Commute, Message } from '@mui/icons-material';
const sx = {
  fontSize: '80px',
};

const productsData = [
  // {
  //   title: 'Tanımlamalar',
  //   image: Wrench,
  //   to: '/definitions/dashboard',
  // },
  {
    title: 'Filo Yönetimi',
    icon: <Commute sx={sx} color="secondary" />,
    to: '/fleet/dashboard',
  },
  {
    title: 'Destek Talebi',
    icon: <Message sx={sx} color="secondary" />,
    to: '/kayral/dashboard',
  },
  // {
  //   title: 'Kavi',
  //   image: Sentry,
  //   to: '/kavi/dashboard',
  // },
  // {
  //   title: 'Fuel',
  //   image: Fuel,
  //   to: '/fuel/dashboard',
  // },
  // {
  //   title: 'Umay',
  //   image: Kids,
  //   to: '/umay/dashboard',
  // },
  // {
  //   title: 'Fleet',
  //   image: Fleet,
  //   to: '/fleet/dashboard',
  // },
  // {
  //   title: 'Dispatch',
  //   image: Dispatch,
  //   to: '/dispatch/dashboard',
  // },
  // {
  //   title: 'GeoMarketing',
  //   image: GeoMarketing,
  //   to: '/geomarketing/dashboard',
  // },
  // {
  //   title: 'HOS',
  //   image: Wrench,
  //   to: '/hos/dashboard',
  // },
  // {
  //   title: 'Staff',
  //   image: Staff,
  //   to: '/staff/dashboard',
  // },
];

const Products = () => {
  const modalOpen = JSON.parse(localStorage.getItem('modalOpen'));
  const setModalOpen = useBoundStore((state) => state.setModalOpen);
  const setAppConfig = useBoundStore((state) => state.setAppConfig);
  const setWsConfig = useBoundStore((state) => state.setWsConfig);

  const handleInstanceSelection = (selectedInstance) => {
    setAppConfig(appConfig[selectedInstance.config]);
    setWsConfig(websocket_config.url[selectedInstance.config]);
    localStorage.setItem('modalOpen', 'false');
    localStorage.setItem(
      'selectedInstance',
      JSON.stringify([selectedInstance]),
    );
    setModalOpen(false);
    toast.success(
      `Uygulamaya yönlendiriliyorsunuz. (${selectedInstance.name})`,
    );
    window.location.reload();
  };

  return (
    <FullLayout>
      <Typography variant="h4" gutterBottom sx={{ paddingX: '1.25rem' }}>
        {`Merhaba, ${JSON.parse(localStorage.getItem('user')).user.id}!`}
      </Typography>
      <DashboardContainer data={productsData} />
      {/* <EventContainer /> */}
      <Modal open={modalOpen}>
        <Box
          style={{
            outline: 'none',
            position: 'absolute',
            top: '50%',
            left: '50%',
            borderRadius: 30,
            transform: 'translate(-50%, -50%)',
            width: 500,
            minHeight: 300,
            backgroundColor: 'white',
            boxShadow: 24,
            padding: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="h5"
            color="initial"
            gutterBottom
            textAlign={'center'}
          >
            Hangi uygulamaya giriş yapmak istiyorsunuz?
          </Typography>

          <div className="flex flex-wrap w-full justify-center items-center mb-3">
            {instances.map((instance, index) => (
              <div
                key={index}
                className="flex flex-col w-36 justify-center items-center p-2 m-5 cursor-pointer hover:scale-110 transition ease 1s drop-shadow-2xl shadow-lg rounded-lg"
                onClick={() => handleInstanceSelection(instance)}
              >
                <span className="font-semibold m-5">{instance.name}</span>
              </div>
            ))}
          </div>
          <div className="flex justify-end items-center">
            <img className="mr-5 w-24" src={logoSmall} alt="Yerlem" />
          </div>
        </Box>
      </Modal>
    </FullLayout>
  );
};

export default Products;
