import axios from 'axios';
import cookie from '../../core/utils/cookie';
import { auth } from '../../core/utils/auth';
import { HeaderTypes } from './headers';

const createInstance = (baseURL, tokenName) => {
  const headers = {
    [HeaderTypes.ContentType]: 'application/json',
  };

  if (cookie.getCookie(tokenName)) {
    headers['Authorization'] = cookie.getCookie(tokenName);
  }

  return axios.create({
    baseURL,
    headers,
    timeout: 300000,
  });
};

const httpHelper = {
  async makeRequest(
    baseURL,
    endPoint,
    requestParameters,
    tokenName,
    config = {},
  ) {
    const instance = createInstance(baseURL, tokenName);
    const requestType = requestParameters.getRequestType();
    const payload = requestParameters.getPayload();
    const pageable = requestParameters.getPageable();
    const parameters = requestParameters.getParameters();

    const pageParams = {
      sort: pageable?.getSort() ? pageable.getSort().getKey() + ',' + pageable.getSort().getSortType() : undefined,
      page: pageable?.getPage() ? pageable.getPage() : undefined,
      size: pageable?.getSize() ? pageable.getSize() : undefined,
    };

    try {
      const res = await instance({
        method: requestType,
        url: endPoint,
        data: payload,
        params: {
          ...parameters,
          ...pageParams,
        },
        ...config,
      });
      return res;
    } catch (error) {
      this.errorHandling(error);
      return null;
    }
  },

  errorHandling(error) {
    let errorMessage = 'Bulunamadı';
    if (error.response) {
      errorMessage = error.response.data?.message;
      switch (error.response.status) {
        case 401:
          auth.logOut();
          break;
        case 404:
          errorMessage = 'Bulunamadı';
          break;
        default:
          break;
      }
    } else {
      errorMessage = error.message;
    }
    console.error(errorMessage);
  },

  // async makeGetRequest(
  //   baseURL = appConfig.baseUrl,
  //   endPoint,
  //   params = {},
  //   tokenName,
  // ) {
  //   return this.makeRequest(RequestType.GET, baseURL, endPoint, tokenName, undefined, { params, });
  // },

  // async makeGetRequest(
  //   baseURL = appConfig.baseUrl,
  //   endPoint,
  //   params = {},
  //   tokenName,
  //   pageable,
  // ) {

  //   const pageParams = {
  //     sort: pageable ? pageable.getSort().getKey() + ',' + pageable.getSort().getSortType() : undefined,
  //     page: pageable ? pageable.getPage() : undefined,
  //     size: pageable ? pageable.getSize() : undefined,
  //   };

  //   return this.makeRequest(
  //     RequestType.GET,
  //     baseURL,
  //     endPoint,
  //     tokenName,
  //     undefined,
  //     {
  //       params: {
  //         ...params,
  //         ...pageParams,
  //       },
  //     },
  //   );
  // },

  // async makePostRequest(
  //   baseURL,
  //   endPoint,
  //   payload = {},
  //   tokenName,
  //   config = {},
  // ) {
  //   return this.makeRequest(
  //     RequestType.POST,
  //     baseURL,
  //     endPoint,
  //     tokenName,
  //     payload,
  //     config,
  //   );
  // },

  // async makePutRequest(baseURL, endPoint, payload = {}, tokenName) {
  //   return this.makeRequest(RequestType.PUT, baseURL, endPoint, tokenName, payload);
  // },

  // async makePatchRequest(baseURL, endPoint, payload = {}, tokenName) {
  //   return this.makeRequest(RequestType.PATCH, baseURL, endPoint, tokenName, payload);
  // },

  // async makeDeleteRequest(baseURL, endPoint, tokenName) {
  //   return this.makeRequest(RequestType.DELETE, baseURL, endPoint, tokenName);
  // },
};

export default httpHelper;

/* const httpHelper = {
  async makeGetRequest(
    baseURL = appConfig.baseUrl,
    endPoint,
    params = {},
    tokenName,
  ) {
    const headers = {
      'content-type': 'application/json',
    };

    const options = {
      baseURL,
      headers,
      timeout: 40000,
    };

    if (cookie.getCookie(tokenName))
      headers['Authorization'] = cookie.getCookie(tokenName);

    const instance = axios.create(options);
    let url = `${endPoint}`;

    const res = await instance
      .get(url, { params })
      .catch((error) => this.errorHandling(error));

    return res;
  },

  async makePostRequest(
    baseURL,
    endPoint,
    payload = {},
    tokenName,
    config = {},
  ) {
    const headers = {
      'content-type': 'application/json',
    };

    const options = {
      baseURL,
      headers,
      timeout: 10000,
      ...config,
    };

    if (cookie.getCookie(tokenName))
      headers['Authorization'] = cookie.getCookie(tokenName);

    const instance = axios.create(options);
    const res = await instance
      .post(endPoint, payload)
      .catch((error) => this.errorHandling(error));

    return res;
  },

  async makePutRequest(baseURL, endPoint, payload = {}, tokenName) {
    const headers = {
      'content-type': 'application/json',
    };

    const options = {
      baseURL,
      headers,
      timeout: 10000,
    };

    if (cookie.getCookie(tokenName))
      headers['Authorization'] = cookie.getCookie(tokenName);

    const instance = axios.create(options);
    const res = await instance
      .put(endPoint, payload)
      .catch((error) => this.errorHandling(error));

    return res;
  },

  async makePatchRequest(baseURL, endPoint, payload = {}, tokenName) {
    const headers = {
      'content-type': 'application/json',
    };

    const options = {
      baseURL,
      headers,
      timeout: 10000,
    };

    if (cookie.getCookie(tokenName))
      headers['Authorization'] = cookie.getCookie(tokenName);

    const instance = axios.create(options);
    const res = await instance
      .patch(endPoint, payload)
      .catch((error) => this.errorHandling(error));

    return res;
  },

  async makeDeleteRequest(baseURL, endPoint, tokenName) {
    const headers = {
      'content-type': 'application/json',
    };

    const options = {
      baseURL,
      headers,
      timeout: 10000,
    };

    if (cookie.getCookie(tokenName))
      headers['Authorization'] = cookie.getCookie(tokenName);

    const instance = axios.create(options);
    const res = await instance
      .delete(endPoint)
      .catch((error) => this.errorHandling(error));

    return res;
  },

  errorHandling(error) {
    let errorMessage = 'Bulunamadı';
    if (error.response) {
      errorMessage = error.response.data?.message;
      switch (error.response.status) {
        case 401:
          auth.logOut();
          break;
        case 404:
          errorMessage = 'Bulunamadı';
          break;
        default:
          break;
      }
    } else {
      errorMessage = error.message;
    }
    console.error(errorMessage);
  },
};

export default httpHelper; */
