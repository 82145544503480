export const columns = [
  {
    field: 'device.id',
    headerName: 'Cihaz ID',
    width: 250,
    renderCell: (data) => <span>{data?.row?.device?.id}</span>,
  },
  {
    field: 'vehicle.id',
    headerName: 'Araç Plaka',
    width: 250,
    renderCell: (data) => <span>{data?.row?.vehicle?.id}</span>,
  },
  /* { field: 'dataProtocol', headerName: 'Protokol', width: 150 },
  { field: 'dataProtocolVersion', headerName: 'Protokol Versiyonu', width: 150 },
  { field: 'company', headerName: 'Şirket', width: 150 },  */
  {
    field: 'createdAt',
    headerName: 'Oluşturma Tarihi',
    width: 250,
    notFilter: true,
    renderCell: (data) => (
      <div>
        {data?.row?.createdAt
          ? new Date(data?.row?.createdAt).toLocaleString('tr')
          : ''}
      </div>
    ),
  },
];
