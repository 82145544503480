import { RequestType } from "./requestType";
import { Pageable } from "./pageable";

/**
 *
 * @param requestType {!RequestType}
 * @param parameters {?Array.<RequestParameter>}
 * @param pageable {?Pageable}
 * @param payload {?Object}
 * @constructor
 */
function RequestParameters(requestType,
    parameters,
    pageable,
    payload) {
    if (requestType == null) {
        throw new Error("requestType undefined bir tip olamaz.")
    }

    if (!RequestType.isValid(requestType)) {
        throw new Error("requestType kabul edilebilir tir tür değil. (bkz:RequestType)")
    }

    const _requestType = requestType;

    if (pageable != null && !(pageable instanceof Pageable)) {
        throw new Error("pageable parametresi Pageable sınıfından olmalı.")
    }

    const _pageable = pageable
    const _payload = payload
    const _parameters = parameters

    this.getRequestType = function () {
        return _requestType
    }

    this.getPageable = function () {
        return _pageable
    }

    this.getPayload = function () {
        return _payload
    }

    this.getParameters = function () {
        return _parameters
    }

    this.getParametersAsObject = function () {
        const object = {}
        if (this.getParameters() != null) {
            this.getParameters().forEach(param => {
                object[param.getKey()] = param.getValue()
            })
        }
        return object
    }
}

RequestParameters.prototype.toUrl = function () {
    let url = ""
    if (this.getParameters() != null && this.getPageable() != null) {
        url += "?"
    }

    let isAmpersandAdded = false
    if (this.getParameters() != null) {
        this.getParameters().forEach(param => {
            url += param.getKey() + "=" + param.getValue() + "&"
            if (!isAmpersandAdded) {
                isAmpersandAdded = true
            }
        })
    }

    if (isAmpersandAdded) {
        url = url.substring(0, url.length - 1);
        isAmpersandAdded = false
    }

    if (this.getPageable() != null) {
        url += this.getPageable().toUrl(!isAmpersandAdded)
    }

    return url
}

RequestParameters.prototype.isPageable = function () {
    if (!this.getPageable()) {
        return false;
    }

    return (this.getPageable() instanceof Pageable);
}

export { RequestParameters }