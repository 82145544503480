import React from 'react';
import {
  TextField,
  Autocomplete,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { DateTimePicker, TimePicker, DatePicker } from '@mui/x-date-pickers';
import CircularProgress from '@mui/material/CircularProgress';

function CustomInput({
  type,
  fullWidth,
  keys,
  size,
  label,
  error,
  value,
  options,
  getOptionLabel,
  readOnly,
  sx = {},
  items,
  style,
  minDateTime,
  maxDateTime,
  onChange,
  errorText,
  loading,
  multiple,
}) {
  return (
    <div className="w-full">
      {type === 'select' ? (
        <FormControl fullWidth={fullWidth} sx={sx}>
          <InputLabel id={keys} size={size || 'medium'}>
            {label}
          </InputLabel>
          <Select
            size={size || 'medium'}
            fullWidth={fullWidth}
            sx={{ minWidth: 248 }}
            id={keys}
            value={value}
            onChange={(e, n = '') => onChange(n, e.target.value)}
            label={label}
            style={style}
          >
            {items?.map((item) => (
              <MenuItem value={item.value} key={Math.random()}>
                {item.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : type === 'date' ? (
        <DatePicker
          style={style}
          label={label}
          ampm="false"
          size={'small'}
          value={value !== null ? new Date(value) : value}
          onChange={(e, n = '') => onChange(n, e)}
          slotProps={{
            textField: { readOnly: true, style: { minWidth: '284px' } },
          }}
          fullWidth={fullWidth}
          mindatetime={minDateTime ?? minDateTime}
          maxatetime={maxDateTime ?? maxDateTime}
        />
      ) : type === 'time' ? (
        <TimePicker
          style={style}
          label={label}
          ampm={false}
          value={value}
          onChange={(e, n = '') => onChange(n, e)}
          renderInput={(params) => (
            <TextField sx={sx} fullWidth size={size || 'medium'} {...params} />
          )}
          size={size}
          minDateTime={minDateTime ?? minDateTime}
          maxDateTime={maxDateTime ?? maxDateTime}
        />
      ) : type === 'datetimeOffset' ? (
        <DateTimePicker
          label={label}
          ampm={false}
          value={value}
          onChange={(e, n = '') => onChange(n, e)}
          minDateTime={minDateTime ?? minDateTime}
          maxDateTime={maxDateTime ?? maxDateTime}
          disableIgnoringDatePartForTimeValidation
          inputFormat="dd.MM.yyyy hh:mm"
          placeholder="dd.MM.yyyy hh:mm"
          mask="__.__.____ __:__"
        />
      ) : type === 'auto' ? (
        <Autocomplete
          sx={sx}
          disablePortal
          options={options}
          value={value || []}
          fullWidth
          multiple={multiple}
          loading={loading}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={
            value ? (option, value) => option.id === value.id : false
          }
          renderInput={(params) => {
            return readOnly ? (
              <TextField
                {...params}
                InputProps={{
                  readOnly: true,
                }}
                label={label}
              />
            ) : (
              <TextField
                {...params}
                fullWidth
                size={size || 'medium'}
                label={label}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            );
          }}
          onChange={onChange}
        />
      ) : (
        <TextField
          sx={sx}
          type={type}
          size={size}
          label={label}
          variant="outlined"
          fullWidth={fullWidth}
          value={value}
          error={error}
          helperText={errorText}
          InputProps={{
            readOnly: readOnly,
          }}
          onChange={(e, n = '') => onChange(n, e.target.value)}
        />
      )}
    </div>
  );
}

export default CustomInput;
