import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../ui-sdk/core/utils/auth';
import { toast } from 'react-toastify';
import useAuthStore from '../stores/auth-store';
import cookie from '../ui-sdk/core/utils/cookie';
import { authService } from '../pages/auth/services/authServices';
import Header from '../components/Header/Header';
import Calendar from '../components/Calendar';
import TimeLine from '../components/Timeline';
import { useBoundStore } from '../stores/index';
import MapShot from '../components/MapShot/MapShot';

import { Route, Routes } from 'react-router-dom';
import routes from '../router/routes';
import PermissionToRender from '../components/Permission/PermissionToRender';

function IsLoggedIn() {
  const location = useLocation();
  const navigate = useNavigate();

  const { setIsLoggedIn, setUser, setPermissions } = useAuthStore();
  const APP_CONFIG = useBoundStore((state) => state.appConfig);
  const instance = JSON.parse(localStorage.getItem('instance'));
  const mapShot = useBoundStore((state) => state.mapShot);

  const isReportPage =
    location.pathname.includes('weekly-report') ||
    location.pathname.includes('transportation-report');

  const check = (i) => {
    if (i === 'amir-al0') {
      return APP_CONFIG;
    } else if (i === 'amir-tr0') {
      return APP_CONFIG;
    }
  };

  //const func = check(instance[0]?.instance.id);

  const checkExpiredAt = () => {
    const t1 = new Date();
    const t2 = new Date(parseInt(cookie.getCookie('expiresAt')));
    const dif = t2.getTime() - t1.getTime();

    if (dif < 2) {
      toast.error('Saatinizi kontrol ediniz');
      auth.logOut();
    }

    setTimeout(() => {
      toast.error('Token süreniz dolmuştur');
      auth.logOut();
      navigate('/');
    }, dif);
  };

  async function getPermissions() {
    const username = JSON.parse(localStorage.getItem('user'))?.user?.id;

    const resObserver = await authService.isObserver(func, username);
    const observer = resObserver?.data?.data || false;
    if (observer) localStorage.setItem('observer', JSON.stringify(observer));
    if (!username) return;
    const result_permissions = observer
      ? await authService.getPermissionByRoleId(func, 'TE_OBSERVER')
      : await authService.getRBAC(func, username);
    const permissions = result_permissions || [];
    const permissions_string = JSON.stringify(permissions);
    localStorage.setItem('permissions', permissions_string);
    checkExpiredAt();
  }

  useEffect(() => {
    //getPermissions();
  }, []);

  useEffect(() => {
    if (!auth.isLoggedIn()) {
      toast.error('Tekrar giriş yapmanız gerekmektedir.');
      auth.logOut();
      setIsLoggedIn(false);
      setUser(null);
      setPermissions(null);
      navigate('/');
    }
  }, [location]);

  const mapDivStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: -1,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${mapShot})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    overflowY: 'scroll',
    overflowX: 'hidden',
  };

  const mapSubDivStyles = {
    position: 'absolute',
    top: 0,
    zIndex: 100,
    width: '100vw',
    height: '100vh',
  };

  return (
    <div
      style={
        isReportPage
          ? {
            background: 'inherit',
          }
          : mapDivStyles
      }
    >
      {!isReportPage && (
        <MapShot
          style={{
            position: 'fixed',
            zIndex: 0,
            width: '100vw',
            height: '100vh',
          }}
        />
      )}
      <div
        style={
          isReportPage
            ? {
              background: 'inherit',
            }
            : mapSubDivStyles
        }
      >
        <Header>
          <Routes>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    route.drawerElement ? (
                      <PermissionToRender
                        instanceCanView={route.instanceCanView}
                        roleCanView={route.roleCanView}
                        permissionsCanView={route.permissionsCanView}
                      >
                        {route.drawerElement}
                      </PermissionToRender>
                    ) : (
                      <PermissionToRender
                        instanceCanView={route.instanceCanView}
                        roleCanView={route.roleCanView}
                        permissionsCanView={route.permissionsCanView}
                      >
                        <Calendar />
                        {/* <TimeLine /> */}
                      </PermissionToRender>
                    )
                  }
                />
              );
            })}
          </Routes>
        </Header>
        <Routes>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <PermissionToRender
                    instanceCanView={route.instanceCanView}
                    roleCanView={route.roleCanView}
                    permissionsCanView={route.permissionsCanView}
                  >
                    {route.element}
                  </PermissionToRender>
                }
              />
            );
          })}
        </Routes>
      </div>
    </div>
  );
}

export default IsLoggedIn;
