const RequestType = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    PATCH: "PATCH",
    DELETE: "DELETE",

    isValid: function (requestType) {
        if (requestType == null) {
            return false
        }

        switch (requestType) {
            case this.GET: return true
            case this.POST: return true
            case this.PUT: return true
            case this.DELETE: return true
            default: return false
        }
    }
}

export { RequestType }