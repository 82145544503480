import {
  ChildCare,
  Dashboard,
  DevicesOther,
  DirectionsCar,
  Engineering,
  Group,
  Inventory,
  AspectRatio,
  CarCrash,
  Security,
  Plagiarism,
  Message,
  Email,
} from '@mui/icons-material';

export const items = [
  {
    id: 0,
    parent: 'ÜRÜNLER',
    to: '/',
    child: [],
    icon: <Inventory />,
  },
  {
    id: 1,
    parent: 'FİLO YÖNETİMİ',
    to: '/fleet/dashboard',
    icon: <Engineering />,
    child: [
      // {
      //   id: 0,
      //   parent: 'ERİŞİM YÖNETİMİ',
      //   to: '/fleet/access-management',
      //   child: [
      //     {
      //       id: 0,
      //       to: '/fleet/access-management/users',
      //       name: 'Kullanıcılar',
      //     },
      //     {
      //       id: 1,
      //       to: '/fleet/access-management/roles',
      //       name: 'Roller',
      //     },
      //     {
      //       id: 2,
      //       to: '/fleet/access-management/permissions',
      //       name: 'İzinler',
      //     },
      //   ],
      // },
      {
        id: 0,
        parent: 'Dashboard',
        to: '/fleet/dashboard',
        icon: <Dashboard />,
        child: null,
      },
      // {
      //   id: 1,
      //   parent: 'Kullanıcılar',
      //   to: '/fleet/users',
      //   icon: <SupervisedUserCircle />,
      //   child: null,
      // },
      {
        id: 1,
        parent: 'Araçlar',
        to: '/fleet/vehicles',
        icon: <DirectionsCar />,
        child: [
          {
            id: 0,
            name: 'Araçlarım',
            to: '/fleet/vehicles',
            icon: <Dashboard />,
            child: null,
          },
          {
            id: 1,
            name: 'Çekme Belgeli Araçlar',
            to: '/fleet/vehicles-off-the-road',
            icon: <DirectionsCar />,
            child: null,
          },
          {
            id: 2,
            name: 'Satılmış Araçlar',
            to: '/fleet/sold-vehicles',
            icon: <DirectionsCar />,
            child: null,
          },
          {
            id: 3,
            name: 'Excelden Araç Ekle',
            to: '/fleet/upload-multiple-vehicle',
            icon: <DirectionsCar />,
            child: null,
          },
        ],
      },
      {
        id: 2,
        parent: 'Plakalar',
        to: '/fleet/plates',
        icon: <AspectRatio />,
        child: [
          {
            id: 0,
            name: 'Plakalarım',
            to: '/fleet/plates',
            icon: <Dashboard />,
            child: null,
          },
          {
            id: 1,
            name: 'C Plakalar',
            to: '/fleet/c-plate-vehicles',
            icon: <AspectRatio />,
            child: null,
          },
          {
            id: 2,
            name: 'İlçe C Plakalar',
            to: '/fleet/district-c-plate-vehicles',
            icon: <AspectRatio />,
            child: null,
          },
        ],
      },
      {
        id: 3,
        parent: 'Muayeneler',
        to: '/fleet/inspections',
        icon: <CarCrash />,
        child: [
          {
            id: 0,
            name: 'Muayenelerim',
            to: '/fleet/inspections',
            icon: <CarCrash />,
            child: null,
          },
          {
            id: 1,
            name: 'Toplu Muayene Ekleme',
            to: '/fleet/upload-multiple-inspection',
            icon: <CarCrash />,
            child: null,
          },
        ],
      },
      {
        id: 4,
        parent: 'Sigortalar',
        to: '/fleet/insurances',
        icon: <Security />,
        child: [
          {
            id: 0,
            name: 'Sigortalarım',
            to: '/fleet/insurances',
            icon: <Dashboard />,
            child: null,
          },
          {
            id: 1,
            name: 'Toplu Sigorta Ekleme',
            to: '/fleet/upload-multiple-insurance',
            icon: <Security />,
            child: null,
          },
        ],
      },
      {
        id: 5,
        parent: 'Belgeler',
        to: '/fleet/documents',
        icon: <Plagiarism />,
        child: [
          {
            id: 0,
            name: 'Belgeler',
            to: '/fleet/documents',
            icon: <Dashboard />,
            child: null,
          },
          {
            id: 1,
            name: 'Toplu Belge Ekleme',
            to: '/fleet/upload-multiple-document',
            icon: <Plagiarism />,
            child: null,
          },
        ],
      },

      // {
      //   id: 3,
      //   parent: 'Cihazlar',
      //   to: '/fleet/devices',
      //   icon: <DevicesOther />,
      //   child: null,
      // },
      // {
      //   id: 4,
      //   parent: 'Sürücüler',
      //   to: '/fleet/drivers',
      //   icon: <Group />,
      //   child: null,
      // },
    ],
  },
  {
    id: 2,
    parent: 'DESTEK TALEBİ',
    icon: <Message />,
    to: '/kayral/dashboard',
    child: [
      {
        id: 0,
        to: '/kayral/dashboard',
        name: 'Dashboard',
        icon: <Dashboard />,
      },
      {
        id: 1,
        to: '/kayral/messages',
        name: 'Mesajlar',
        icon: <Email />,
      },

    ],
  },
  // {
  //   id: 3,
  //   parent: 'FLEET',
  //   to: '/fleet/dashboard',
  //   child: [
  //     {
  //       id: 0,
  //       to: '/fleet/dashboard',
  //       name: 'Dashboard',
  //     },
  //     {
  //       id: 1,
  //       to: '/fleet/teams',
  //       name: 'Takımlar',
  //     },
  //     {
  //       id: 2,
  //       to: '/fleet/observers',
  //       name: 'Gözlemciler',
  //     },
  //     {
  //       id: 3,
  //       to: '/fleet/entities',
  //       name: 'Varlıklar',
  //     },
  //     {
  //       id: 4,
  //       to: '/fleet/alarms',
  //       name: 'Alarmlar',
  //     },
  //   ],
  // },
  // {
  //   id: 3,
  //   parent: 'UMAY',
  //   icon: <ChildCare />,
  //   to: '/umay/dashboard',
  //   child: [
  //     {
  //       id: 0,
  //       parent: 'DASHBOARD',
  //       to: '/umay/dashboard',
  //       icon: <Dashboard />,
  //     },
  //     {
  //       id: 1,
  //       parent: 'TAKIMLAR',
  //       to: '/umay/teams',
  //       icon: <Group />,
  //       child: [
  //         {
  //           id: 0,
  //           to: '/umay/teams/index-four',
  //           name: 'Index Four',
  //           instanceCanView: ['amir-tr0'],
  //           icon: <List />,
  //         },
  //         {
  //           id: 1,
  //           to: '/umay/teams/index-five',
  //           name: 'Index Five',
  //           instanceCanView: ['amir-tr0'],
  //           icon: <List />,
  //         },
  //         {
  //           id: 0, // amir-al0 için ilk id
  //           to: '/umay/teams/index-six',
  //           name: 'Index Six',
  //           instanceCanView: ['amir-al0'],
  //           icon: <List />,
  //         },
  //       ],
  //     },
  //     {
  //       id: 2,
  //       parent: 'GÖZLEMCİLER',
  //       to: '/umay/observers',
  //       icon: <Troubleshoot />,
  //       child: [
  //         {
  //           id: 0,
  //           to: '/umay/observers/index-seven',
  //           name: 'Index Seven',
  //           icon: <List />,
  //         },
  //         {
  //           id: 1,
  //           to: '/umay/observers/index-eight',
  //           name: 'Index Eight',
  //           icon: <List />,
  //         },
  //         {
  //           id: 2,
  //           to: '/umay/observers/index-nine',
  //           name: 'Index Nine',
  //           icon: <List />,
  //         },
  //       ],
  //     },
  //     // {
  //     //   id: 3,
  //     //   parent: 'VARLIKLAR',
  //     //   to: '/umay/entities/1',
  //     //   child: [
  //     //     {
  //     //       id: 0,
  //     //       to: '/umay/entities/index-ten',
  //     //       name: 'Index Ten',
  //     //     },
  //     //     {
  //     //       id: 1,
  //     //       to: '/umay/entities/index-eleven',
  //     //       name: 'Index Eleven',
  //     //     },
  //     //     {
  //     //       id: 2,
  //     //       to: '/umay/entities/index-twelve',
  //     //       name: 'Index Twelve',
  //     //     },
  //     //   ],
  //     // },
  //     // {
  //     //   id: 4,
  //     //   parent: 'ALARMLAR',
  //     //   to: '/umay/alarms',
  //     //   child: [
  //     //     {
  //     //       id: 0,
  //     //       to: '/umay/alarms/index-thirteen',
  //     //       name: 'Index Thirteen',
  //     //     },
  //     //     {
  //     //       id: 1,
  //     //       to: '/umay/alarms/index-fourteen',
  //     //       name: 'Index Fourteen',
  //     //     },
  //     //     {
  //     //       id: 2,
  //     //       to: '/umay/alarms/index-fifteen',
  //     //       name: 'Index Fifteen',
  //     //     },
  //     //   ],
  //     // },
  //   ],
  // },
  // {
  //   id: 4,
  //   parent: 'KAVİ',
  //   icon: <SafetyCheck />,
  //   to: '/kavi/dashboard',
  //   child: [
  //     {
  //       id: 0,
  //       to: '/kavi/dashboard',
  //       name: 'Dashboard',
  //       icon: <Dashboard />,
  //     },
  //     {
  //       id: 1,
  //       name: 'Görev Güvenlik Raporları',
  //       to: '/kavi/transportation-safety-reports',
  //       instanceCanView: ['amir-al0'],
  //       icon: <EventSeat />,
  //     },
  //     {
  //       id: 2,
  //       name: 'Araç Güvenlik Raporları',
  //       to: '/kavi/vehicle-safety-reports',
  //       instanceCanView: ['amir-al0'],
  //       icon: <DirectionsBus />,
  //     },
  //     {
  //       id: 3,
  //       name: 'Filo Güvenlik Raporları',
  //       to: '/kavi/fleet-safety-reports',
  //       instanceCanView: ['amir-al0'],
  //       icon: <EmojiTransportation />,
  //     },
  //     {
  //       id: 1, //  amir-tr0 için ilk id
  //       name: 'Kart Raporları',
  //       to: '/kavi/card-reports',
  //       instanceCanView: ['amir-tr0'],
  //       icon: <TransferWithinAStation />,
  //     },
  //     {
  //       id: 2, //  amir-tr0 için ikinci id
  //       name: 'Görev Raporları',
  //       to: '/kavi/voyage-reports',
  //       instanceCanView: ['amir-tr0'],
  //       icon: <AirportShuttle />,
  //     },
  //   ],
  // },
  // {
  //   id: 5,
  //   parent: 'FUEL',
  //   icon: <LocalGasStation />,
  //   to: '/fuel/dashboard',
  //   child: [
  //     {
  //       id: 0,
  //       to: '/fuel/dashboard',
  //       name: 'Dashboard',
  //       icon: <Dashboard />,
  //     },
  //   ],
  // },
  // {
  //   id: 6,
  //   parent: 'BİLDİRİMLER',
  //   to: '/notifications/dashboard',
  //   child: [
  //     {
  //       id: 0,
  //       to: '/notifications/dashboard',
  //       name: 'Dashboard',
  //     },
  //     {
  //       id: 1,
  //       to: '/notifications/index-five',
  //       name: 'Index Five',
  //     },
  //     {
  //       id: 2,
  //       to: '/notifications/index-six',
  //       name: 'Index Six',
  //     },
  //   ],
  // },
];
