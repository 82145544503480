import { Edit, Visibility } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { randomNumberBetween } from '@mui/x-data-grid/utils/utils';

import moment from 'moment';
import { Link } from 'react-router-dom';

function calculateDateDifference(startDateStr) {
  // Başlangıç tarihini moment objesine dönüştür
  var startDate = moment(startDateStr, 'DD.MM.YYYY');
  // console.log(startDate);
  // Bugünkü tarihi al
  var currentDate = moment();

  // İki tarih arasındaki farkı hesapla
  var difference = moment.duration(startDate.diff(currentDate));
  var daysDifference = difference.asDays();

  // Sonucu döndür
  return Math.floor(daysDifference);
}

export const columns = [
  {
    field: 'plate',
    headerName: 'Plaka',
    width: 100,
  },
  { field: 'capacity', headerName: 'Kapasite', width: 90 },
  {
    field: 'purchased',
    headerName: 'Satın alınan',
    width: 140,
  },
  {
    field: 'status',
    headerName: 'Durumu',
    width: 80,
  },
  {
    field: 'plateType',
    headerName: 'Plaka Tipi',
    width: 100,
    renderCell: (e) => {
      const plateTypes = e.row.plateType;
      const plateType = plateTypes.name;
      return plateType;
    },
  },
  {
    field: 'selfOwned',
    headerName: 'Özmal',
    width: 70,
    renderCell: (e) => {
      return e.row.selfOwned ? 'EVET' : 'HAYIR';
    },
  },
  {
    field: 'contratEndDate',
    headerName: 'Sözleşme Bitiş',
    width: 130,
    renderCell: (e) => {
      const dateParse = moment(e.row.contratEndDate).format('DD.MM.YYYY');
      return dateParse;
    },
  },
  {
    field: 'actions',
    headerName: '',
    width: 90,
    align: 'center',
    notFilter: true,
    renderCell: (e) => (
      <>
        <Link to={`/fleet/plates/edit/${e.row.plateId}`}>
          <IconButton size="small">
            <Edit />
          </IconButton>
        </Link>
      </>
    ),
  },
];
