import { SortType } from "./sortType";

const DEFAULT_PAGEABLE_PARAMETERS = {
    PAGE: 0,
    SIZE: 20,
    PAGE_KEY: "page",
    SIZE_KEY: "size",
    SORT_KEY: "sort",
}

/**
 *
 * @param page {?Number.<Int>}
 * @param size {?Number<Int>}
 * @param sort {?Sort}
 * @constructor
 */
function Pageable(page, size, sort) {
    const _page = (page == null) ? DEFAULT_PAGEABLE_PARAMETERS.PAGE : page
    const _size = (size == null) ? DEFAULT_PAGEABLE_PARAMETERS.SIZE : size
    const _sort = sort

    this.getPage = function () { return _page }
    this.getSize = function () { return _size }
    this.getSort = function () { return _sort }
}

/**
 * @param appendAmpersand {?Boolean}
 */
Pageable.prototype.toUrl = function (appendAmpersand) {
    let url = ""
    if (appendAmpersand != null && appendAmpersand) {
        url += "&"
    }

    url += DEFAULT_PAGEABLE_PARAMETERS.PAGE_KEY + "=" + this.getPage() + "&"
    url += DEFAULT_PAGEABLE_PARAMETERS.SIZE_KEY + "=" + this.getSize()
    if (this.getSort() != null) {
        url += "&"
        url += DEFAULT_PAGEABLE_PARAMETERS.SORT_KEY + "="
            + this.getSort().getKey() + "," + this.getSort().getSortType()
    }

    return url
}

export { Pageable }