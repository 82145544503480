import React, { useEffect, useState } from 'react';
import { DashboardContainer } from '../../../components/dashboard';
import EventContainer from '../../../components/Events/EventContainer';

import FullLayout from '../../../layouts/fullLayout';
import Email from '@mui/icons-material/Email';

import Typography from '@mui/material/Typography';
import {
  DirectionsBusOutlined,
  CarCrash,
  Security,
  AspectRatio,
  Plagiarism,
} from '@mui/icons-material';

const sx = {
  fontSize: '80px',
};

const fleetData = [
  {
    title: 'Mesajlar',
    icon: <Email sx={sx} color="secondary" />,
    to: '/kayral/messages',
  },
];

const Kayral = () => {
  return (
    <FullLayout>
      <Typography variant="h4" gutterBottom sx={{ paddingX: '1.25rem' }}>
        {`Merhaba, ${JSON.parse(localStorage.getItem('user')).user.id}!`}
      </Typography>
      <DashboardContainer data={fleetData} />
      {/* <EventContainer /> */}
    </FullLayout>
  );
};

export default Kayral;
