const SortType = {
    ASC: "asc",
    DESC: "desc",

    isValid: function (sortType) {
        if (sortType == null) {
            return false
        }

        switch (sortType) {
            case this.ASC: return true
            case this.DESC: return true
            default: return false
        }
    }
}

export {SortType}