import React, { useEffect, useState } from 'react';
import { DashboardContainer } from '../../../components/dashboard';
import EventContainer from '../../../components/Events/EventContainer';

import FullLayout from '../../../layouts/fullLayout';

import Typography from '@mui/material/Typography';
import {
  DirectionsBusOutlined,
  CarCrash,
  Security,
  AspectRatio,
  Plagiarism,
} from '@mui/icons-material';

const sx = {
  fontSize: '80px',
};

const fleetData = [
  // {
  //   title: 'Kullanıcılar',
  //   icon: <GroupOutlined sx={sx} color="secondary" />,
  //   to: '/fleet/users',
  // },
  {
    title: 'Araçlar',
    icon: <DirectionsBusOutlined sx={sx} color="secondary" />,
    to: '/fleet/vehicles',
  },
  {
    title: 'Plakalar',
    icon: <AspectRatio sx={sx} color="secondary" />,
    to: '/fleet/plates',
  },
  {
    title: 'Muayeneler',
    icon: <CarCrash sx={sx} color="secondary" />,
    to: '/fleet/inspections',
  },
  {
    title: 'Sigortalar',
    icon: <Security sx={sx} color="secondary" />,
    to: '/fleet/insurances',
  },
  {
    title: 'Belgeler',
    icon: <Plagiarism sx={sx} color="secondary" />,
    to: '/fleet/documents',
  },
  // {
  //   title: 'Cihazlar',
  //   icon: <DevicesOutlined sx={sx} color="secondary" />,
  //   to: '/fleet/devices',
  // },
  // {
  //   title: 'Sürücüler',
  //   icon: <GroupsOutlined sx={sx} color="secondary" />,
  //   to: '/fleet/drivers',
  // },
];

const Fleet = () => {
  return (
    <FullLayout>
      <Typography variant="h4" gutterBottom sx={{ paddingX: '1.25rem' }}>
        {`Merhaba, ${JSON.parse(localStorage.getItem('user')).user.id}!`}
      </Typography>
      <DashboardContainer data={fleetData} />
      {/* <EventContainer /> */}
    </FullLayout>
  );
};

export default Fleet;
