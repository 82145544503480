const createUserLocationStore = (set) => ({
  mapShot: null,
  setMapShot: (mapShot) => {
    set(() => ({ mapShot }));
  },
  userLocation: [37.874641, 32.493156],
  setUserLocation: (userLocation) => {
    set(() => ({ userLocation }));
  },
  startWatchingLocation: () => {
    if (navigator.geolocation) {
      const watchId = navigator.geolocation.watchPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          set((state) => ({ userLocation: [latitude, longitude] }));
        },
        (error) => {
          console.error('Error getting user location:', error);
          // If there is an error, set the default location
          set((state) => ({ userLocation: [37.874641, 32.493156] }));
        },
      );
      return watchId;
    } else {
      // If geolocation is not supported, set the default location
      set((state) => ({ userLocation: [37.874641, 32.493156] }));
      return null; // Return null as there's no watchId
    }
  },
  stopWatchingLocation: (watchId) => {
    if (watchId) {
      navigator.geolocation.clearWatch(watchId);
    }
  },
});

export default createUserLocationStore;
