import { Delete, Edit } from '@mui/icons-material';
import moment from 'moment';

export const columns = [
  { field: 'chassisNumber', headerName: 'Şase No', width: 120 },
  {
    field: 'insuranceFirm',
    headerName: 'Sigorta Firması',
    width: 150,
    renderCell: (e) => {
      const insuranceFirm = e.row.insurances.map((insurance) => {
        return insurance.firm;
      });
      return <div className="w-full pl-4">{insuranceFirm}</div>;
    },
  },
  {
    field: 'insuranceTypeId',
    headerName: 'Muayene Tipi',
    width: 220,
    renderCell: (e) => {
      const insuranceTypeId = e.row.insurances.map((insurance) => {
        return insurance.typeId;
      });
      const insuranceType =
        insuranceTypeId[0] === '0F8AJBREQ84FD'
          ? 'KOLTUK SİGORTASI'
          : insuranceTypeId[0] === '0F8AMKSAV84FG'
            ? 'ZORUNLU TRAFİK POLİÇESİ'
            : insuranceTypeId[0] === '0F8AMHDTV84FF'
              ? 'KASKO'
              : 'BİLİNMİYOR';
      return <div className="w-full pl-4">{insuranceType}</div>;
    },
  },
  {
    field: 'insurancePolicyNumber',
    headerName: 'Poliçe No',
    width: 150,
    renderCell: (e) => {
      const insurancePolicyNumber = e.row.insurances.map((insurance) => {
        return insurance.policyNumber;
      });
      return <div className="w-full pl-4">{insurancePolicyNumber}</div>;
    },
  },
  {
    field: 'insuranceEndDate',
    headerName: 'Muayene Bitiş Tarihi',
    width: 200,
    renderCell: (e) => {
      const insuranceEndDate = e.row.insurances.map((insurance) => {
        return insurance.validityEndsAt;
      });
      return (
        <div className="w-full pl-4">
          {moment(insuranceEndDate).format('DD/MM/YYYY')}
        </div>
      );
    },
  },
  {
    field: 'actions',
    headerName: '',
    width: 60,
    renderCell: (params) => {
      return (
        <div>
          <Edit />
          <Delete />
        </div>
      );
    },
  },
];
