import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import services from '../service';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  CompareArrows,
} from '@mui/icons-material';

export default function TStepper(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const {
    steps,
    children,
    optionalSteps,
    formData,
    setData,
    getDatas,
    helperText,
    fieldsEmpty,
    realitonData,
    setModalOpen,
  } = props;

  const isStepOptional = (step) => {
    return optionalSteps?.includes(step);
  };

  const savePlate = async () => {
    const data = setData();
    const plateId = data.plateId;
    const plate = data.plate.toUpperCase();
    const plateType = data.plateType;
    const capacity = data.capacity;
    const selfOwned = data.selfOwned;
    const contrat = data.contrat;
    const contratEndDate =
      data.plateId !== null
        ? data.contratEndDate
        : data.contratEndDate.toIsoString();
    const description = data.description;
    const purchased = data.purchased;
    const status = data.status;
    const createdAt = data.createdAt;
    const updatedData = {
      plateId: plateId,
      plate: plate,
      plateType: {
        plateTypeId: plateType,
      },
      capacity: capacity,
      selfOwned: selfOwned,
      contrat: contrat,
      contratEndDate: contratEndDate,
      description: description,
      purchased: purchased,
      status: status,
      createdAt: createdAt,
    };
    const newData = {
      plate: plate,
      plateType: {
        plateTypeId: plateType,
      },
      capacity: capacity,
      selfOwned: selfOwned,
      contrat: contrat,
      contratEndDate: contratEndDate,
      description: description,
      purchased: purchased,
      status: status,
    };
    const result = await services.savePlate(plateId ? updatedData : newData);
    if (result?.status === 200) {
      toast.success('Plaka başarılı bir şekilde kayıt edildi');
      getDatas();
      handleNext();
    } else {
      toast.error('Plaka daha önce kayıt edilmiş.');
    }
    return result;
  };

  React.useEffect(() => {
    getDatas();
  }, []);

  const saveRelation = async () => {
    const vehicleId = realitonData?.vehicleId?.id;
    const plateId = realitonData?.plateId?.id;
    const plateRelationData = {
      status: true,
      plate: {
        plateId: plateId,
      },
      vehicle: {
        id: vehicleId,
      },
    };
    const result = await services.plateVehicleRelationSave(plateRelationData);
    if (result?.status === 200) {
      toast.success('Eşleştirme başarılı bir şekilde kayıt edildi');
      location.pathname.includes('edit')
        ? navigate('/fleet/plates')
        : (setModalOpen(false), window.location.reload());
    } else {
      toast.error('Eşleştirme başarısız');
    }
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const dropNulls = (array) => {
    return array.filter((item) => item !== null);
  };

  const postData = async (payload) => {
    const pay = {
      ...payload,
      insurances: dropNulls(payload.insurances),
      inspections: dropNulls(payload.inspections),
    };

    const result = await services.postCollectionVehicleData(pay);
    return result;
  };

  const saveFormData = () => {
    const data = setData();
    try {
      // console.log(data)
      postData(data)
        .then((res) => {
          if (res.data.response) {
            toast.error(
              'Plaka, Motor No veya Şasi No daha önce kayıt edilmiş. Lütfen kontrol ediniz.',
            );
            location.pathname.includes('edit')
              ? navigate('/fleet/vehicles')
              : setModalOpen(false);
          } else {
            toast.success('Başarılı bir şekilde kayıt edildi');
            location.pathname.includes('edit')
              ? navigate('/fleet/vehicles')
              : setModalOpen(false);
          }
        })
        .catch((err) => {});
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Opsiyonel</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
          <div>{children[activeStep]}</div>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              startIcon={<KeyboardArrowLeft />}
              variant="outlined"
              sx={{ mr: 1 }}
            >
              GERİ
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {/* {isStepOptional(activeStep) && (
                            activeStep === steps.length - 1 ? null : (
                                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                    Atla
                                </Button>
                            )
                        )} */}

            {activeStep === steps.length - 1 ? (
              <Button
                startIcon={<CompareArrows />}
                variant="contained"
                onClick={saveRelation}
              >
                EŞLEŞTİR
              </Button>
            ) : (
              <Button
                endIcon={<KeyboardArrowRight />}
                disabled={helperText !== '' || fieldsEmpty ? true : false}
                variant="contained"
                onClick={savePlate}
              >
                İLERİ
              </Button>
            )}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
