import httpHelper from '../../../../ui-sdk/core/service/httpHelper';
const APP_CONFIG = JSON.parse(localStorage.getItem('appConfig'));
import { RequestParameters } from '../../../../ui-sdk/core/service/requestParameters';

const service = {
  // DEVICES SERVICES

  // async getDevices(params) {
  //   const endPoint = 'device';
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     params,
  //     'Bearer',
  //   );
  //   return result;
  // },
  // async getDevicesWithSearch(query) {
  //   const endpoint = 'device/search';
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endpoint,
  //     query,
  //     'Bearer',
  //   );
  //   return result;
  // },
  async getDeviceVehicleRelationWithSearch(params, pageable) {
    const requestParameters = new RequestParameters(
      'GET',
      params,
      pageable,
      null,
    );
    const endpoint = 'device-vehicle-relation/search';
    const result = await httpHelper.makeRequest(
      APP_CONFIG,
      endpoint,
      requestParameters,
      'Bearer',
    );
    return result;
  },
  // async getDevicesWithFilterData(params) {
  //   const endPoint = `device/findByLikeIgnoreCase`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     params,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async saveDevice(payload) {
  //   const endPoint = 'device';
  //   const result = await httpHelper.makePostRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async getDevice(deviceId) {
  //   const endPoint = `device/${deviceId}`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     {},
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async updateDevice(payload) {
  //   const endPoint = `device`;
  //   const result = await httpHelper.makePutRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async deleteDevice(deviceId) {
  //   const endPoint = `devices/${deviceId}`;
  //   const result = await httpHelper.makeDeleteRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // // DEVİCE METADATA SERVİCES

  // async getDeviceMetadatas(params) {
  //   const endPoint = 'device-metadata';
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     params,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async saveDeviceMetadata(payload) {
  //   const endPoint = 'device-metadata';
  //   const result = await httpHelper.makePostRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async getDeviceMetadata(deviceMetadataId) {
  //   const endPoint = `device-metadata/${deviceMetadataId}`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     {},
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async getDeviceMetadataByDeviceId(deviceId) {
  //   const endPoint = `device-metadata/findByDeviceId/${deviceId}`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     {},
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async updateDeviceMetadata(deviceMetadataId, payload) {
  //   const endPoint = `device-metadata/${deviceMetadataId}`;
  //   const result = await httpHelper.makePatchRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async deleteDeviceMetadata(deviceMetadataId) {
  //   const endPoint = `device-metadata/${deviceMetadataId}`;
  //   const result = await httpHelper.makeDeleteRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // // DEVICE METADATA KEY SERVICES

  // async getDeviceMetadataKeys(params) {
  //   const endPoint = 'device-metadata-key';
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     params,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async saveDeviceMetadataKey(payload) {
  //   const endPoint = 'device-metadata-key';
  //   const result = await httpHelper.makePostRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async getDeviceMetadataKey(deviceMetadataKeyId) {
  //   const endPoint = `device-metadata-key/${deviceMetadataKeyId}`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     {},
  //     'Bearer',
  //   );
  //   return result;
  // },
  // async updateDeviceMetadataKey(payload) {
  //   const endPoint = `device-metadata-key/`;
  //   const result = await httpHelper.makePatchRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async deleteDeviceMetadataKey(deviceMetadataKeyId) {
  //   const endPoint = `device-metadata-key/${deviceMetadataKeyId}`;
  //   const result = await httpHelper.makeDeleteRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // // DEVİCE GROUPS SERVİCES

  // async getDeviceGroups(params) {
  //   const endPoint = 'device-group';
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     params,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async saveDeviceGroups(payload) {
  //   const endPoint = 'device-group';
  //   const result = await httpHelper.makePostRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async getDeviceGroup(deviceGroupId) {
  //   const endPoint = `device-group/${deviceGroupId}`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     {},
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async updateDeviceGroups(deviceGroupId, payload) {
  //   const endPoint = `device-group/${deviceGroupId}`;
  //   const result = await httpHelper.makePatchRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async deleteDeviceGroups(deviceGroupId) {
  //   const endPoint = `device-group/${deviceGroupId}`;
  //   const result = await httpHelper.makeDeleteRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // // DEVİCE GROUPS RELATIONS SERVİCES

  // async getDeviceGroupRelations(params) {
  //   const endPoint = 'device-group-relation';
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     params,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async saveDeviceGroupRelations(payload) {
  //   const endPoint = 'device-group-relation';
  //   const result = await httpHelper.makePostRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async getDeviceGroupRelation(deviceGroupRelationId) {
  //   const endPoint = `device-group-relation/${deviceGroupRelationId}`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     {},
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async getDeviceGroupRelationByDeviceId(deviceId) {
  //   const endPoint = `device-group-relation/findByDeviceId/${deviceId}`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     {},
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async updateDeviceGroupRelation(payload) {
  //   const endPoint = `device-group-relation`;
  //   const result = await httpHelper.makePatchRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async deleteDeviceGroupRelation(deviceGroupRelationId) {
  //   const endPoint = `device-group-relation/${deviceGroupRelationId}`;
  //   const result = await httpHelper.makeDeleteRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // // DEVICE VEHICLE RELATION SERVICES

  // async getDeviceVehicleRelations(params) {
  //   const endPoint = 'device-vehicle-relation';
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     params,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async saveDeviceVehicleRelations(payload) {
  //   const endPoint = 'device-vehicle-relation';
  //   const result = await httpHelper.makePostRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async getDeviceVehicleRelation(deviceVehicleRelationId) {
  //   const endPoint = `device-vehicle-relation/${deviceVehicleRelationId}`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     {},
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async getDeviceVehicleRelationByDeviceId(deviceId) {
  //   const endPoint = `device-vehicle-relation/findVehicleByDeviceId/${deviceId}`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     {},
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async updateDeviceVehicleRelations(deviceVehicleRelationId, payload) {
  //   const endPoint = `device-vehicle-relation/${deviceVehicleRelationId}`;
  //   const result = await httpHelper.makePatchRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async deleteDeviceVehicleRelations(deviceVehicleRelationId) {
  //   const endPoint = `device-vehicle-relation/${deviceVehicleRelationId}`;
  //   const result = await httpHelper.makeDeleteRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     'Bearer',
  //   );
  //   return result;
  // },
  // async getVehicles(params) {
  //   const endPoint = 'vehicle';
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     params,
  //     'Bearer',
  //   );
  //   return result;
  // },
};

export default service;
