export const fields = () => [
  {
    key: 'plate',
    type: 'text',
    validation: (value) => {
      if (!value) return true;
      else false;
    },
    placeHolder: 'Plaka',
    defaultValue: null,
  },
  {
    key: 'capacity',
    type: 'number',
    validation: (value) => {
      if (!value) return true;
      else false;
    },
    placeHolder: 'Kapasite',
    defaultValue: null,
  },
  {
    key: 'selfOwned',
    type: 'select',
    items: [
      { value: true, text: 'Evet' },
      { value: false, text: 'Hayır' },
    ],
    validation: (value) => {
      if (!value) return true;
      else false;
    },
    placeHolder: 'Özmal',
    defaultValue: null,
  },
  {
    key: 'contrat',
    type: 'select',
    items: [
      { value: true, text: 'Var' },
      { value: false, text: 'Yok' },
    ],
    validation: (value) => {
      if (!value) return true;
      else false;
    },
    placeHolder: 'Kontrat',
    defaultValue: null,
  },
  {
    key: 'purchased',
    type: 'text',
    validation: (value) => {
      if (!value) return true;
      else false;
    },
    placeHolder: 'Satın Alınan',
    defaultValue: null,
  },
  {
    key: 'status',
    type: 'select',
    items: [
      { value: true, text: 'Aktif' },
      { value: false, text: 'Pasif' },
    ],
    validation: (value) => {
      if (!value) return true;
      else false;
    },
    placeHolder: 'Durum',
    defaultValue: null,
  },

  {
    key: 'contratEndDate',
    type: 'date',
    validation: (value) => {
      if (!value) return true;
      else false;
    },
    placeHolder: 'Kontrat Bitiş Tarihi',
    defaultValue: null,
  },
  {
    key: 'description',
    type: 'text',
    validation: (value) => {
      if (!value) return true;
      else false;
    },
    placeHolder: 'Açıklama',
    defaultValue: null,
  },
  {
    key: 'plateType',
    type: 'select',
    items: [
      { value: '0FKZN57EQPB94', text: 'C PLAKALAR' },
      { value: '0FM9C0ESF0HW0', text: 'İLÇE C PLAKALAR' },
      { value: '0FM9C6HHB0HW1', text: 'NORMAL PLAKALAR' },
    ],
    validation: (value) => {
      if (!value) return true;
      else false;
    },
    placeHolder: 'Plaka Tipi',
    defaultValue: null,
  },
];
