import React from 'react';
import { useState, useEffect } from 'react';
import services from '../service/index';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import moment from 'moment';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function VehicleProfile() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [fieldsEmpty, setFieldsEmpty] = useState(false);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [vehicleFormData, setVehicleFormData] = React.useState({
    plate: '',
    debitUserId: '',
    engineNumber: '',
    chasis: '',
    brand: '',
    model: '',
    modelYear: '',
    type: '',
    load: '',
    fuelFactoryPerformance: '',
    coolant: '',
    description: '',
    status: '',
    availableFirm: '',
    documentSerialNo: '',
    document: '',
    purposeOfUsage: '',
    vehicleClass: '',
    queueNo: '',
  });
  const [statusType, setStatusType] = React.useState({
    statusTypeId: '',
  });

  const getCoolant = (value) => {
    if (value == '1') {
      return 'Var';
    } else if (value == '0') {
      return 'Yok';
    } else if (value == '-1') {
      return 'Bilinmiyor';
    }
  };

  const getStatus = (value) => {
    if (value === true) {
      return 'Aktif';
    } else if (value === false) {
      return 'Pasif';
    }
  };
  const [seatInsuranceFormData, setSeatInsuranceFormData] = React.useState({
    insuranceTypeId: '0F8AJBREQ84FD',
    insuranceFirm: '',
    // insuranceValidityMileage: null,
    insurancePolicyNumber: '',
    // insuranceValidityBeginsAt: null,
    insuranceValidityEndsAt: null,
  });
  const [kaskoInsuranceFormData, setKaskoInsuranceFormData] = React.useState({
    insuranceTypeId: '0F8AMHDTV84FF',
    insuranceFirm: '',
    // insuranceValidityMileage: null,
    insurancePolicyNumber: '',
    // insuranceValidityBeginsAt: null,
    insuranceValidityEndsAt: null,
  });
  const [trafficInsuranceFormData, setTrafficInsuranceFormData] =
    React.useState({
      insuranceTypeId: '0F8AMKSAV84FG',
      insuranceFirm: '',
      // insuranceValidityMileage: null,
      insurancePolicyNumber: '',
      // insuranceValidityBeginsAt: null,
      insuranceValidityEndsAt: null,
    });

  const [vehicleInspectionFormData, setVehicleInspectionFormData] =
    React.useState({
      inspectionTypeId: '0F8AN274Q84FH',
      inspectionFirm: '',
      inspectionValidityMileage: null,
      inspectionPolicyNumber: '',
      // inspectionValidityBeginsAt: null,
      inspectionValidityEndsAt: null,
    });
  const [exhaustInspectionFormData, setExhaustInspectionFormData] =
    React.useState({
      inspectionTypeId: '0F8AN4NA384FJ',
      inspectionFirm: '',
      inspectionValidityMileage: null,
      inspectionPolicyNumber: '',
      // inspectionValidityBeginsAt: null,
      inspectionValidityEndsAt: null,
    });
  const [formData, setFormData] = React.useState({
    ...vehicleFormData,
    insurances: {
      ...seatInsuranceFormData,
      ...kaskoInsuranceFormData,
      ...trafficInsuranceFormData,
    },
    inspections: {
      ...vehicleInspectionFormData,
      ...exhaustInspectionFormData,
    },
  });

  function isFieldsEmpty() {
    if (
      vehicleFormData.chasis === '' ||
      vehicleFormData.plate === '' ||
      vehicleFormData.engineNumber === ''
    ) {
      setFieldsEmpty(true);
      return true;
    } else {
      setFieldsEmpty(false);
      return false;
    }
  }

  const getVehicleData = async () => {
    setIsLoading(true);
    const response = await services.getVehicleByVehicleId(id);
    if (response) {
      setVehicleFormData(response.data);
      setSeatInsuranceFormData(
        response.data.insurances.find(
          (insurance) => insurance.insuranceTypeId === '0F8AJBREQ84FD',
        ) || seatInsuranceFormData,
      );
      setKaskoInsuranceFormData(
        response.data.insurances.find(
          (insurance) => insurance.insuranceTypeId === '0F8AMHDTV84FF',
        ) || kaskoInsuranceFormData,
      );
      setTrafficInsuranceFormData(
        response.data.insurances.find(
          (insurance) => insurance.insuranceTypeId === '0F8AMKSAV84FG',
        ) || trafficInsuranceFormData,
      );
      setVehicleInspectionFormData(
        response.data.inspections.find(
          (inspection) => inspection.inspectionTypeId === '0F8AN274Q84FH',
        ) || vehicleInspectionFormData,
      );
      setExhaustInspectionFormData(
        response.data.inspections.find(
          (inspection) => inspection.inspectionTypeId === '0F8AN4NA384FJ',
        ) || exhaustInspectionFormData,
      );
      setStatusType({ statusTypeId: response.data.statusType.statusTypeId });
      console.log(
        'seatInsuranceFormData',
        response.data.insurances.find(
          (insurance) => insurance.insuranceTypeId === '0F8AJBREQ84FD',
        ) || seatInsuranceFormData,
      );
      console.log(
        'kaskoInsuranceFormData',
        response.data.insurances.find(
          (insurance) => insurance.insuranceTypeId === '0F8AMHDTV84FF',
        ) || kaskoInsuranceFormData,
      );
      console.log(
        'trafficInsuranceFormData',
        response.data.insurances.find(
          (insurance) => insurance.insuranceTypeId === '0F8AMKSAV84FG',
        ) || trafficInsuranceFormData,
      );
      console.log(
        'vehicleInspectionFormData',
        response.data.inspections.find(
          (inspection) => inspection.inspectionTypeId === '0F8AN274Q84FH',
        ) || vehicleInspectionFormData,
      );
      console.log(
        'exhaustInspectionFormData',
        response.data.inspections.find(
          (inspection) => inspection.inspectionTypeId === '0F8AN4NA384FJ',
        ) || exhaustInspectionFormData,
      );
      console.log('response', response);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getVehicleData();
    setData();
    console.log(formData);
  }, []);

  useEffect(() => {
    isFieldsEmpty();
  }, [
    vehicleFormData.chasis,
    vehicleFormData.plate,
    vehicleFormData.engineNumber,
  ]);

  const checkInsuranceFields = (data) => {
    if (
      data.insuranceFirm != '' ||
      data.insurancePolicyNumber != '' ||
      data.insuranceValidityBeginsAt != null ||
      data.insuranceValidityEndsAt != null
    ) {
      return data;
    }
  };

  const checkInspectionFields = (data) => {
    if (
      data.inspectionFirm != '' ||
      data.inspectionPolicyNumber != '' ||
      data.inspectionValidityBeginsAt != null ||
      data.inspectionValidityEndsAt != null
    ) {
      return data;
    }
  };

  const setData = () => {
    const data = {
      ...vehicleFormData,
      statusType: statusType,
      insurances: [
        checkInsuranceFields(seatInsuranceFormData)
          ? checkInsuranceFields(seatInsuranceFormData)
          : null,
        checkInsuranceFields(kaskoInsuranceFormData)
          ? checkInsuranceFields(kaskoInsuranceFormData)
          : null,
        checkInsuranceFields(trafficInsuranceFormData)
          ? checkInsuranceFields(trafficInsuranceFormData)
          : null,
      ],
      inspections: [
        checkInspectionFields(vehicleInspectionFormData)
          ? checkInspectionFields(vehicleInspectionFormData)
          : null,
        checkInspectionFields(exhaustInspectionFormData)
          ? checkInspectionFields(exhaustInspectionFormData)
          : null,
      ],
    };
    setFormData({
      ...vehicleFormData,
      statusType: statusType,
      insurances: [
        checkInsuranceFields(seatInsuranceFormData)
          ? checkInsuranceFields(seatInsuranceFormData)
          : null,
        checkInsuranceFields(kaskoInsuranceFormData)
          ? checkInsuranceFields(kaskoInsuranceFormData)
          : null,
        checkInsuranceFields(trafficInsuranceFormData)
          ? checkInsuranceFields(trafficInsuranceFormData)
          : null,
      ],
      inspections: [
        checkInspectionFields(vehicleInspectionFormData)
          ? checkInspectionFields(vehicleInspectionFormData)
          : null,
        checkInspectionFields(exhaustInspectionFormData)
          ? checkInspectionFields(exhaustInspectionFormData)
          : null,
      ],
    });
    return data;
  };

  return (
    <div className="p-3 m-3 flex flex-col justify-center items-center">
      <div className="p-3 m-3 flex flex-col justify-center items-center">
        <div className="w-full 2xl:w-max flex flex-col items-center p-3 m-3">
          <div className=" py-3 w-full mx-3 my-2 flex flex-wrap  justify-between max-sm:justify-center gap-3 items-center">
            <span className="text-lg sm:text-2xl max-sm:text-center">
              Araç : {vehicleFormData?.plate}
            </span>
            <Button
              variant="contained"
              startIcon={<ModeEditOutlineIcon />}
              color="primary"
              onClick={() => navigate(`/fleet/vehicles/edit/${id}`)}
              className="bg-[#A7B5F1] text-white px-4 py-2 rounded-md cursor-pointer hover:scale-110 transition ease 1s drop-shadow-2xl shadow-lg mr-1"
            >
              Düzenle
            </Button>
          </div>
          <div className="w-full mx-2 my-5 bg-white rounded-xl shadow-xl flex justify-between items-center">
            <Box
              sx={{
                width: '100%',
                bgcolor: 'background.paper',
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
              >
                <Tab label="Bilgiler" />
                <Tab label="Muayeneler" />
                <Tab label="Sigortalar" />
                <Tab label="Belgeler" />
              </Tabs>
              {
                // RUHSAT BİLGİLERİ
                <TabPanel
                  className="flex flex-col gap-3 items-center justify-center w-full"
                  value={value}
                  index={0}
                >
                  <div className="relative w-full flex items-center justify-center">
                    <img
                      src="https://www.svgrepo.com/show/278811/bus.svg"
                      alt="vehicle"
                      className="w-40 h-40 py-4 my-4"
                    />
                  </div>
                  <div className="w-full flex-col sm:flex-col md:flex-col lg:flex-col xl:flex-row 2xl:flex-row flex gap-3 items-center justify-center">
                    <table className="border-[#A7B5F1] border-[15px] w-full ">
                      <tr className="w-full flex">
                        <td className=" h-12 w-full flex justify-center items-center border-[1px] border-black">
                          <input
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] "
                            placeholder="(Y.1) VERİLDİĞİ İL/İLÇE"
                            disabled
                          ></input>
                        </td>
                      </tr>
                      <tr className="w-full flex">
                        <td className=" h-12 w-1/2 flex flex-col justify-center items-center border-[1px] border-black">
                          <p
                            htmlFor="plate"
                            className="w-full h-full outline-none border-none text-[12px] "
                          >
                            {' '}
                            (A) PLAKA
                          </p>
                          <input
                            name="plate"
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px]"
                            placeholder="(A) PLAKA"
                            value={vehicleFormData.plate}
                            onChange={(e) =>
                              setVehicleFormData({
                                ...vehicleFormData,
                                plate: e.target.value,
                              })
                            }
                            disabled
                          ></input>
                        </td>
                        <td className=" h-12 w-1/2 flex justify-center items-center border-[1px] border-black">
                          <input
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] "
                            placeholder="(B) İLK TESCİL TARİHİ"
                            disabled
                          ></input>
                        </td>
                      </tr>
                      <tr className="w-full flex">
                        <td className=" h-12 w-1/2 flex flex-col justify-center items-center border-[1px] border-black">
                          <label
                            htmlFor="queueNo"
                            className="w-full h-full outline-none border-none text-[12px] "
                          >
                            (Y.2) TESCİL SIRA NO
                          </label>
                          <input
                            name="queueNo"
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] "
                            placeholder="(Y.2) TESCİL SIRA NO"
                            value={vehicleFormData.queueNo}
                            onChange={(e) =>
                              setVehicleFormData({
                                ...vehicleFormData,
                                queueNo: e.target.value,
                              })
                            }
                            disabled
                          ></input>
                        </td>
                        <td className=" h-12 w-1/2 flex justify-center items-center border-[1px] border-black">
                          <input
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] "
                            placeholder="(I) TESCİL TARİHİ"
                            disabled
                          ></input>
                        </td>
                      </tr>
                      <tr className="w-full flex">
                        <td className=" h-12 w-1/2 flex flex-col justify-center items-center border-[1px] border-black">
                          <label
                            htmlFor="brand"
                            className="w-full h-full outline-none border-none text-[12px] "
                          >
                            (D.1) MARKASI
                          </label>
                          <input
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] "
                            placeholder="(D.1) MARKASI"
                            value={vehicleFormData.brand}
                            onChange={(e) =>
                              setVehicleFormData({
                                ...vehicleFormData,
                                brand: e.target.value,
                              })
                            }
                            disabled
                          ></input>
                        </td>
                        <td className=" h-12 w-1/2 flex flex-col justify-center items-center border-[1px] border-black">
                          <label
                            htmlFor="type"
                            className="w-full h-full outline-none border-none text-[12px] "
                          >
                            (D.2) TİPİ
                          </label>
                          <input
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] "
                            placeholder="(D.2) TİPİ"
                            value={vehicleFormData.type}
                            onChange={(e) =>
                              setVehicleFormData({
                                ...vehicleFormData,
                                type: e.target.value,
                              })
                            }
                            disabled
                          ></input>
                        </td>
                      </tr>
                      <tr className="w-full flex">
                        <td className=" h-12 w-1/2 flex flex-col justify-center items-center border-[1px] border-black">
                          <label
                            htmlFor="model"
                            className="w-full h-full outline-none border-none text-[12px] "
                          >
                            (~) MODELİ
                          </label>
                          <input
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] "
                            placeholder="(~) MODELİ"
                            value={vehicleFormData.model}
                            onChange={(e) =>
                              setVehicleFormData({
                                ...vehicleFormData,
                                model: e.target.value,
                              })
                            }
                            disabled
                          ></input>
                        </td>
                        <td className=" h-12 w-1/2 flex justify-center items-center border-[1px] border-black">
                          <td className=" h-12 w-1/2 flex flex-col justify-center items-center border-[1px] border-black">
                            <label
                              htmlFor="modelYear"
                              className="w-full h-full outline-none border-none text-[12px] "
                            >
                              (D.4) MODEL YILI
                            </label>
                            <input
                              type="text"
                              className="w-full h-full outline-none border-none p-2 text-[12px] "
                              placeholder="(D.4) MODEL YILI"
                              value={vehicleFormData.modelYear}
                              onChange={(e) =>
                                setVehicleFormData({
                                  ...vehicleFormData,
                                  modelYear: e.target.value,
                                })
                              }
                              disabled
                            ></input>
                          </td>
                          <td className=" h-12 w-1/2 flex flex-col justify-center items-center border-[1px] border-black">
                            <label
                              htmlFor="vehicleClass"
                              className="w-full h-full outline-none border-none text-[12px] "
                            >
                              {' '}
                              (J) ARAÇ SINIFI
                            </label>
                            <input
                              type="text"
                              className="w-full h-full outline-none border-none p-2 text-[12px] "
                              placeholder="(J) ARAÇ SINIFI"
                              value={vehicleFormData.vehicleClass}
                              onChange={(e) =>
                                setVehicleFormData({
                                  ...vehicleFormData,
                                  vehicleClass: e.target.value,
                                })
                              }
                              disabled
                            ></input>
                          </td>
                        </td>
                      </tr>
                      <tr className="w-full flex">
                        <td className=" h-12 w-1/2 flex justify-center items-center border-[1px] border-black">
                          <input
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] "
                            placeholder="(D.5) CİNSİ"
                            disabled
                          ></input>
                        </td>
                        <td className=" h-12 w-1/2 flex justify-center items-center border-[1px] border-black">
                          <input
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] "
                            placeholder="(R) RENGİ"
                            disabled
                          ></input>
                        </td>
                      </tr>
                      <tr className="w-full flex">
                        <td className=" h-12 w-full flex flex-col justify-center items-center border-[1px] border-black">
                          <label
                            htmlFor="engineNumber"
                            className="w-full h-full outline-none border-none text-[12px] "
                          >
                            {' '}
                            (P.5) MOTOR NO*
                          </label>
                          <input
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] "
                            placeholder="(P.5) MOTOR NO*"
                            required
                            value={vehicleFormData.engineNumber}
                            onChange={(e) =>
                              setVehicleFormData({
                                ...vehicleFormData,
                                engineNumber: e.target.value,
                              })
                            }
                            disabled
                          ></input>
                        </td>
                      </tr>
                      <tr className="w-full flex">
                        <td className=" h-12 w-full flex flex-col justify-center items-center border-[1px] border-black">
                          <label
                            htmlFor="chasis"
                            className="w-full h-full outline-none border-none text-[12px] "
                          >
                            {' '}
                            (E) ŞASE NO*
                          </label>
                          <input
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] "
                            placeholder="(E) ŞASE NO*"
                            required
                            value={vehicleFormData.chasis}
                            onChange={(e) =>
                              setVehicleFormData({
                                ...vehicleFormData,
                                chasis: e.target.value,
                              })
                            }
                            disabled
                          ></input>
                        </td>
                      </tr>
                      <tr className="w-full flex">
                        <td className=" h-12 w-1/2 flex flex-col justify-center items-center border-[1px] border-black">
                          <label
                            htmlFor="fuelFactoryPerformance"
                            className="w-full h-full outline-none border-none text-[12px] "
                          >
                            {' '}
                            (~) YAKIT FABRİKA PERFORMANSI
                          </label>
                          <input
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] "
                            placeholder="(~) YAKIT FABRİKA PERFORMANSI"
                            value={vehicleFormData.fuelFactoryPerformance}
                            onChange={(e) =>
                              setVehicleFormData({
                                ...vehicleFormData,
                                fuelFactoryPerformance: e.target.value,
                              })
                            }
                            disabled
                          ></input>
                        </td>
                        <td className=" h-12 w-1/2 flex flex-col justify-center items-center border-[1px] border-black">
                          <label
                            htmlFor="coolant"
                            className="w-full h-full outline-none border-none text-[12px] "
                          >
                            {' '}
                            (~) KLİMA BİLGİSİ
                          </label>
                          <input
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] "
                            placeholder="(~) KLİMA BİLGİSİ"
                            value={getCoolant(vehicleFormData.coolant)}
                            onChange={(e) =>
                              setVehicleFormData({
                                ...vehicleFormData,
                                coolant: e.target.value,
                              })
                            }
                            disabled
                          ></input>
                        </td>
                      </tr>
                      <tr className="w-full flex">
                        <td className=" h-12 w-1/2 flex justify-center items-center border-[1px] border-black">
                          <input
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] "
                            placeholder="(G) KATAR AĞIRLIĞI"
                            disabled
                          ></input>
                        </td>
                        <td className=" h-12 w-1/2 flex justify-center items-center border-[1px] border-black">
                          <input
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] "
                            placeholder="(G.2) RÖMORK AZAMİ YÜKLÜ AĞIRLIĞI"
                            disabled
                          ></input>
                        </td>
                      </tr>
                      <tr className="w-full flex">
                        <td className=" h-12 w-1/2 flex flex-col justify-center items-center border-[1px] border-black">
                          <label
                            htmlFor="load"
                            className="w-full h-full outline-none border-none text-[12px] "
                          >
                            {' '}
                            (S.1) KOLTUK SAYISI (SÜR. DAHİL)
                          </label>
                          <input
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] "
                            placeholder="(S.1) KOLTUK SAYISI (SÜR. DAHİL)"
                            value={vehicleFormData.load}
                            onChange={(e) =>
                              setVehicleFormData({
                                ...vehicleFormData,
                                load: e.target.value,
                              })
                            }
                            disabled
                          ></input>
                        </td>
                        <td className=" h-12 w-1/2 flex justify-center items-center border-[1px] border-black">
                          <input
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] "
                            placeholder="(S.2) AYAKTA YOLCU SAYISI"
                            disabled
                          ></input>
                        </td>
                      </tr>
                      <tr className="w-full flex">
                        <td className=" h-12 w-1/2 flex justify-center items-center border-[1px] border-black">
                          <input
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] "
                            placeholder="(P.1) SİLİNDİR HACMİ"
                            disabled
                          ></input>
                        </td>
                        <td className=" h-12 w-1/2 flex justify-center items-center border-[1px] border-black">
                          <input
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] "
                            placeholder="(P.2) MOTOR GÜCÜ"
                            disabled
                          ></input>
                        </td>
                      </tr>
                      <tr className="w-full flex">
                        <td className=" h-12 w-1/2 flex flex-col justify-center items-center border-[1px] border-black">
                          <label
                            htmlFor="availableFirm"
                            className="w-full h-full outline-none border-none text-[12px] "
                          >
                            {' '}
                            (~) KULLANABİLİR FİRMA
                          </label>
                          <input
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] "
                            placeholder="(~) KULLANABİLİR FİRMA"
                            value={vehicleFormData.availableFirm}
                            onChange={(e) =>
                              setVehicleFormData({
                                ...vehicleFormData,
                                availableFirm: e.target.value,
                              })
                            }
                            disabled
                          ></input>
                        </td>
                        <td className=" h-12 w-1/2 flex justify-center items-center border-[1px] border-black">
                          <input
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] "
                            placeholder="(Q) GÜÇ AĞIRLIK ORANI (Motosiklet)"
                            disabled
                          ></input>
                        </td>
                      </tr>
                      <tr className="w-full flex">
                        <td className=" h-12 w-1/2 flex flex-col justify-center items-center border-[1px] border-black">
                          <label
                            htmlFor="purposeOfUsage"
                            className="w-full h-full outline-none border-none text-[12px] "
                          >
                            {' '}
                            (Y.3) KULLANIM AMACI
                          </label>
                          <input
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] "
                            placeholder="(Y.3) KULLANIM AMACI"
                            value={vehicleFormData.purposeOfUsage}
                            onChange={(e) =>
                              setVehicleFormData({
                                ...vehicleFormData,
                                purposeOfUsage: e.target.value,
                              })
                            }
                            disabled
                          ></input>
                        </td>
                        <td className=" h-12 w-1/2 flex flex-col justify-center items-center border-[1px] border-black">
                          <label
                            htmlFor="status"
                            className="w-full h-full outline-none border-none text-[12px] "
                          >
                            {' '}
                            (~) DURUM
                          </label>
                          <input
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] "
                            placeholder="(~) DURUM"
                            value={getStatus(vehicleFormData.status)}
                            onChange={(e) =>
                              setVehicleFormData({
                                ...vehicleFormData,
                                status: e.target.value,
                              })
                            }
                            disabled
                          ></input>
                        </td>
                      </tr>
                    </table>
                    <table className="border-[#A7B5F1] border-[15px] w-full">
                      <tr className="w-full flex">
                        <td className=" h-12 w-full flex justify-center items-center border-[1px] border-black">
                          <input
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] "
                            placeholder="(Y.4) TC KİMLİK NO / VERGİ NO"
                            disabled
                          ></input>
                        </td>
                      </tr>
                      <tr className="w-full flex">
                        <td className=" h-12 w-full flex justify-center items-center border-[1px] border-black">
                          <input
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] "
                            placeholder="(C.1.1) SOYADI / TİCARİ ÜNVANI"
                            disabled
                          ></input>
                        </td>
                      </tr>
                      <tr className="w-full flex">
                        <td className=" h-12 w-full flex flex-col justify-center items-center border-[1px] border-black">
                          <label
                            htmlFor="debitUserId"
                            className="w-full h-full outline-none border-none text-[12px] "
                          >
                            {' '}
                            (C.1.2) ADI
                          </label>
                          <input
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] "
                            placeholder="(C.1.2) ADI"
                            value={vehicleFormData.debitUserId}
                            onChange={(e) =>
                              setVehicleFormData({
                                ...vehicleFormData,
                                debitUserId: e.target.value,
                              })
                            }
                            disabled
                          ></input>
                        </td>
                      </tr>
                      <tr className="w-full flex">
                        <td className=" h-24 w-full flex justify-center items-center border-[1px] border-black">
                          <textarea
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] resize-none"
                            placeholder="(C.1.2) ADRESİ"
                            disabled
                          ></textarea>
                        </td>
                      </tr>
                      <tr className="w-full flex">
                        <td className="  w-1/2 flex justify-center items-center border-[1px] border-black">
                          <textarea
                            type="text"
                            className="w-full h-full outline-none border-none p-2 text-[12px] resize-none"
                            placeholder="(Z.1) ARAÇ ÜZERİNDE HAK VE MENFAATİ BULUNANLAR"
                            disabled
                          ></textarea>
                        </td>
                        <td className=" h-48 w-1/2 flex flex-col justify-center items-center border-[1px] border-black">
                          <tr className="w-full flex">
                            <td className=" h-12 w-full flex justify-center items-center border-[1px] border-black">
                              <input
                                type="text"
                                className="w-full h-full outline-none border-none p-2 text-[12px] "
                                placeholder="(Z.3.1) NOTER SATIŞ TARİHİ"
                                disabled
                              ></input>
                            </td>
                          </tr>
                          <tr className="w-full flex">
                            <td className=" h-12 w-full flex justify-center items-center border-[1px] border-black">
                              <input
                                type="text"
                                className="w-full h-full outline-none border-none p-2 text-[12px] "
                                placeholder="(Z.3.2) NOTER SATIŞ NO"
                                disabled
                              ></input>
                            </td>
                          </tr>
                          <tr className="w-full flex">
                            <td className=" h-24 w-full flex justify-center items-center border-[1px] border-black">
                              <textarea
                                type="text"
                                className="w-full h-full outline-none border-none p-2 text-[12px] resize-none"
                                placeholder="(Z.3.3) NOTERİN ADI"
                                disabled
                              ></textarea>
                            </td>
                          </tr>
                        </td>
                      </tr>
                      <tr className="w-full flex h-60">
                        <td className="   w-1/2 flex flex-col justify-center items-center border-[1px] border-black">
                          <tr className="w-full flex">
                            <td className=" h-36 w-full flex flex-col justify-center items-center border-[1px] border-black">
                              <label
                                htmlFor="description"
                                className="w-full  outline-none border-none text-[12px] "
                              >
                                {' '}
                                (Z.2) DİĞER BİLGİLER
                              </label>
                              <textarea
                                type="text"
                                className="w-full h-full outline-none border-none p-2 text-[12px] resize-none"
                                placeholder="(Z.2) DİĞER BİLGİLER"
                                value={vehicleFormData.description}
                                onChange={(e) =>
                                  setVehicleFormData({
                                    ...vehicleFormData,
                                    description: e.target.value,
                                  })
                                }
                                disabled
                              ></textarea>
                            </td>
                          </tr>
                          <tr className="w-full flex">
                            <td className=" h-24 w-full flex justify-center items-center border-[1px] border-black">
                              <textarea
                                type="text"
                                className="w-full h-full outline-none border-none p-2 text-[12px] resize-none"
                                placeholder="(Z.4) ONAYLAYAN SİCİL-İMZA"
                                disabled
                              ></textarea>
                            </td>
                          </tr>
                        </td>
                        <td className=" w-1/2 flex flex-col justify-center items-center border-[1px] border-black">
                          <tr className="w-full flex">
                            <td className=" h-48 w-full flex flex-col justify-start items-start border-[1px] border-black">
                              <label
                                htmlFor="document"
                                className="w-full outline-none border-none text-[12px] "
                              >
                                {' '}
                                (~) BELGE
                              </label>
                              <textarea
                                type="text"
                                className="w-full h-full outline-none border-none p-2 text-[12px] resize-none"
                                placeholder="(~) BELGE"
                                value={vehicleFormData.document}
                                onChange={(e) =>
                                  setVehicleFormData({
                                    ...vehicleFormData,
                                    document: e.target.value,
                                  })
                                }
                                disabled
                              ></textarea>
                            </td>
                          </tr>
                          <tr className="w-full flex">
                            <td className=" h-12 w-full flex flex-col justify-center items-center border-[1px] border-black">
                              <label
                                htmlFor="documentSerialNo"
                                className="w-full outline-none border-none text-[12px] "
                              >
                                {' '}
                                (~) BELGE SERİ NO
                              </label>
                              <input
                                type="text"
                                className="w-full h-full outline-none border-none p-2 text-[12px] "
                                placeholder="BELGE SERİ NO"
                                value={vehicleFormData.documentSerialNo}
                                onChange={(e) =>
                                  setVehicleFormData({
                                    ...vehicleFormData,
                                    documentSerialNo: e.target.value,
                                  })
                                }
                                disabled
                              ></input>
                            </td>
                          </tr>
                        </td>
                      </tr>
                    </table>{' '}
                  </div>
                </TabPanel>
              }
              {
                // SİGORTALAR
                <TabPanel
                  className="flex flex-col gap-3 items-center justify-center w-full"
                  value={value}
                  index={1}
                >
                  <div className="w-full bg-white rounded-lg shadow-xl flex-col sm:flex-col md:flex-col lg:flex-col xl:flex-row 2xl:flex-row flex gap-3 items-center justify-center">
                    <div className="w-full flex flex-col gap-3 items-center justify-center">
                      {
                        // TRAFİK SİGORTASI
                        <div className="w-full flex flex-col gap-3 items-center justify-center">
                          <span className="text-lg sm:text-2xl max-sm:text-center">
                            Trafik Sigortası
                          </span>

                          <table className="border-[#A7B5F1] border-[15px] w-full">
                            <tr className="w-full flex">
                              <td className=" h-12 w-1/2 flex flex-col justify-center items-center border-[1px] border-black">
                                <label
                                  htmlFor="insuranceFirm"
                                  className="w-full h-full outline-none border-none text-[12px] "
                                >
                                  {' '}
                                  Sigorta Firması
                                </label>

                                <input
                                  type="text"
                                  className="w-full h-full outline-none border-none p-2 text-[12px] "
                                  placeholder="Sigorta Firması"
                                  value={trafficInsuranceFormData.insuranceFirm}
                                  onChange={(e) =>
                                    setTrafficInsuranceFormData({
                                      ...trafficInsuranceFormData,
                                      insuranceFirm: e.target.value,
                                    })
                                  }
                                  disabled
                                ></input>
                              </td>
                              <td className=" h-12 w-1/2 flex justify-center items-center border-[1px] border-black">
                                <label
                                  htmlFor="insurancePolicyNumber"
                                  className="w-full h-full outline-none border-none text-[12px] "
                                >
                                  {' '}
                                  Poliçe Numarası
                                </label>
                                <input
                                  type="text"
                                  className="w-full h-full outline-none border-none p-2 text-[12px] "
                                  placeholder="Poliçe Numarası"
                                  value={
                                    trafficInsuranceFormData.insurancePolicyNumber
                                  }
                                  onChange={(e) =>
                                    setTrafficInsuranceFormData({
                                      ...trafficInsuranceFormData,
                                      insurancePolicyNumber: e.target.value,
                                    })
                                  }
                                  disabled
                                ></input>
                              </td>
                            </tr>
                            <tr className="w-full flex">
                              <td className=" h-12 w-1/2 flex flex-col justify-center items-center border-[1px] border-black">
                                <label
                                  htmlFor="insuranceValidityEndsAt"
                                  className="w-full h-full outline-none border-none text-[12px] "
                                >
                                  {' '}
                                  Bitiş Tarihi
                                </label>
                                <input
                                  type="date"
                                  className="w-full h-full outline-none border-none p-2 text-[12px] "
                                  placeholder="Bitiş Tarihi"
                                  value={moment(
                                    trafficInsuranceFormData.insuranceValidityEndsAt,
                                  ).format('YYYY-MM-DD')}
                                  onChange={(e) =>
                                    setTrafficInsuranceFormData({
                                      ...trafficInsuranceFormData,
                                      insuranceValidityEndsAt: e.target.value,
                                    })
                                  }
                                ></input>
                              </td>
                            </tr>
                          </table>
                        </div>
                      }
                      {
                        // KASKO
                        <div className="w-full flex flex-col gap-3 items-center justify-center">
                          <span className="text-lg sm:text-2xl max-sm:text-center">
                            Kasko
                          </span>

                          <table className="border-[#A7B5F1] border-[15px] w-full">
                            <tr className="w-full flex">
                              <td className=" h-12 w-1/2 flex flex-col justify-center items-center border-[1px] border-black">
                                <label
                                  htmlFor="insuranceFirm"
                                  className="w-full h-full outline-none border-none text-[12px] "
                                >
                                  {' '}
                                  Kasko Firması
                                </label>

                                <input
                                  type="text"
                                  className="w-full h-full outline-none border-none p-2 text-[12px] "
                                  placeholder="Kasko Firması"
                                  value={kaskoInsuranceFormData.insuranceFirm}
                                  onChange={(e) =>
                                    setKaskoInsuranceFormData({
                                      ...kaskoInsuranceFormData,
                                      insuranceFirm: e.target.value,
                                    })
                                  }
                                  disabled
                                ></input>
                              </td>
                              <td className=" h-12 w-1/2 flex justify-center items-center border-[1px] border-black">
                                <label
                                  htmlFor="insurancePolicyNumber"
                                  className="w-full h-full outline-none border-none text-[12px] "
                                >
                                  {' '}
                                  Poliçe Numarası
                                </label>
                                <input
                                  type="text"
                                  className="w-full h-full outline-none border-none p-2 text-[12px] "
                                  placeholder="Poliçe Numarası"
                                  value={
                                    kaskoInsuranceFormData.insurancePolicyNumber
                                  }
                                  onChange={(e) =>
                                    setKaskoInsuranceFormData({
                                      ...kaskoInsuranceFormData,
                                      insurancePolicyNumber: e.target.value,
                                    })
                                  }
                                  disabled
                                ></input>
                              </td>
                            </tr>
                            <tr className="w-full flex">
                              <td className=" h-12 w-1/2 flex flex-col justify-center items-center border-[1px] border-black">
                                <label
                                  htmlFor="insuranceValidityEndsAt"
                                  className="w-full h-full outline-none border-none text-[12px] "
                                >
                                  {' '}
                                  Bitiş Tarihi
                                </label>

                                <input
                                  type="date"
                                  className="w-full h-full outline-none border-none p-2 text-[12px] "
                                  placeholder="Bitiş Tarihi"
                                  value={moment(
                                    kaskoInsuranceFormData.insuranceValidityEndsAt,
                                  ).format('YYYY-MM-DD')}
                                  onChange={(e) =>
                                    setKaskoInsuranceFormData({
                                      ...kaskoInsuranceFormData,
                                      insuranceValidityEndsAt: e.target.value,
                                    })
                                  }
                                ></input>
                              </td>
                            </tr>
                          </table>
                        </div>
                      }
                      {
                        // KOLTUK SİGORTASI
                        <div className="w-full flex flex-col gap-3 items-center justify-center">
                          <span className="text-lg sm:text-2xl max-sm:text-center">
                            Koltuk Sigortası
                          </span>

                          <table className="border-[#A7B5F1] border-[15px] w-full">
                            <tr className="w-full flex">
                              <td className=" h-12 w-1/2 flex flex-col justify-center items-center border-[1px] border-black">
                                <label
                                  htmlFor="insuranceFirm"
                                  className="w-full h-full outline-none border-none text-[12px] "
                                >
                                  {' '}
                                  Koltuk Sigorta Firması
                                </label>

                                <input
                                  type="text"
                                  className="w-full h-full outline-none border-none p-2 text-[12px] "
                                  placeholder="  Koltuk Sigorta Firması"
                                  value={seatInsuranceFormData.insuranceFirm}
                                  onChange={(e) =>
                                    setSeatInsuranceFormData({
                                      ...seatInsuranceFormData,
                                      insuranceFirm: e.target.value,
                                    })
                                  }
                                  disabled
                                ></input>
                              </td>
                              <td className=" h-12 w-1/2 flex justify-center items-center border-[1px] border-black">
                                <label
                                  htmlFor="insurancePolicyNumber"
                                  className="w-full h-full outline-none border-none text-[12px] "
                                >
                                  {' '}
                                  Poliçe Numarası
                                </label>
                                <input
                                  type="text"
                                  className="w-full h-full outline-none border-none p-2 text-[12px] "
                                  placeholder="Poliçe Numarası"
                                  value={
                                    seatInsuranceFormData.insurancePolicyNumber
                                  }
                                  onChange={(e) =>
                                    setSeatInsuranceFormData({
                                      ...seatInsuranceFormData,
                                      insurancePolicyNumber: e.target.value,
                                    })
                                  }
                                  disabled
                                ></input>
                              </td>
                            </tr>
                            <tr className="w-full flex">
                              <td className=" h-12 w-1/2 flex flex-col justify-center items-center border-[1px] border-black">
                                <label
                                  htmlFor="insuranceValidityEndsAt"
                                  className="w-full h-full outline-none border-none text-[12px] "
                                >
                                  {' '}
                                  Bitiş Tarihi
                                </label>

                                <input
                                  type="date"
                                  className="w-full h-full outline-none border-none p-2 text-[12px] "
                                  placeholder="Bitiş Tarihi"
                                  value={moment(
                                    seatInsuranceFormData.insuranceValidityEndsAt,
                                  ).format('YYYY-MM-DD')}
                                  onChange={(e) =>
                                    setSeatInsuranceFormData({
                                      ...seatInsuranceFormData,
                                      insuranceValidityEndsAt: e.target.value,
                                    })
                                  }
                                  disabled
                                ></input>
                              </td>
                            </tr>
                          </table>
                        </div>
                      }
                    </div>
                  </div>
                </TabPanel>
              }
              {
                // MUAYENELER
                <TabPanel
                  className="flex flex-col gap-3 items-center justify-center w-full"
                  value={value}
                  index={2}
                >
                  <div className="w-full bg-white rounded-lg shadow-xl flex-col sm:flex-col md:flex-col lg:flex-col xl:flex-row 2xl:flex-row flex gap-3 items-center justify-center">
                    <div className="w-full flex flex-col gap-3 items-center justify-center">
                      {
                        // TRAFİK SİGORTASI
                        <div className="w-full flex flex-col gap-3 items-center justify-center">
                          <span className="text-lg sm:text-2xl max-sm:text-center">
                            Araç Muayenesi
                          </span>

                          <table className="border-[#A7B5F1] border-[15px] w-full">
                            <tr className="w-full flex">
                              <td className=" h-12 w-1/2 flex justify-center items-center border-[1px] border-black">
                                <label
                                  htmlFor="insurancePolicyNumber"
                                  className="w-full h-full outline-none border-none text-[12px] "
                                >
                                  {' '}
                                  Poliçe Numarası
                                </label>
                                <input
                                  type="text"
                                  className="w-full h-full outline-none border-none p-2 text-[12px] "
                                  placeholder="Poliçe Numarası"
                                  value={
                                    vehicleInspectionFormData.insurancePolicyNumber
                                  }
                                  onChange={(e) =>
                                    setVehicleInspectionFormData({
                                      ...trafficInsuranceFormData,
                                      vehicleInspectionFormData: e.target.value,
                                    })
                                  }
                                  disabled
                                ></input>
                              </td>
                              <td className=" h-12 w-1/2 flex flex-col justify-center items-center border-[1px] border-black">
                                <label
                                  htmlFor="insuranceValidityEndsAt"
                                  className="w-full h-full outline-none border-none text-[12px] "
                                >
                                  {' '}
                                  Bitiş Tarihi
                                </label>
                                <input
                                  type="date"
                                  className="w-full h-full outline-none border-none p-2 text-[12px] "
                                  placeholder="Bitiş Tarihi"
                                  value={moment(
                                    vehicleInspectionFormData.insuranceValidityEndsAt,
                                  ).format('YYYY-MM-DD')}
                                  onChange={(e) =>
                                    setVehicleInspectionFormData-({
                                      ...vehicleInspectionFormData,
                                      insuranceValidityEndsAt: e.target.value,
                                    })
                                  }
                                  disabled
                                ></input>
                              </td>
                            </tr>
                          </table>
                        </div>
                      }
                      { // EGZOZ MUAYENESİ
                         <div className="w-full flex flex-col gap-3 items-center justify-center">
                         <span className="text-lg sm:text-2xl max-sm:text-center">
                           Egzoz Muayenesi
                         </span>

                         <table className="border-[#A7B5F1] border-[15px] w-full">
                           <tr className="w-full flex">
                             <td className=" h-12 w-1/2 flex justify-center items-center border-[1px] border-black">
                               <label
                                 htmlFor="insurancePolicyNumber"
                                 className="w-full h-full outline-none border-none text-[12px] "
                               >
                                 {' '}
                                 Poliçe Numarası
                               </label>
                               <input
                                 type="text"
                                 className="w-full h-full outline-none border-none p-2 text-[12px] "
                                 placeholder="Poliçe Numarası"
                                 value={
                                   exhaustInspectionFormData.insurancePolicyNumber
                                 }
                                 onChange={(e) =>
                                   setExhaustInspectionFormData({
                                     ...exhaustInspectionFormData,
                                     insurancePolicyNumber: e.target.value,
                                   })
                                 }
                                 disabled
                               ></input>
                             </td>
                             <td className=" h-12 w-1/2 flex flex-col justify-center items-center border-[1px] border-black">
                               <label
                                 htmlFor="insuranceValidityEndsAt"
                                 className="w-full h-full outline-none border-none text-[12px] "
                               >
                                 {' '}
                                 Bitiş Tarihi
                               </label>
                               <input
                                 type="date"
                                 className="w-full h-full outline-none border-none p-2 text-[12px] "
                                 placeholder="Bitiş Tarihi"
                                 value={moment(
                                  exhaustInspectionFormData.insuranceValidityEndsAt,
                                 ).format('YYYY-MM-DD')}
                                 onChange={(e) =>
                                   setExhaustInspectionFormData({
                                     ...exhaustInspectionFormData,
                                     insuranceValidityEndsAt: e.target.value,
                                   })
                                 }
                                 disabled
                               ></input>
                             </td>
                           </tr>
                         </table>
                       </div>
                      }
                    </div>
                  </div>
                </TabPanel>
              }
            </Box>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default VehicleProfile;
