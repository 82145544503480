export const columns = [
  {
    field: 'user',
    headerName: 'Şoför Adı',
    width: 250,
    //filterEP: 'findByName'
    renderCell: (data) => {
      return <span>{data?.row?.user?.id} </span>;
    },
  },
  /* { field: 'name', 
    headerName: 'Şoför Adı', 
    width: 200, 
    //filterEP: 'findByName'
    renderCell: (data) => {
      return <span>{data?.row?.user?.firstname} {data?.row?.user?.lastname}</span>
    }
  },
  { field: 'gsm', 
    headerName: 'Telefon Numarası', 
    width: 150, 
    //filterEP: 'findByGsm'
    renderCell: (data) => {
      return <span>{data?.row?.user?.gsm}</span>
    }
  },
  { field: 'email', 
    headerName: 'Email', 
    width: 150, 
    //filterEP: 'findByEmail'
    renderCell: (data) => {
      return <span>{data?.row?.user?.email}</span>
    }
  }, */
  {
    field: 'createdAt',
    headerName: 'Oluşturulma Tarihi',
    width: 250,
    notFilter: true,
    renderCell: (e) => <div>{new Date(e.value).toLocaleString('tr')}</div>,
  },
  {
    field: 'updatedAt',
    headerName: 'Güncellenme Tarihi',
    width: 250,
    notFilter: true,
    renderCell: (e) => <div>{new Date(e.value).toLocaleString('tr')}</div>,
  },
];

export const filterList = [
  { value: 'userId', text: 'Şoför Adı' },
  /* { value: 'firstname', text: 'İsim' },
  { value: 'lastname', text: 'Soyisim' },
  { value: 'gsm', text: 'Telefon' } */
];
