import httpHelper from '../../../../ui-sdk/core/service/httpHelper';
import { appConfig } from '../../../../constants/appConfig';
import { RequestParameters } from '../../../../ui-sdk/core/service/requestParameters';

const services = {
  //  VEHICLES SERVICES

  // async getVehicles(params) {
  //   const endPoint = 'vehicle';
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     params,
  //     'Bearer',
  //   );
  //   return result;
  // },
  async getAllVehicles(params, pageable) {
    const requestParameters = new RequestParameters(
      'GET',
      params,
      pageable,
      null,
    );
    const endpoint = 'vehicle/findAllVehicle';
    const result = await httpHelper.makeRequest(
      appConfig.baseUrl,
      endpoint,
      requestParameters,
      // 'Bearer',
    );
    return result;
  },
  async getAllVehiclesWithDates(params, pageable) {
    const requestParameters = new RequestParameters(
      'GET',
      params,
      pageable,
      null,
    );
    const endpoint = '/collectionVehicleData/getAllVehicle';
    const result = await httpHelper.makeRequest(
      appConfig.baseUrl,
      endpoint,
      requestParameters,
      // 'Bearer',
    );
    return result;
  },
  async postCollectionVehicleData(payload) {
    const requestParameters = new RequestParameters('POST', {}, null, payload);
    const endpoint = 'collectionVehicleData/save';
    const result = await httpHelper.makeRequest(
      appConfig.baseUrl,
      endpoint,
      requestParameters,
      // 'Bearer',
    );
    return result;
  },
  async savePlate(payload) {
    const requestParameters = new RequestParameters('POST', {}, null, payload);
    const endpoint = 'plate/save';
    const result = await httpHelper.makeRequest(
      appConfig.baseUrl,
      endpoint,
      requestParameters,
      // 'Bearer',
    );
    return result;
  },
  async plateVehicleRelationSave(payload) {
    const requestParameters = new RequestParameters('POST', {}, null, payload);
    const endpoint = 'plateVehicleRelation/save';
    const result = await httpHelper.makeRequest(
      appConfig.baseUrl,
      endpoint,
      requestParameters,
      // 'Bearer',
    );
    return result;
  },
  async getVehicleDataByVehicleId(vehicleId) {
    const requestParameters = new RequestParameters('GET', {}, null, null);
    const endpoint = `vehicle/getVehicleById/${vehicleId}`;
    const result = await httpHelper.makeRequest(
      appConfig.baseUrl,
      endpoint,
      requestParameters,
      // 'Bearer',
    );
    return result;
  },

  async getInsuranceByVehicleId(vehicleId) {
    const requestParameters = new RequestParameters(
      'GET',
      {
        vehicleId: vehicleId,
      },
      null,
      null,
    );
    const endpoint = `insurance/getInsuranceByVehicleId`;
    const result = await httpHelper.makeRequest(
      appConfig.baseUrl,
      endpoint,
      requestParameters,
      // 'Bearer',
    );
    return result;
  },

  async getInspectionByVehicleId(vehicleId) {
    const requestParameters = new RequestParameters(
      'GET',
      {
        vehicleId: vehicleId,
      },
      null,
      null,
    );
    const endpoint = `inspection/getInspectionByVehicleId`;
    const result = await httpHelper.makeRequest(
      appConfig.baseUrl,
      endpoint,
      requestParameters,
      // 'Bearer',
    );
    return result;
  },

  async updateVehicleByVehicleId(vehicleId, payload) {
    const requestParameters = new RequestParameters('PUT', {}, null, payload);
    const endpoint = `vehicle/update/${vehicleId}`;
    const result = await httpHelper.makeRequest(
      appConfig.baseUrl,
      endpoint,
      requestParameters,
      // 'Bearer',
    );
    return result;
  },

  async updateInsuranceByVehicleId(payload) {
    const requestParameters = new RequestParameters('PUT', {}, null, payload);
    const endpoint = `insurance/update`;
    const result = await httpHelper.makeRequest(
      appConfig.baseUrl,
      endpoint,
      requestParameters,
      // 'Bearer',
    );
    return result;
  },

  async updateInspectionByVehicleId(payload) {
    const requestParameters = new RequestParameters('PUT', {}, null, payload);
    const endpoint = `inspection/update`;
    const result = await httpHelper.makeRequest(
      appConfig.baseUrl,
      endpoint,
      requestParameters,
      // 'Bearer',
    );
    return result;
  },

  async getVehicleByVehicleId(vehicleId) {
    const requestParameters = new RequestParameters(
      'GET',
      {
        vehicleId: vehicleId,
      },
      null,
      null,
    );

    const endpoint = `vehicle/getVehicleById`;
    const result = await httpHelper.makeRequest(
      appConfig.baseUrl,
      endpoint,
      requestParameters,
      // 'Bearer',
    );
    return result;
  },

  async checkChassisNumber(params) {
    const requestParameters = new RequestParameters('GET', null, null, null);

    const endpoint = `vehicles/decodevin/${params.chassisNumber}?format=json`;
    const result = await httpHelper.makeRequest(
      'https://vpic.nhtsa.dot.gov/api/',
      endpoint,
      requestParameters,
      // 'Bearer',
    );
    return result;
  },

  async getAllPlate(params, pageable) {
    const requestParameters = new RequestParameters(
      'GET',
      params,
      pageable,
      null,
    );

    const endpoint = `plate/getAllPlateType`;
    const result = await httpHelper.makeRequest(
      appConfig.baseUrl,
      endpoint,
      requestParameters,
      // 'Bearer',
    );
    return result;
  },
  async getPlateByPlateId(plateId) {
    const requestParameters = new RequestParameters(
      'GET',
      {
        plateId: plateId,
      },
      null,
      null,
    );

    const endpoint = `plate/findByPlateId`;
    const result = await httpHelper.makeRequest(
      appConfig.baseUrl,
      endpoint,
      requestParameters,
      // 'Bearer',
    );
    return result;
  },
  async findAllVehicle(pageable) {
    const requestParameters = new RequestParameters(
      'GET',
      null,
      pageable,
      null,
    );

    const endpoint = `vehicle/findAllVehicle`;
    const result = await httpHelper.makeRequest(
      appConfig.baseUrl,
      endpoint,
      requestParameters,
      // 'Bearer',
    );
    return result;
  },
  // async getVehiclesWithFilter(params) {
  //   const endPoint = `vehicle/findByLikeIgnoreCase`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     params,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async getVehiclesWithFilterByObserverId(params, observerId) {
  //   const endPoint = `observer-vehicle/findByObserverIdAndLikeIgnoreCase/${observerId}`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     params,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async saveVehicle(payload) {
  //   const endPoint = 'vehicle';
  //   const result = await httpHelper.makePostRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async getVehicle(vehicleId) {
  //   const endPoint = `vehicle/${vehicleId}`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     {},
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async getQRCode(identity) {
  //   const endPoint = `vehicle-qr-code/${identity}`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     {},
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async updateVehicle(payload) {
  //   const endPoint = `vehicle`;
  //   const result = await httpHelper.makePutRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async deleteVehicle(vehicleId) {
  //   const endPoint = `vehicle/${vehicleId}`;
  //   const result = await httpHelper.makeDeleteRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async findByLike(filterEP, value, params) {
  //   const endPoint =
  //     filterEP !== 'id' ? `vehicle/${filterEP}/${value}` : `vehicle/${value}`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     params,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async findByPlateVehicle(payload) {
  //   const endPoint = `vehicle/search/findByPlate`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async findByLikeIgnoreCase(params) {
  //   const endPoint = `vehicle/findByLikeIgnoreCase`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     { ...params, status: 'ACTIVE' },
  //     'Bearer',
  //   );
  //   return result;
  // },

  // // VEHICLE METADATA SERVICES

  // async getVehiclesMetadata(payload) {
  //   const endPoint = 'vehicle-metadata';
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async saveVehicleMetadata(payload) {
  //   const endPoint = 'vehicle-metadata';
  //   const result = await httpHelper.makePostRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async getVehicleMetadata(vehicleMetadataId) {
  //   const endPoint = `vehicle-metadata/${vehicleMetadataId}`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     {},
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async getVehicleMetadataByVehicleId(vehicleId) {
  //   const endPoint = `vehicle-metadata/findByVehicleId/${vehicleId}`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     {},
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async updateVehicleMetadata(payload) {
  //   const endPoint = `vehicle-metadata`;
  //   const result = await httpHelper.makePutRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async deleteVehicleMetadata(vehicleMetadataId) {
  //   const endPoint = `vehicle-metadata/${vehicleMetadataId}`;
  //   const result = await httpHelper.makeDeleteRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async findByPlateVehicleMetadata(payload) {
  //   const endPoint = `vehicle/search/findByPlate`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // // VEHICLE METADATA KEYS SERVICES

  // async getVehicleMetadataKeys(params) {
  //   const endPoint = `vehicle-metadata-key`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     params,
  //     'Bearer',
  //   );
  //   return result;
  // },
  // async saveVehicleMetadataKey(payload) {
  //   const endPoint = 'vehicle-metadata-key';
  //   const result = await httpHelper.makePostRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async getVehicleMetadataKey(vehicleMetadataKeyId) {
  //   const endPoint = `vehicle-metadata-key/${vehicleMetadataKeyId}`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     {},
  //     'Bearer',
  //   );
  //   return result;
  // },
  // async updateVehicleMetadataKey(payload) {
  //   const endPoint = `vehicle-metadata-key`;
  //   const result = await httpHelper.makePutRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async deleteVehicleMetadataKey(vehicleMetadataKeyId) {
  //   const endPoint = `vehicle-metadata-key/${vehicleMetadataKeyId}`;
  //   const result = await httpHelper.makeDeleteRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // // VEHICLE GROUP SERVICES

  // async getVehicleGroups(params) {
  //   const endPoint = 'vehicle-group';
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     params,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async saveVehicleGroups(payload) {
  //   const endPoint = 'vehicle-group';
  //   const result = await httpHelper.makePostRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async getVehicleGroup(vehicleGroupId) {
  //   const endPoint = `vehicle-group/${vehicleGroupId}`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     {},
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async updateVehicleGroups(payload) {
  //   const endPoint = `vehicle-group`;
  //   const result = await httpHelper.makePutRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async deleteVehicleGroups(vehicleGroupId) {
  //   const endPoint = `vehicle-group/${vehicleGroupId}`;
  //   const result = await httpHelper.makeDeleteRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async findByPlateVehicleGroups(payload) {
  //   const endPoint = `vehicle/search/findByPlate`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // // VEHICLE GROUP RELATION SERVICES

  // async getVehicleGroupRelations(params) {
  //   const endPoint = 'vehicle-group-relation';
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     params,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async saveVehicleGroupRelations(payload) {
  //   const endPoint = 'vehicle-group-relation';
  //   const result = await httpHelper.makePostRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async getVehicleGroupRelation(vehicleGroupRelationId) {
  //   const endPoint = `vehicle-group-relation/${vehicleGroupRelationId}`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     {},
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async getVehicleGroupByVehicleId(vehicleId) {
  //   const endPoint = `vehicle-group-relation/findByVehicleId/${vehicleId}`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     {},
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async updateVehicleGroupRelations(payload) {
  //   const endPoint = `vehicle-group-relation`;
  //   const result = await httpHelper.makePutRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async deleteVehicleGroupRelations(vehicleGroupRelationId) {
  //   const endPoint = `vehicle-group-relation/${vehicleGroupRelationId}`;
  //   const result = await httpHelper.makeDeleteRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     'Bearer',
  //   );
  //   return result;
  // },
};

export default services;
