import React from 'react';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import services from './service';
import { useBoundStore } from '../../../stores';
import { Modal, Box, Typography } from '@mui/material';
import TStepper from './components/Stepper';
import CustomInput from '../../../components/CustomInput';
import { fields } from './constants/create_vehicle_fields';
import { insurance_fields } from './constants/create_insurance_fields';
import { inspection_fields } from './constants/create_inspection_fields';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Sort } from '../../../ui-sdk/core/service/sort';
import { Pageable } from '../../../ui-sdk/core/service/pageable';
import service from './service';



const boxStyle = {
  outline: 'none',
  position: 'absolute',
  top: '50%',
  left: '50%',
  borderRadius: 30,
  transform: 'translate(-50%, -50%)',
  width: 937,
  height: 'auto',
  backgroundColor: 'white',
  boxShadow: 24,
  padding: 30,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

function EditVehicle() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [modalOpen, setModalOpen] = useState(true);
  const [fieldsEmpty, setFieldsEmpty] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [sortModel, setSortModel] = useState([]);


  const [vehicleFormData, setVehicleFormData] = React.useState({
    plate: '',
    debitUserId: '',
    engineNumber: '',
    chasis: '',
    brand: '',
    model: '',
    modelYear: '',
    type: '',
    load: '',
    fuelFactoryPerformance: '',
    coolant: '',
    description: '',
    status: '',
    availableFirm: '',
    documentSerialNo: '',
    document: '',
    purposeOfUsage: '',
    vehicleClass: '',
    queueNo: '',
  });
  const [statusType, setStatusType] = React.useState({
    statusTypeId: '',
  });
  const [seatInsuranceFormData, setSeatInsuranceFormData] = React.useState({
    insuranceTypeId: '0F8AJBREQ84FD',
    insuranceFirm: '',
    // insuranceValidityMileage: null,
    insurancePolicyNumber: '',
    // insuranceValidityBeginsAt: null,
    insuranceValidityEndsAt: null,
  });
  const [kaskoInsuranceFormData, setKaskoInsuranceFormData] = React.useState({
    insuranceTypeId: '0F8AMHDTV84FF',
    insuranceFirm: '',
    // insuranceValidityMileage: null,
    insurancePolicyNumber: '',
    // insuranceValidityBeginsAt: null,
    insuranceValidityEndsAt: null,
  });
  const [trafficInsuranceFormData, setTrafficInsuranceFormData] =
    React.useState({
      insuranceTypeId: '0F8AMKSAV84FG',
      insuranceFirm: '',
      // insuranceValidityMileage: null,
      insurancePolicyNumber: '',
      // insuranceValidityBeginsAt: null,
      insuranceValidityEndsAt: null,
    });

  const [vehicleInspectionFormData, setVehicleInspectionFormData] =
    React.useState({
      inspectionTypeId: '0F8AN274Q84FH',
      inspectionFirm: '',
      inspectionValidityMileage: null,
      inspectionPolicyNumber: '',
      // inspectionValidityBeginsAt: null,
      inspectionValidityEndsAt: null,
    });
  const [exhaustInspectionFormData, setExhaustInspectionFormData] =
    React.useState({
      inspectionTypeId: '0F8AN4NA384FJ',
      inspectionFirm: '',
      inspectionValidityMileage: null,
      inspectionPolicyNumber: '',
      // inspectionValidityBeginsAt: null,
      inspectionValidityEndsAt: null,
    });
  const [activeJobs, setActiveJobs] = React.useState('');
  const [formData, setFormData] = React.useState({
    ...vehicleFormData,
    activeJobs: activeJobs,
    insurances: {
      ...seatInsuranceFormData,
      ...kaskoInsuranceFormData,
      ...trafficInsuranceFormData,
    },
    inspections: {
      ...vehicleInspectionFormData,
      ...exhaustInspectionFormData,
    },
  });

  async function getAllCompany() {
    setIsLoading(true);
    const { field, sort } =
      sortModel.length > 0
        ? sortModel[0]
        : {
            field: 'createdAt',
            sort: 'desc',
          };

    const params = {};

    const pageable = new Pageable(
      0,
      9999999999999,
      new Sort('createdAt', sort),
    );

    try {
      const result = await service.getAllCompany(params, pageable);
      const data = result?.data;

      // "name" alanını "label" olarak değiştiriyoruz
      const transformedData = data.map((item) => ({
        id: item.id,
        label: item.name,
      }));

      setIsLoading(false);
      setCompanies(transformedData);
    } catch (error) {
      console.error('Error fetching companies: ', error);
      setIsLoading(false);
    }
  }

  const handleChangeActiveJobs = (value) => {
    setActiveJobs(value);
  };
  const handleFieldChange = (key, value) => {
    if (key === 'activeJobs') {
      handleChangeActiveJobs(value);
    } else if (key === 'statusType') {
      setStatusType({
        statusTypeId: value,
      });
      setVehicleFormData({
        ...vehicleFormData,
        statusType: {
          statusTypeId: value,
        },
      });
    } else {
      setVehicleFormData({
        ...vehicleFormData,
        [key]: value,
      });
    }
  };

  async function checkChassisNumber() {
    // First API
    const result = await services.checkChassisNumber({
      chassisNumber: vehicleFormData.chasis,
    });
    result?.data?.Results?.map((item) => {
      if (item?.Variable === 'Error Code') {
        if (item?.Value?.includes('6')) {
          setHelperText('Eksik / Hatalı Şasi Numarası, kontrol ediniz.');
          return true;
        } else {
          setHelperText('');
          return false;
        }
      }
    });
  }

  function isFieldsEmpty() {
    if (
      vehicleFormData.chasis === '' ||
      vehicleFormData.plate === '' ||
      vehicleFormData.engineNumber === ''
    ) {
      setFieldsEmpty(true);
      return true;
    } else {
      setFieldsEmpty(false);
      return false;
    }
  }

  const getVehicleData = async () => {
    setIsLoading(true);
    const response = await services.getVehicleByVehicleId(id);
    if (response) {
      setVehicleFormData(response.data);
      setSeatInsuranceFormData(
        response.data.insurances.find(
          (insurance) => insurance.insuranceTypeId === '0F8AJBREQ84FD',
        ) || seatInsuranceFormData,
      );
      setKaskoInsuranceFormData(
        response.data.insurances.find(
          (insurance) => insurance.insuranceTypeId === '0F8AMHDTV84FF',
        ) || kaskoInsuranceFormData,
      );
      setTrafficInsuranceFormData(
        response.data.insurances.find(
          (insurance) => insurance.insuranceTypeId === '0F8AMKSAV84FG',
        ) || trafficInsuranceFormData,
      );
      setVehicleInspectionFormData(
        response.data.inspections.find(
          (inspection) => inspection.inspectionTypeId === '0F8AN274Q84FH',
        ) || vehicleInspectionFormData,
      );
      setExhaustInspectionFormData(
        response.data.inspections.find(
          (inspection) => inspection.inspectionTypeId === '0F8AN4NA384FJ',
        ) || exhaustInspectionFormData,
      );
      setStatusType({ statusTypeId: response.data.statusType.statusTypeId });
      setActiveJobs(JSON.parse(response.data.activeJobs));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getVehicleData();
    getAllCompany();
  }, []);

  useEffect(() => {
    checkChassisNumber();
  }, [vehicleFormData.chasis]);

  useEffect(() => {
    isFieldsEmpty();
  }, [
    vehicleFormData.chasis,
    vehicleFormData.plate,
    vehicleFormData.engineNumber,
  ]);

  const checkInsuranceFields = (data) => {
    if (
      data.insuranceFirm != '' ||
      data.insurancePolicyNumber != '' ||
      data.insuranceValidityBeginsAt != null ||
      data.insuranceValidityEndsAt != null
    ) {
      return data;
    }
  };

  const checkInspectionFields = (data) => {
    if (
      data.inspectionFirm != '' ||
      data.inspectionPolicyNumber != '' ||
      data.inspectionValidityBeginsAt != null ||
      data.inspectionValidityEndsAt != null
    ) {
      return data;
    }
  };

  const setData = () => {
    const data = {
      ...vehicleFormData,
      statusType: statusType,
      activeJobs: JSON.stringify(activeJobs),
      insurances: [
        checkInsuranceFields(seatInsuranceFormData)
          ? checkInsuranceFields(seatInsuranceFormData)
          : null,
        checkInsuranceFields(kaskoInsuranceFormData)
          ? checkInsuranceFields(kaskoInsuranceFormData)
          : null,
        checkInsuranceFields(trafficInsuranceFormData)
          ? checkInsuranceFields(trafficInsuranceFormData)
          : null,
      ],
      inspections: [
        checkInspectionFields(vehicleInspectionFormData)
          ? checkInspectionFields(vehicleInspectionFormData)
          : null,
        checkInspectionFields(exhaustInspectionFormData)
          ? checkInspectionFields(exhaustInspectionFormData)
          : null,
      ],
    };
    setFormData({
      ...vehicleFormData,
      statusType: statusType,
      activeJobs: JSON.stringify(activeJobs),
      insurances: [
        checkInsuranceFields(seatInsuranceFormData)
          ? checkInsuranceFields(seatInsuranceFormData)
          : null,
        checkInsuranceFields(kaskoInsuranceFormData)
          ? checkInsuranceFields(kaskoInsuranceFormData)
          : null,
        checkInsuranceFields(trafficInsuranceFormData)
          ? checkInsuranceFields(trafficInsuranceFormData)
          : null,
      ],
      inspections: [
        checkInspectionFields(vehicleInspectionFormData)
          ? checkInspectionFields(vehicleInspectionFormData)
          : null,
        checkInspectionFields(exhaustInspectionFormData)
          ? checkInspectionFields(exhaustInspectionFormData)
          : null,
      ],
    });
    return data;
  };
  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          navigate(-1);
        }}
      >
        <div className="flex flex-col outline-none justify-center items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-auto max-2xl:w-full max-2xl:h-full bg-white p-10 w-[1280px] overflow-y-auto">
          <div className="w-full flex justify-end items-center">
            <IconButton
              onClick={() => {
                setModalOpen(false);
                navigate(-1);
              }}
            >
              <Close />
            </IconButton>
          </div>
          <TStepper
            steps={['Araç Bilgileri', 'Sigorta Bilgileri', 'Muayene Bilgileri']}
            optionalSteps={[1, 2]}
            formData={formData}
            setData={setData}
            helperText={helperText}
            fieldsEmpty={fieldsEmpty}
          >
            {/* Araç Bilgileri */}
            <div className="w-full ">
              <div className="mt-5 w-full ">
                <div>
                  <Typography
                    variant="h5"
                    color="initial"
                    gutterBottom
                    textAlign={'center'}
                  >
                    Araç Bilgilerini Güncelle
                  </Typography>
                </div>

                <div className="flex flex-row flex-wrap justify-center items-center gap-3 w-full ">
                  {fields(companies).map((field, index) => {
                    return (
                      <div className=" flex flex-wrap min-h-20" key={index}>
                        <CustomInput
                          key={index}
                          type={field.type}
                          label={field.placeHolder}
                          multiple={field.key === 'activeJobs' ? true : false}
                          value={
                            field.key === 'activeJobs'
                              ? activeJobs || []
                              : field.key === 'statusType'
                                ? statusType.statusTypeId || ''
                                : vehicleFormData[field.key] || ''
                          }
                          error={
                            (field.key === 'chasis' && helperText !== '') ||
                            ((field.key === 'engineNumber' ||
                              field.key === 'plate' ||
                              field.key === 'chasis') &&
                              vehicleFormData[field.key] === '')
                              ? true
                              : false
                          }
                          errorText={
                            (field.key === 'engineNumber' ||
                              field.key === 'plate' ||
                              field.key === 'chasis') &&
                            vehicleFormData[field.key] === ''
                              ? 'Bu alan boş bırakılamaz'
                              : field.key === 'chasis' && helperText
                          }
                          fullWidth={true}
                          sx={{ width: '284px' }}
                          items={field.items}
                          options={field.options || []}
                          size={'medium'}
                          onChange={(key, value) => {
                            handleFieldChange(field.key, value);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {/* Sigorta Bilgileri */}
            <>
              <div className="mt-5 ">
                <div>
                  <Typography
                    variant="h5"
                    color="initial"
                    gutterBottom
                    textAlign={'center'}
                  >
                    Sigorta Bilgileri
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="h6"
                    color="initial"
                    gutterBottom
                    textAlign={'left'}
                  >
                    Trafik Sigortası
                  </Typography>
                </div>
                <div className="flex flex-row flex-wrap max-md:justify-center max-md:items-center gap-3 w-full mb-3">
                  {insurance_fields().map((field, index) => {
                    return (
                      <div className=" flex  flex-wrap " key={index}>
                        <CustomInput
                          key={index}
                          type={field.type}
                          label={field.placeHolder}
                          value={trafficInsuranceFormData[field.key]}
                          fullWidth={false}
                          size={'medium'}
                          sx={{ width: '284px' }}
                          onChange={(key, value) => {
                            setTrafficInsuranceFormData({
                              ...trafficInsuranceFormData,
                              [field.key]: value,
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                <div>
                  <Typography
                    variant="h6"
                    color="initial"
                    gutterBottom
                    textAlign={'left'}
                  >
                    Kasko
                  </Typography>
                </div>
                <div className="flex flex-row flex-wrap max-md:justify-center max-md: items-center gap-3 w-full mb-3 ">
                  {insurance_fields().map((field, index) => {
                    return (
                      <div className=" flex  flex-wrap " key={index}>
                        <CustomInput
                          key={index}
                          type={field.type}
                          label={field.placeHolder}
                          value={kaskoInsuranceFormData[field.key]}
                          fullWidth={false}
                          size={'medium'}
                          sx={{ width: '284px' }}
                          onChange={(key, value) => {
                            setKaskoInsuranceFormData({
                              ...kaskoInsuranceFormData,
                              [field.key]: value,
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                <div>
                  <Typography
                    variant="h6"
                    color="initial"
                    gutterBottom
                    textAlign={'left'}
                  >
                    Koltuk Sigortası
                  </Typography>
                </div>
                <div className="flex flex-row flex-wrap max-md:justify-center max-md:items-center gap-3 w-full mb-3">
                  {insurance_fields().map((field, index) => {
                    return (
                      <div className=" flex  flex-wrap " key={index}>
                        <CustomInput
                          key={index}
                          type={field.type}
                          label={field.placeHolder}
                          value={seatInsuranceFormData[field.key]}
                          fullWidth={false}
                          size={'medium'}
                          sx={{ width: '284px' }}
                          onChange={(key, value) => {
                            setSeatInsuranceFormData({
                              ...seatInsuranceFormData,
                              [field.key]: value,
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
            {/* Muayene Bilgileri */}
            <>
              <div className="mt-5">
                <Typography
                  variant="h5"
                  color="initial"
                  gutterBottom
                  textAlign={'center'}
                >
                  Muayene Bilgileri
                </Typography>
              </div>
              <div>
                <div>
                  <Typography
                    variant="h6"
                    color="initial"
                    gutterBottom
                    textAlign={'left'}
                  >
                    Zorunlu Araç Muayenesi
                  </Typography>
                </div>
                <div className="flex flex-row flex-wrap max-md:justify-center max-md:items-center gap-3 w-full mb-3">
                  {inspection_fields().map((field, index) => {
                    return (
                      <div className=" flex  flex-wrap " key={index}>
                        <CustomInput
                          key={index}
                          type={field.type}
                          label={field.placeHolder}
                          value={vehicleInspectionFormData[field.key]}
                          fullWidth={false}
                          size={'medium'}
                          sx={{ width: '284px' }}
                          onChange={(key, value) => {
                            setVehicleInspectionFormData({
                              ...vehicleInspectionFormData,
                              [field.key]: value,
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                <div>
                  <Typography
                    variant="h6"
                    color="initial"
                    gutterBottom
                    textAlign={'left'}
                  >
                    Zorunlu Araç Egzoz Muayenesi
                  </Typography>
                </div>
                <div className="flex flex-row flex-wrap max-md:justify-center max-md:items-center gap-3 w-full mb-3">
                  {inspection_fields().map((field, index) => {
                    return (
                      <div className=" flex  flex-wrap " key={index}>
                        <CustomInput
                          key={index}
                          type={field.type}
                          label={field.placeHolder}
                          value={exhaustInspectionFormData[field.key]}
                          fullWidth={false}
                          sx={{ width: '284px' }}
                          size={'medium'}
                          onChange={(key, value) => {
                            setExhaustInspectionFormData({
                              ...exhaustInspectionFormData,
                              [field.key]: value,
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          </TStepper>
        </div>
      </Modal>
    </div>
  );
}

export default EditVehicle;
