import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Pagination, CircularProgress, Button, Modal } from '@mui/material';
import * as MATERIEL_ICONS from '@mui/icons-material/';
import Template from '../../../../assets/xlsxs/UPLOADMULTIPLEVEHICLETEMPLATE.xlsx';
import service from '../service';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { toast } from 'react-toastify';
import InfoCard from './excelInfo/InfoCard';

const UploadMultipleVehicle = () => {
  const location = useLocation();
  const fileInputRef = useRef(null);

  const [filterData, setFilterData] = useState({ query: '' });
  const [sortModel, setSortModel] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const paginationChange = (_, e_page) => setPage(e_page - 1);
  const handleFileDownload = async () => {
    try {
      const excelFileUrl = Template;
      const response = await fetch(excelFileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Araç Ekleme Şablonu.xlsx';
      a.click();
    } catch (error) {
      console.error('Dosya indirme sırasında bir hata oluştu:', error);
    }
  };

  const handleFileUpload = () => {
    try {
      setIsLoading(true);
      fileInputRef.current.click();
      setIsLoading(false);
    } catch (error) {
      console.error('Dosya yükleme sırasında bir hata oluştu:', error);
    }
  };
  const parseExcelDate = (excelDate) => {
    // Verinin istenilen formatta olup olmadığını kontrol etmek için bir regex kullanalım
    if (
      excelDate === null ||
      excelDate === '' ||
      excelDate === undefined ||
      excelDate === 'undefined' ||
      excelDate === 'YOK' ||
      excelDate === 'yok' ||
      excelDate === 'Yok'
    ) {
      return null;
    } else {
      const dateFormatRegex = /^(\d{1,2})[\/.-](\d{1,2})[\/.-](\d{2}|\d{4})$/;
      // Eğer gelen veri null ya da boş ise direkt olarak null döndürelim
      if (
        !excelDate ||
        typeof excelDate !== 'string' ||
        !dateFormatRegex.test(excelDate)
      ) {
        return null;
      }
      const parts = excelDate.split(/[\/.-]/); // Gün, ay ve yıl parçalarına ayır
      let day = 0; // Günü al ve tam sayıya çevir
      let month = 0; // Ayı al, bir eksilt ve tam sayıya çevir (JavaScript'te aylar 0'dan başlar)
      let year = parseInt(parts[2]);

      //  TR TARİHİ İÇİN OLAN FONKSİYON
      /* if (parseInt(parts[1]) > 12) {
         day = parseInt(parts[1]);
         month = parseInt(parts[0]);
       } else {
         day = parseInt(parts[0]);
         month = parseInt(parts[1]);
       }*/

      //ABD TARİHİ İÇİN OLAN FONKSİYON
      day = parseInt(parts[1]);
      month = parseInt(parts[0]);


      // Eğer yıl sadece iki haneli ise 2000 yılına ekle
      if (year < 100) {
        year += 2000;
      }

      const DateExcel = new Date(year, month - 1, day);
      return DateExcel; // Yeni bir tarih nesnesi oluştur ve döndür
    }
  };

  const handleFileChange = (e) => {
    try {
      setIsLoading(true);
      const file = e.target.files[0];
      if (file && file.name.endsWith('.xlsx')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const fileContent = e.target.result;
          const workbook = XLSX.read(fileContent, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const range = XLSX.utils.decode_range(sheet['!ref']);
          const totalRows = range.e.r;
          const vehicles = [];

          for (let rowIndex = 1; rowIndex <= totalRows; rowIndex++) {
            try {
              const queueNumber =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 0 })];

              const plate =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 1 })];
              const business =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 2 })];
              const driver =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 3 })];
              const documentSerialNumber =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 4 })];
              const motorNumber =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 5 })];
              const chassisNumber =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 6 })];
              const brand =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 7 })];
              const model =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 8 })];
              const modelYear =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 9 })];
              const type =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 10 })];
              const seat =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 11 })];
              const factoryFuelData =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 12 })];
              const climate =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 13 })];
              const document =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 14 })];
              const purposeOfUse =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 15 })];
              const carClass =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 16 })];
              const description =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 17 })];
              const status =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 18 })];
              const inspectionFirm =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 19 })];
              const inspectionEnd =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 20 })];
              const inspectionKM =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 21 })];
              const inspectionDocumentNumber =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 22 })];
              const exhaustInspectionFirm =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 23 })];
              const exhaustInspectionEnd =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 24 })];

              const exhaustInspectionDocumentNumber =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 25 })];
              const mandatoryTrafficPolicyFirm =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 26 })];
              const mandatoryTrafficPolicyEnd =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 27 })];

              const mandatoryTrafficPolicyNumber =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 28 })];
              const seatInsuranceFirm =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 29 })];
              const seatInsuranceEnd =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 30 })];

              const seatInsurancePolicyNumber =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 31 })];
              const cascoFirm =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 32 })];
              const cascoEnd =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 33 })];

              const cascoPolicyNumber =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 34 })];
              if (!queueNumber | plate) {
                continue;
              }
              const getIsoDate = (date) => {
                return date ? parseExcelDate(date)?.toISOString() : null;
              };
              const inspectionEndIso = getIsoDate(inspectionEnd?.v);
              const exhaustInspectionEndIso = getIsoDate(
                exhaustInspectionEnd?.v,
              );
              const mandatoryTrafficPolicyEndIso = getIsoDate(
                mandatoryTrafficPolicyEnd?.v,
              );
              const seatInsuranceEndIso = getIsoDate(seatInsuranceEnd?.v);
              const cascoEndIso = getIsoDate(cascoEnd?.v);

              const coolantState = climate?.v === 'VAR' ? '1' : '0';

              vehicles.push({
                plate: plate?.v,
                debitUserId: driver?.v,
                engineNumber: motorNumber?.v,
                chasis: chassisNumber?.v,
                brand: brand?.v,
                model: model?.v,
                modelYear: modelYear?.v,
                type: type?.v,
                load: seat?.v,
                fuelFactoryPerformance: factoryFuelData?.v,
                coolant: coolantState,
                description: description?.v,
                status: true,
                availableFirm: business?.v,
                documentSerialNo: documentSerialNumber?.v,
                document: document?.v,
                purposeOfUsage: purposeOfUse?.v,
                vehicleClass: carClass?.v,
                queueNo: queueNumber?.v,
                statusType: {
                  statusTypeId: '0FFTEF9ZQT6M7'
                },
                insurances: [
                  {
                    insuranceTypeId: '0F8AJBREQ84FD',
                    insuranceFirm: seatInsuranceFirm?.v,
                    insurancePolicyNumber: seatInsurancePolicyNumber?.v,
                    insuranceValidityEndsAt: seatInsuranceEndIso,
                  },

                  {
                    insuranceTypeId: '0F8AMHDTV84FF',
                    insuranceFirm: cascoFirm?.v,
                    insurancePolicyNumber: cascoPolicyNumber?.v,
                    insuranceValidityEndsAt: cascoEndIso,
                  },
                  {
                    insuranceTypeId: '0F8AMKSAV84FG',
                    insuranceFirm: mandatoryTrafficPolicyFirm?.v,
                    insurancePolicyNumber: mandatoryTrafficPolicyNumber?.v,
                    insuranceValidityEndsAt: mandatoryTrafficPolicyEndIso,
                  },
                ],
                inspections: [
                  {
                    inspectionTypeId: '0F8AN274Q84FH',
                    inspectionFirm: inspectionFirm?.v,
                    inspectionValidityMileage: inspectionKM?.v,
                    inspectionPolicyNumber: inspectionDocumentNumber?.v,
                    inspectionValidityEndsAt: inspectionEndIso,
                  },
                  {
                    inspectionTypeId: '0F8AN4NA384FJ',
                    inspectionFirm: exhaustInspectionFirm?.v,
                    inspectionPolicyNumber: exhaustInspectionDocumentNumber?.v,
                    inspectionValidityEndsAt: exhaustInspectionEndIso,
                  },
                ],
              });
              console.log('asd', vehicles);
            } catch (error) {
              console.error('Dosya içeriği okunurken bir hata oluştu:', error);
            }
          }
          setVehicles(vehicles);
          setIsLoading(false);
        };
        reader.onprogress = (event) => {
          if (event.lengthComputable) {
            // İlerleme takibi yapabilirsiniz
            const progress = (event.loaded / event.total) * 100;
            console.log('Yükleme ilerlemesi:', progress);
          }
        };
        reader.readAsBinaryString(file);
      }
    } catch (error) {
      console.error('Dosya yükleme sırasında bir hata oluştu:', error);
      setIsLoading(false);
    }
  };

  const handleSaveConfirm = async () => {
    setModalOpen(true);
    const unsuccessfulVehicles = []; // Başarısız olan araçları izlemek için bir dizi oluşturuldu
    try {
      for (const vehicle of vehicles) {
        try {
          const result = await service.postCollectionVehicleData(vehicle);
          if (result.status == 200) {
            if (result.data.response) {
              toast.error(
                vehicle.plate +
                ' plakası, motor no veya şasi no daha önce kaydedilmiş',
              );
              unsuccessfulVehicles.push(vehicle); // Başarısız olan aracı listeye ekle
            } else {
              toast.success(vehicle.plate + ' plakası başarıyla kaydedildi');
            }
          } else {
            toast.error(vehicle.plate + ' plakası kaydedilirken hata oluştu');
            unsuccessfulVehicles.push(vehicle); // Başarısız olan aracı listeye ekle
          }
        } catch (err) {
          console.error('Araçlar kaydedilirken bir hata oluştu:', err);
          unsuccessfulVehicles.push(vehicle); // Başarısız olan aracı listeye ekle
        }
      }

      handleDownloadUnsuccessfulVehicles(unsuccessfulVehicles);
    } catch (error) {
      console.error('Araçlar kaydedilirken bir hata oluştu:', error);
    }
    setModalOpen(false);
  };
  const handleDownloadUnsuccessfulVehicles = (unsuccessfulVehicles) => {
    try {
      const XLSX = require('xlsx');
      if (unsuccessfulVehicles.length > 0) {
        const headers = [
          'Plaka',
          'Şirket',
          'Şoför',
          'Açıklama',
          'Kullanım Amacı',
          'Araç Sınıfı',
          'Belgesi',
          'Durum',
          'Marka',
          'Model',
          'Model Yılı',
          'Belge Seri No',
          'Motor No',
          'Şase No',
          'Tipi',
          'Koltuk',
          'Fabrika Yakıt Verisi',
          'Klima',
        ];

        const data = unsuccessfulVehicles.map((vehicle) => {
          return [
            vehicle.plate,
            vehicle.availableFirm,
            vehicle.debitUserId,
            vehicle.description,
            vehicle.purposeOfUse,
            vehicle.vehicleClass,
            vehicle.document,
            vehicle.status,
            vehicle.brand,
            vehicle.model,
            vehicle.modelYear,
            vehicle.documentSerialNo,
            vehicle.engineNumber,
            vehicle.chasis,
            vehicle.type,
            vehicle.load,
            vehicle.fuelFactoryPerformance,
            vehicle.coolant,
          ];
        });

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'Kaydedilemeyen_Araçlar.xlsx');
        toast.info('Başarısız araçlar Excel dosyasına dönüştürüldü');
      }
    } catch (error) {
      console.error(
        'Başarısız olan araçları Excel dosyasına dönüştürme sırasında bir hata oluştu:',
        error,
      );
    }
  };

  const handleFilterInput = (newValue) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      query: newValue,
    }));
  };

  useEffect(() => {
    moment.locale('tr');
    setPage(0);
  }, [filterData]);

  const filter = async () => {
    setPage(0);
  };

  useEffect(() => { }, [page, sortModel, location]);

  return (
    <div className="p-3 m-3 flex flex-col justify-center items-center">
      <div className="w-full max-lg:w-full p-3 m-3">
        <div className=" py-3 px-2 mx-3 flex max-sm:flex-col flex-wrap justify-between max-sm:justify-center gap-3 items-center">
          <span className="text-lg sm:text-2xl max-sm:text-center">
            Araçlar
          </span>
          <div className="mx-2 my-5 flex justify-between items-center">
            {/*  <Button variant="contained" color="primary" onClick={onChangeVehicle}>
              gönder
            </Button>*/}
            <div className="flex max-sm:flex-col justify-center gap-5">
              <Button
                variant="contained"
                startIcon={<MATERIEL_ICONS.FileDownload />}
                color="primary"
                onClick={handleFileDownload}
                className="mx-2"
              >
                ŞABLONU İNDİR
              </Button>
              <Button
                variant="contained"
                startIcon={<MATERIEL_ICONS.FileUpload />}
                color="primary"
                onClick={handleFileUpload}
                className="mx-2"
              >
                <input
                  ref={fileInputRef}
                  type="file"
                  accept=".xlsx"
                  style={{ display: 'none' }} // Dosya seçme inputunu gizler
                  onChange={handleFileChange}
                />
                ARAÇLARI EKLE
              </Button>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center w-[890px]">
            <CircularProgress className="users-list-circular-progress" />
          </div>
        ) : (
          <div className=" p-3 mx-3">
            <div className="w-full flex flex-wrap justify-between items-center  gap-3">
              {vehicles.length > 0 ? (
                vehicles.map((vehicle, index) => (
                  <InfoCard key={index} vehicle={vehicle} />
                ))
              ) : (
                <div className="w-full bg-white shadow-2xl rounded-xl py-8 flex justify-center items-center ">
                  <span className=" text-xs p-3">
                    Şablonu indirmek için{' '}
                    <span className="font-semibold">ŞABLONU İNDİR</span>{' '}
                    düğmesine tıklayarak Excel dosyasını indirebilirsiniz.
                    Dosyayı doldurduktan sonra,{' '}
                    <span className="font-semibold">ARAÇLARI EKLE</span>{' '}
                    düğmesiyle yükleyebilirsiniz.
                  </span>
                </div>
              )}
            </div>

            {/* <TDataGrid
            rows={vehicles}
            columns={columns}
            sortModel={sortModel}
            setSortModel={setSortModel}
          />*/}
            {vehicles.length > 0 && (
              <div className="fixed bottom-0 right-0 w-full flex flex-row-reverse  shadow-xl xl:px-18 lg:px-16 md:px-12 px-8 py-3">
                <Button
                  variant="contained"
                  onClick={() => setModalOpen(true)}
                  color="primary"
                >
                  Kayıt İşlemini Tamamla
                </Button>
              </div>
            )}
            <Modal
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div className="fixed inset-0 flex justify-center items-center">
                <div className="fixed inset-0 flex justify-center items-center">
                  <div className="w-[400px] h-[150px] bg-white rounded-xl shadow-xl p-3 m-3 flex flex-col justify-around items-center">
                    <span className="text-md max-sm:text-center">
                      Kayıt işlemini tamamlamak istiyor musunuz?
                    </span>
                    <div className="w-full flex justify-end gap-2 px-4 ">
                      <Button
                        variant="outlined"
                        className="w-1/3 bg-red-400"
                        color="error"
                        onClick={() => setModalOpen(false)}
                      >
                        İptal
                      </Button>
                      <Button
                        variant="outlined"
                        className="w-1/3 "
                        color="success"
                        onClick={handleSaveConfirm}
                      >
                        Onayla
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadMultipleVehicle;
