import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Pagination, CircularProgress, Button } from '@mui/material';
import * as MATERIEL_ICONS from '@mui/icons-material/';
import TDataGrid from '../../../components/Datagrid/Datagrid';
import { Pageable } from '../../../ui-sdk/core/service/pageable';
import { Sort } from '../../../ui-sdk/core/service/sort';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CustomInput from './components/CustomInput';
import { fields } from './constants/create_vehicle_fields';
import { plate_fields } from './constants/create_insurance_fields';
import { inspection_fields } from './constants/create_inspection_fields';
import TStepper from './components/Stepper';
import { useBoundStore } from '../../../stores/index';
import service from './service';
import { columns } from './constants/table_columns_data';
import { toast } from 'react-toastify';

const boxStyle = {
  outline: 'none',
  position: 'absolute',
  top: '50%',
  left: '50%',
  borderRadius: 30,
  transform: 'translate(-50%, -50%)',
  width: 937,
  height: 'auto',
  backgroundColor: 'white',
  boxShadow: 24,
  padding: 30,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

const Users = () => {
  const location = useLocation();

  const [filterData, setFilterData] = useState({ param: '' });
  const [sortModel, setSortModel] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [plates, setPlates] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [count, setCount] = useState(0);
  const [helperText, setHelperText] = useState('');
  const [fieldsEmpty, setFieldsEmpty] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [matchModalOpen, setMatchModalOpen] = useState(false);

  const [plateFormData, setPlateFormData] = React.useState({
    plate: '',
    contrat: '',
    capacity: '',
    purchased: '',
    status: '',
    selfOwned: '',
    contratEndDate: null,
    description: '',
    plateType: '',
  });

  const [realitonData, setRelationData] = React.useState({
    plateId: '',
    vehicleId: '',
  });
  const [formData, setFormData] = React.useState({
    ...plateFormData,
  });

  const paginationChange = (_, e_page) => setPage(e_page - 1);
  const XLSX = require('xlsx');

  function isFieldsEmpty() {
    if (plateFormData.selfOwned === '' || plateFormData.plate === '') {
      setFieldsEmpty(true);
      return true;
    } else {
      setFieldsEmpty(false);
      return false;
    }
  }

  async function getPlateData() {
    setIsLoading(true);
    const handleField = (field) => {
      const sortColumn = columns.filter((column) => column.field === field)[0];
      const res = sortColumn?.sortParameter
        ? sortColumn?.sortParameter
        : sortColumn?.field || 'createdAt';
      return res;
    };
    /*
    const handleTypeId = (field) => {
      const typeIdColumn = columns.filter(
        (column) => column.field === field,
      )[0];
      return typeIdColumn ? { typeId: typeIdColumn.typeId } : null;
    };

   
    const params = {
      ...handleTypeId(field),
    };*/
    const { field, sort } =
      sortModel.length > 0
        ? sortModel[0]
        : {
            field: 'createdAt',
            sort: 'desc',
          };

    const params = {};

    const pageable = new Pageable(
      page,
      10,
      new Sort(handleField(field) || 'createdAt', sort),
    );
    const result = await service.getAllPlate(params, pageable);
    const data = result?.data;
    setCount(data?.totalPages);
    setPlates(data?.content);

    setIsLoading(false);
  }
  async function getVehicleData() {
    setIsLoading(true);
    try {
      const pageableAllVehicle = new Pageable(0, 9999999, null);
      const resultVehicle = await service.findAllVehicle(pageableAllVehicle);
      setVehicles(resultVehicle?.data?.content);
    } catch (e) {
      toast.error('Araçlar getirilirken hata oluştu.');
    }
    setIsLoading(false);
  }

  async function getDatas() {
    getPlateData();
    getVehicleData();
  }

  async function searchData() {
    setIsLoading(true);
    const pageable = new Pageable(page, 10, null);
    const result = await service.getAllPlate(
      { param: filterData.param },
      pageable,
    );
    const data = result?.data;
    setCount(data?.totalPages);
    setPlates(data?.content);
    setIsLoading(false);
  }

  const handleExportExcelData = async () => {
    //SIRALAMAYI İNDİRMEK İÇİN
    const headers = [
      'Plaka',
      'Açıklama',
      'Satın Alınan',
      'Özmal',
      'Durum',
      'Kontrat Bitiş Tarihi',
    ];

    const data = plates.map((vehicle) => {
      return [
        vehicle.plate,
        vehicle.description,
        vehicle.purchased,
        vehicle.selfOwned ? 'EVET' : 'HAYIR',
        vehicle.status,
        vehicle.contratEndDate,
      ];
    });

    const workbook = XLSX.utils.book_new();
    const worksheetData = [headers, ...data];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Plakalar');
    XLSX.writeFile(workbook, 'Plakalar.xlsx');
  };

  const handleFilterInput = (newValue) => {
    setPage(0); // Reset page to 0
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      param: newValue,
    }));
  };

  const filter = async () => {
    searchData();
  };

  useEffect(() => {
    if (filterData.param === '') {
      getPlateData();
    } else {
      filter();
    }
  }, [filterData, page, sortModel, location, modalOpen]);

  useEffect(() => {
    isFieldsEmpty();
  }, [plateFormData.selfOwned, plateFormData.plate, plateFormData.capacity]);

  const setData = () => {
    const data = {
      ...plateFormData,
    };

    setFormData({
      ...plateFormData,
    });

    return data;
  };

  return (
    <>
      <div className="p-3 m-3 flex flex-col justify-center items-center">
        <div className="w-full 2xl:w-max flex flex-col items-center p-3 m-3">
          <div className=" py-3 w-max mx-3 my-2 flex flex-wrap max-lg:w-full justify-between max-lg:justify-center gap-3 items-center">
            <span className="text-lg sm:text-2xl max-sm:text-center">
              Plakalar
            </span>
            <div className="mx-2 my-5 flex justify-between items-center">
              <div className="flex flex-wrap justify-center gap-5">
                <TextField
                  size="small"
                  placeholder="Arama"
                  onChange={(e) => handleFilterInput(e.target.value)}
                  value={filterData.query}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MATERIEL_ICONS.Search />
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: '8px',
                    },
                  }}
                />

                <Button
                  variant="contained"
                  startIcon={<MATERIEL_ICONS.Add />}
                  color="primary"
                  onClick={() => {
                    setModalOpen(true);
                  }}
                  className="mx-2"
                >
                  YENİ PLAKA EKLE
                </Button>
                <Button
                  variant="contained"
                  startIcon={<MATERIEL_ICONS.CompareArrows />}
                  color="primary"
                  onClick={() => {
                    setMatchModalOpen(true);
                  }}
                  className="mx-2"
                >
                  PLAKA EŞLEŞTİR
                </Button>
                <Button
                  variant="contained"
                  startIcon={<MATERIEL_ICONS.SaveAsOutlined />}
                  color="primary"
                  onClick={() => {
                    handleExportExcelData(true);
                  }}
                  className="mx-2"
                >
                  EXCELE AKTAR
                </Button>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className="flex justify-center items-center w-[1200px]">
              <CircularProgress className="users-list-circular-progress" />
            </div>
          ) : (
            <div className="flex lg:w-max max-lg:w-full justify-center items-center">
              <div className="w-full bg-white rounded-xl shadow-xl p-3 m-3">
                <TDataGrid
                  rows={plates || []}
                  columns={columns}
                  sortModel={sortModel}
                  setSortModel={setSortModel}
                />
                <div className="flex justify-center items-center p-3 m-3">
                  <Pagination
                    page={page + 1}
                    onChange={paginationChange}
                    count={count}
                    color="primary"
                    size="small"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <Box style={boxStyle}>
          <TStepper
            steps={['Plaka Bilgileri', 'Plaka Araç Eşleştirme']}
            optionalSteps={[1]}
            formData={formData}
            setData={setData}
            getDatas={getDatas}
            realitonData={realitonData}
            helperText={helperText}
            fieldsEmpty={fieldsEmpty}
            setModalOpen={setModalOpen}
          >
            {/* Plaka Bilgileri */}
            <>
              <div className="mt-5">
                <div>
                  <Typography
                    variant="h5"
                    color="initial"
                    gutterBottom
                    textAlign={'center'}
                  >
                    Yeni Plaka Ekle
                  </Typography>
                </div>

                <div className="flex flex-row flex-wrap justify-start items-center gap-3 w-full">
                  {fields().map((field, index) => {
                    return (
                      <div className=" flex  flex-wrap h-20" key={index}>
                        <CustomInput
                          key={index}
                          type={field.type}
                          label={field.placeHolder}
                          value={plateFormData[field.key]}
                          fullWidth={true}
                          sx={{ width: '284px' }}
                          items={field.items}
                          size={'medium'}
                          onChange={(key, value) => {
                            setPlateFormData({
                              ...plateFormData,
                              [field.key]: value,
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
            {/* Eşleşme Bilgileri */}
            <>
              <div className="mt-5 ">
                <div>
                  <Typography
                    variant="h5"
                    color="initial"
                    gutterBottom
                    textAlign={'center'}
                  >
                    Plaka araç eşleşmesi
                  </Typography>
                </div>

                <div className="flex flex-row flex-wrap  items-center gap-3 w-full mb-3">
                  {plate_fields(plates, vehicles).map((field, index) => {
                    return (
                      <div className=" flex  flex-wrap h-20" key={index}>
                        <CustomInput
                          key={index}
                          type={field.type}
                          label={field.placeHolder}
                          value={realitonData[field.key]}
                          fullWidth={true}
                          sx={{ width: '284px' }}
                          options={field.options}
                          items={field.items}
                          size={'medium'}
                          onChange={(key, value) => {
                            setRelationData({
                              ...realitonData,
                              [field.key]: value,
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          </TStepper>
        </Box>
      </Modal>
      <Modal
        open={matchModalOpen}
        onClose={() => {
          setMatchModalOpen(false);
        }}
      >
        <Box style={boxStyle}>
          <TStepper
            steps={['Plaka Araç Eşleştirme']}
            formData={formData}
            setData={setData}
            getDatas={getDatas}
            getVehicleData={getVehicleData}
            realitonData={realitonData}
            helperText={helperText}
            fieldsEmpty={fieldsEmpty}
            setModalOpen={setMatchModalOpen}
          >
            {/* Eşleşme Bilgileri */}
            <>
              <div className="mt-5 ">
                <div>
                  <Typography
                    variant="h5"
                    color="initial"
                    gutterBottom
                    textAlign={'center'}
                  >
                    Plaka araç eşleşmesi
                  </Typography>
                </div>
                <div className="flex flex-row justify-evenly items-center gap-3 w-full mb-3 mt-5">
                  {plate_fields(plates, vehicles).map((field, index) => {
                    return (
                      <div className=" flex  flex-wrap h-20" key={index}>
                        <CustomInput
                          key={index}
                          type={field.type}
                          label={field.placeHolder}
                          value={realitonData[field.key]}
                          loading={isLoading}
                          fullWidth={true}
                          sx={{ width: '284px' }}
                          options={field.options}
                          items={field.items}
                          size={'medium'}
                          onChange={(key, value) => {
                            setRelationData({
                              ...realitonData,
                              [field.key]: value,
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
            <></>
          </TStepper>
        </Box>
      </Modal>
    </>
  );
};

export default Users;
