import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import services from '../service';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  SaveAs,
} from '@mui/icons-material';

export default function TStepper(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [loading, setLoading] = React.useState(false);

  const {
    steps,
    children,
    optionalSteps,
    formData,
    setData,
    helperText,
    fieldsEmpty,
    setModalOpen,
  } = props;

  const isStepOptional = (step) => {
    return optionalSteps?.includes(step);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const dropNulls = (array) => {
    return array.filter((item) => item !== null);
  };

  const postData = async (payload) => {
    const pay = {
      ...payload,
      insurances: dropNulls(payload.insurances),
      inspections: dropNulls(payload.inspections),
    };

    const result = await services.postCollectionVehicleData(pay);
    return result;
  };

  const saveFormData = () => {
    setLoading(true);
    const data = setData();
    try {
      // console.log(data)
      postData(data)
        .then((res) => {
          setLoading(false);
          if (res.data.response) {
            toast.error(
              'Plaka, Motor No veya Şasi No daha önce kayıt edilmiş. Lütfen kontrol ediniz.',
            );
            location.pathname.includes('edit')
              ? navigate('/fleet/vehicles')
              : setModalOpen(false);
          } else {
            toast.success('Başarılı bir şekilde kayıt edildi');
            location.pathname.includes('edit')
              ? navigate('/fleet/vehicles')
              : setModalOpen(false);
          }
        })
        .catch((err) => { });
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <Box sx={{
      width: '100%',
      height: '100%',
      padding: '1rem',
    }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Opsiyonel</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
          {children[activeStep]}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              sx={{ m: 1 }}
              disabled={activeStep === 0}
              onClick={handleBack}
              startIcon={<KeyboardArrowLeft />}
              variant="outlined"
            >
              GERİ
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {/* {isStepOptional(activeStep) && (
                            activeStep === steps.length - 1 ? null : (
                                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                    Atla
                                </Button>
                            )
                        )} */}

            {activeStep === steps.length - 1 ? (
              <Button
                startIcon={<SaveAs />}
                variant="contained"
                sx={{ m: 1 }}
                disabled={loading}
                onClick={
                  activeStep === steps.length - 1
                    ? async () => {
                      await setData(); // Wait for setData to complete
                      saveFormData(); // Call saveFormData after setData has updated the formData
                    }
                    : handleNext
                }
              >
                Kaydet
                {loading && (
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      className="w-4 h-4 mx-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </Button>
            ) : (
              <Button
                endIcon={<KeyboardArrowRight />}
                disabled={helperText !== '' || fieldsEmpty ? true : false}
                variant="contained"
                sx={{ m: 1 }}
                onClick={
                  activeStep === steps.length - 1
                    ? () => {
                      setData();
                      saveFormData();
                    }
                    : handleNext
                }
              >
                İLERİ
              </Button>
            )}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
