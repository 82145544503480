export const columns = [
  { field: 'firstname', headerName: 'İsim', width: 120 },
  { field: 'lastname', headerName: 'Soyisim', width: 120 },
  { field: 'id', headerName: 'Kullanıcı ID', width: 150 },
  //{ field: 'yerlemId', headerName: 'Yerlem ID', width: 150, notFilter: true  },
  { field: 'email', headerName: 'Email', width: 300 },
  { field: 'gsm', headerName: 'GSM', width: 150, filterEP: 'findByGsm' },
];

export const filterList = [
  { value: 'id', text: 'Kullanıcı ID' },
  { value: 'firstname', text: 'İsim' },
  { value: 'lastname', text: 'Soyisim' },
  { value: 'email', text: 'Email' },
  { value: 'gsm', text: 'Telefon' },
];
