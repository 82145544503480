import { filterList } from '../constants/table_columns_data';

export const FILTER_DATA = filterList.reduce((accumulator, item) => {
  const newAccumulator = { ...accumulator };
  newAccumulator[item.value] = '';
  return newAccumulator;
}, {});

export const COUNT = 0;
export const SORT_MODEL = [];
export const IS_LOADING = false;
export const PAGE = 0;
export const USERS = [];
