export const insurance_fields = () => [
    {
        key: "insuranceFirm",
        type: "text",
        validation: (value) => {
            if (!value) return true
            else false
        },
        placeHolder: "Sigorta Firması*",
        defaultValue: null,
    },
    // {
    //     key: "insuranceValidityMileage",
    //     type: "number",
    //     validation: (value) => {
    //         if (!value) return true
    //         else false
    //     },
    //     placeHolder: "Sigorta  Kilometre*",
    //     defaultValue: null,
    // },
    {
        key: "insurancePolicyNumber",
        type: "text",
        validation: (value) => {
            if (!value) return true
            else false
        },
        placeHolder: "Sigorta Poliçe Numarası*",
        defaultValue: null,
    },
    // {
    //     key: "insuranceValidityBeginsAt",
    //     type: "date",
    //     validation: (value) => {
    //         if (!value) return true
    //         else false
    //     },
    //     placeHolder: "Sigorta  Başlangıç Tarihi*",
    //     defaultValue: null,
    // },
    {
        key: "insuranceValidityEndsAt",
        type: "date",
        validation: (value) => {
            if (!value) return true
            else false
        },
        placeHolder: "Sigorta  Bitiş Tarihi*",
        defaultValue: null,
    },
]