import { EnterLayoutPage, LoginPage, ErrorPage, Fleet } from '../pages';
import { Products } from '../pages';
import { Users } from '../pages';
import { Vehicles } from '../pages';
import { Drivers } from '../pages';
import { Devices } from '../pages';
import { Inspections } from '../pages';
import { Insurances } from '../pages';
import EditVehicle from '../pages/fleet/Vehicles/EditVehicle';
import VehicleLicense from '../pages/fleet/Vehicles/VehicleLicense';
import { UploadMultipleVehicle } from '../pages';
import { UploadMultipleInspection } from '../pages';
import { UploadMultipleInsurance } from '../pages';
import { VehiclesWithTowCertificate } from '../pages';
import { SoldVehicles } from '../pages';
import { CPlateVehicles } from '../pages';
import { DistrictCPlateVehicles } from '../pages';
import { Plates } from '../pages';
import { VehicleProfile } from '../pages';
import { Documents } from '../pages';
import { UploadMultipleDocument } from '../pages';
import EditPlate from '../pages/fleet/Plates/EditPlate';
import { Kayral } from '../pages';
import Messages from '../pages/kayral/Messages';

const routes = [
  {
    path: '/',
    element: <Products />,
  },
  {
    path: '/login',
    element: (
      <EnterLayoutPage>
        <LoginPage />
      </EnterLayoutPage>
    ),
  },
  {
    path: '*',
    element: <ErrorPage />,
  },
  {
    path: '/fleet/dashboard',
    element: <Fleet />,
  },
  {
    path: '/fleet/users',
    element: <Users />,
  },
  {
    path: '/fleet/vehicles',
    element: <Vehicles />,
    // drawerElement: <CreateVehicle />,
  },
  {
    path: '/fleet/vehicles/edit/:id',
    element: <EditVehicle />,
  },
  {
    path: '/fleet/vehicles/vehicle-license/:id',
    element: <VehicleLicense />,
  },
  {
    path: '/fleet/upload-multiple-vehicle',
    element: <UploadMultipleVehicle />,
  },
  { path: '/fleet/inspections', element: <Inspections /> },
  { path: '/fleet/insurances', element: <Insurances /> },
  {
    path: '/fleet/upload-multiple-inspection',
    element: <UploadMultipleInspection />,
  },
  {
    path: '/fleet/vehicles-off-the-road',
    element: <VehiclesWithTowCertificate />,
  },
  {
    path: '/fleet/sold-vehicles',
    element: <SoldVehicles />,
  },
  {
    path: '/fleet/plates',
    element: <Plates />,
  },
  {
    path: '/fleet/vehicle-profile/:id',
    element: <VehicleProfile />,
  },
  {
    path: '/fleet/c-plate-vehicles',
    element: <CPlateVehicles />,
  },
  {
    path: '/fleet/plates/edit/:id',
    element: <EditPlate />,
  },
  {
    path: '/fleet/district-c-plate-vehicles',
    element: <DistrictCPlateVehicles />,
  },
  {
    path: '/fleet/upload-multiple-insurance',
    element: <UploadMultipleInsurance />,
  },
  {
    path: '/fleet/drivers',
    element: <Drivers />,
  },
  {
    path: '/fleet/devices',
    element: <Devices />,
  },
  {
    path: '/fleet/documents',
    element: <Documents />,
  },
  {
    path: '/fleet/upload-multiple-document',
    element: <UploadMultipleDocument />,
  },
  {
    path: "/kayral/dashboard",
    element: <Kayral />,
  },
  {
    path: "/kayral/messages",
    element: <Messages />,
  },
];

export default routes;
