import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Stack } from '@mui/material';
import Empty from '../../assets/images/empty.png';

const style = {
  border: 'none',
  borderRadius: '18px',
  '.MuiDataGrid-row.Mui-hovered, .MuiDataGrid-row.Mui-selected': {
    backgroundColor: '#E4F8F1',
  },
};

function TDataGrid(props) {
  const { rows, columns, sortModel, setSortModel } = props;
  return (
    <>
      <DataGrid
        getRowId={() => Math.random()}
        rows={rows}
        sx={style}
        columns={columns}
        autoHeight
        hideFooter
        showColumnRightBorder
        sortingMode="server"
        className="report-list-table-area-table"
        disableColumnMenu
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        components={{
          NoRowsOverlay: () => (
            <div className="flex flex-col justify-center items-center">
              <img src={Empty} className="w-20 h-20" alt="Bulunamadı" />
              <h1 className="font-bold">Veri Bulunamadı</h1>
            </div>
          ),
          NoResultsOverlay: () => (
            <div className="flex flex-col justify-center items-center">
              <img src={Empty} className="w-20 h-20" alt="Bulunamadı" />
              <h1 className="font-bold">
                Aradığınız kriterlere uygun veri bulunamadı.
              </h1>
            </div>
          ),
        }}
      />
    </>
  );
}

export default TDataGrid;
