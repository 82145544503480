import React, { useRef, useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import domtoimage from 'dom-to-image';
import { useBoundStore } from '../../stores';
import { useLocation } from 'react-router-dom';

function MapShot() {
  const mapRef = useRef(null);
  const [tilesLoaded, setTilesLoaded] = useState(false);
  const [showMap, setShowMap] = useState(true);

  const location = useLocation();

  const userLocation = useBoundStore((state) => state.userLocation);
  const startWatchingLocation = useBoundStore(
    (state) => state.startWatchingLocation,
  );
  const stopWatchingLocation = useBoundStore(
    (state) => state.stopWatchingLocation,
  );
  const setMapShot = useBoundStore((state) => state.setMapShot);

  const captureScreenshot = () => {
    if (!tilesLoaded) {
      console.log('Tiles not fully loaded yet');
      return;
    }

    const mapElement = mapRef.current?._container;

    if (mapElement) {
      domtoimage
        .toPng(mapElement, {
          width: mapElement.clientWidth,
          height: mapElement.clientHeight,
        })
        .then(function (dataUrl) {
          // Use the captured image as a background image for another component
          setMapShot(dataUrl);
          setShowMap(false);
        })
        .catch(function (error) {
          console.error('Capture failed:', error);
        });
    }
  };

  const handleTilesLoaded = () => {
    setTilesLoaded(true);
  };

  useEffect(() => {
    const watchId = startWatchingLocation();
    return () => stopWatchingLocation(watchId);
  }, []);

  useEffect(() => {
    console.log('MapShot: location changed');
    setTimeout(() => {
      captureScreenshot();
    }, 5000);
  }, [tilesLoaded]);

  // Listen for route changes
  useEffect(() => {
    // Check if the route changed to '/'
    if (location.pathname === '/') {
      // If the route changed to '/', capture a screenshot of the map
      setShowMap(true);
      setTimeout(() => {
        captureScreenshot();
      }, 5000);
    }
  }, [location]);

  return (
    <div
      id="backgroundComponent"
      style={{
        filter: 'blur(1px)',
      }}
    >
      {showMap ? (
        <MapContainer
          ref={mapRef}
          style={{
            width: '100vw',
            height: '100vh',
            filter: '  grayscale(100%)',
          }} // 100% dimensions
          center={userLocation} // Center the map on user's coordinates if available, otherwise default to a fixed location
          zoom={15  }
          scrollWheelZoom={false}
          attributionControl={false}
          zoomControl={false}
          whenReady={handleTilesLoaded} // Call handleTilesLoaded when the map is ready
        >
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
            onLoad={handleTilesLoaded} // Call handleTilesLoaded when the tile layer is loaded
          />
          {/* {userLocation && (
                        <Marker
                            icon={L.icon({
                                iconUrl: 'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png',
                                iconSize: [40, 40],
                                iconAnchor: [12, 41],
                            })}
                            position={userLocation}>
                            <Popup>Your location</Popup>
                        </Marker>
                    )} */}
        </MapContainer>
      ) : (
        <></>
      )}
    </div>
  );
}

export default MapShot;
