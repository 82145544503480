import httpHelper from '../../../../ui-sdk/core/service/httpHelper';
import { appConfig } from '../../../../constants/appConfig';
import { RequestParameters } from '../../../../ui-sdk/core/service/requestParameters';

const services = {

    async getChatList() {
        const requestParameters = new RequestParameters(
            'GET',
            null,
            null,
            null,
        );
        const endpoint = 'chat/getChatList';
        const result = await httpHelper.makeRequest(
            appConfig.kayral,
            endpoint,
            requestParameters,
            // 'Bearer',
        );
        return result;
    },

    async getChatListByUserId(params) {
        const requestParameters = new RequestParameters(
            'GET',
            params,
            null,
            null,
        );
        const endpoint = 'chat/getChatListByUserId';
        const result = await httpHelper.makeRequest(
            appConfig.kayral,
            endpoint,
            requestParameters,
            // 'Bearer',
        );
        return result;
    },
    async getChatListByTicketId(params) {
        const requestParameters = new RequestParameters(
            'GET',
            params,
            null,
            null,
        );
        const endpoint = 'chat/getChatListByTicketId';
        const result = await httpHelper.makeRequest(
            appConfig.kayral,
            endpoint,
            requestParameters,
            // 'Bearer',
        );
        return result;
    },

    async sendMessage(payload) {
        const requestParameters = new RequestParameters(
            'POST',
            null,
            null,
            payload,
        );
        const endpoint = 'chat/save';
        const result = await httpHelper.makeRequest(
            appConfig.kayral,
            endpoint,
            requestParameters,
            // 'Bearer',
        );
        return result;
    },

    async setAsRead(params) {
        const requestParameters = new RequestParameters(
            'GET',
            params,
            null,
            null,
        );
        const endpoint = '/supportTicket/setMessageSeen';
        const result = await httpHelper.makeRequest(
            appConfig.kayral,
            endpoint,
            requestParameters,
            // 'Bearer',
        );
        return result;
    }

};

export default services;
