import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Pagination, CircularProgress, Button } from '@mui/material';
import * as MATERIEL_ICONS from '@mui/icons-material/';
import TDataGrid from '../../../../components/Datagrid/Datagrid';
import { Pageable } from '../../../../ui-sdk/core/service/pageable';
import { Sort } from '../../../../ui-sdk/core/service/sort';
import service from './service';
import { columns } from './constants/table_columns_data';

const Users = () => {
  const location = useLocation();

  const [filterData, setFilterData] = useState({ query: '' });
  const [sortModel, setSortModel] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [count, setCount] = useState(0);
  const [helperText, setHelperText] = useState('');
  const [fieldsEmpty, setFieldsEmpty] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const paginationChange = (_, e_page) => setPage(e_page - 1);

  /*function isFieldsEmpty() {
    if (
      vehicleFormData.chasis === '' ||
      vehicleFormData.plate === '' ||
      vehicleFormData.engineNumber === ''
    ) {
      setFieldsEmpty(true);
      return true;
    } else {
      setFieldsEmpty(false);
      return false;
    }
  }
*/
  async function getData() {
    setIsLoading(true);
    const handleField = (field) => {
      const sortColumn = columns.filter((column) => column.field === field)[0];
      const res = sortColumn?.sortParameter
        ? sortColumn?.sortParameter
        : sortColumn?.field || 'createdAt';
      return res;
    };

    const handleTypeId = (field) => {
      const typeIdColumn = columns.filter(
        (column) => column.field === field,
      )[0];
      return typeIdColumn ? { typeId: typeIdColumn.typeId } : null;
    };

    const { field, sort } =
      sortModel.length > 0
        ? sortModel[0]
        : {
            field: 'createdAt',
            sort: 'desc',
          };

    const params = {
      ...handleTypeId(field),
    };

    const pageable = new Pageable(
      page,
      10,
      new Sort(handleField(field) || 'createdAt', sort),
    );
    const result = await service.getAllVehiclesWithDates(params, pageable);
    const data = result?.data;
    setCount(data?.totalPages);
    setVehicles(data?.content);
    setIsLoading(false);
  }

  const handleFilterInput = (newValue) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      query: newValue,
    }));
  };

  useEffect(() => {
    setPage(0);
    getData();
  }, [filterData]);

  const filter = async () => {
    setPage(0);
    getData();
  };

  useEffect(() => {
    getData();
  }, [page, sortModel, location, modalOpen]);

  return (
    <>
      <div className="p-3 m-3 flex flex-col justify-center items-center">
        <div className="w-full 2xl:w-max flex flex-col items-center p-3 m-3">
          <div className=" py-3 max-w-[800px] w-full mx-3 my-2 flex flex-wrap max-lg:w-full justify-between max-lg:justify-center gap-3 items-center">
            <span className="text-lg sm:text-2xl max-sm:text-center">
              C Plakalar
            </span>
            <div className="mx-2 my-5 flex justify-between items-center">
              <div className="flex gap-5">
                <Button
                  variant="contained"
                  startIcon={<MATERIEL_ICONS.SaveAsOutlined />}
                  color="primary"
                  onClick={() => {
                    handleExportExcelData(true);
                  }}
                  className="mx-2"
                >
                  EXCELE AKTAR
                </Button>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className="flex justify-center items-center w-[800px]">
              <CircularProgress className="users-list-circular-progress" />
            </div>
          ) : (
            <div className="flex lg:w-max max-lg:w-full justify-center items-center">
              <div className="w-full bg-white rounded-xl shadow-xl p-3 m-3">
                <TDataGrid
                  rows={[]}
                  columns={columns}
                  sortModel={sortModel}
                  setSortModel={setSortModel}
                />
                <div className="flex justify-center items-center p-3 m-3">
                  <Pagination
                    page={page + 1}
                    onChange={paginationChange}
                    count={count}
                    color="primary"
                    size="small"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Users;
