import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Pagination, CircularProgress, Button } from '@mui/material';
import * as MATERIEL_ICONS from '@mui/icons-material/';
import TDataGrid from '../../../../components/Datagrid/Datagrid';
import { Pageable } from '../../../../ui-sdk/core/service/pageable';
import { Sort } from '../../../../ui-sdk/core/service/sort';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CustomInput from '../../../../components/CustomInput';
import { fields } from './constants/create_vehicle_fields';
import { insurance_fields } from './constants/create_insurance_fields';
import { inspection_fields } from './constants/create_inspection_fields';
import TStepper from '../components/Stepper';
import service from './service';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { columns } from './constants/table_columns_data';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';

const boxStyle = {
  outline: 'none',
  position: 'absolute',
  top: '50%',
  left: '50%',
  borderRadius: 30,
  transform: 'translate(-50%, -50%)',
  width: 937,
  height: 'auto',
  backgroundColor: 'white',
  boxShadow: 24,
  padding: 30,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};
const Users = () => {
  const location = useLocation();
  const [filterData, setFilterData] = useState({ param: '' });
  const [sortModel, setSortModel] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [count, setCount] = useState(0);
  const [helperText, setHelperText] = useState('');
  const [fieldsEmpty, setFieldsEmpty] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [rowSize, setRowSize] = useState(10);
  const [vehicleFormData, setVehicleFormData] = React.useState({
    plate: '',
    debitUserId: '',
    engineNumber: '',
    chasis: '',
    brand: '',
    model: '',
    modelYear: '',
    type: '',
    load: '',
    fuelFactoryPerformance: '',
    coolant: '',
    description: '',
    status: '',
    availableFirm: '',
    documentSerialNo: '',
    document: '',
    purposeOfUsage: '',
    vehicleClass: '',
    queueNo: '',
  });
  const [seatInsuranceFormData, setSeatInsuranceFormData] = React.useState({
    insuranceTypeId: '0F8AJBREQ84FD',
    insuranceFirm: '',
    // insuranceValidityMileage: null,
    insurancePolicyNumber: '',
    // insuranceValidityBeginsAt: null,
    insuranceValidityEndsAt: null,
  });
  const [kaskoInsuranceFormData, setKaskoInsuranceFormData] = React.useState({
    insuranceTypeId: '0F8AMHDTV84FF',
    insuranceFirm: '',
    // insuranceValidityMileage: null,
    insurancePolicyNumber: '',
    // insuranceValidityBeginsAt: null,
    insuranceValidityEndsAt: null,
  });
  const [trafficInsuranceFormData, setTrafficInsuranceFormData] = React.useState({
    insuranceTypeId: '0F8AMKSAV84FG',
    insuranceFirm: '',
    // insuranceValidityMileage: null,
    insurancePolicyNumber: '',
    // insuranceValidityBeginsAt: null,
    insuranceValidityEndsAt: null,
  });
  const [vehicleInspectionFormData, setVehicleInspectionFormData] = React.useState({
    inspectionTypeId: '0F8AN274Q84FH',
    inspectionFirm: '',
    inspectionValidityMileage: null,
    inspectionPolicyNumber: '',
    // inspectionValidityBeginsAt: null,
    inspectionValidityEndsAt: null,
  });
  const [exhaustInspectionFormData, setExhaustInspectionFormData] = React.useState({
    inspectionTypeId: '0F8AN4NA384FJ',
    inspectionFirm: '',
    inspectionValidityMileage: null,
    inspectionPolicyNumber: '',
    // inspectionValidityBeginsAt: null,
    inspectionValidityEndsAt: null,
  });
  const [formData, setFormData] = React.useState({
    ...vehicleFormData,
    insurances: {
      ...seatInsuranceFormData,
      ...kaskoInsuranceFormData,
      ...trafficInsuranceFormData,
    },
    inspections: {
      ...vehicleInspectionFormData,
      ...exhaustInspectionFormData,
    },
  });
  const paginationChange = (_, e_page) => setPage(e_page - 1);
  const XLSX = require('xlsx');
  async function checkChassisNumber() {
    const result = await service.checkChassisNumber({
      chassisNumber: vehicleFormData.chasis,
    });
    result?.data?.Results?.map((item) => {
      if (item?.Variable === 'Error Code') {
        if (item?.Value?.includes('6')) {
          setHelperText('Eksik / Hatalı Şasi Numarası, kontrol ediniz.');
          return true;
        } else {
          setHelperText('');
          return false;
        }
      }
    });
  }
  function isFieldsEmpty() {
    if (
      vehicleFormData.chasis === '' ||
      vehicleFormData.plate === '' ||
      vehicleFormData.engineNumber === ''
    ) {
      setFieldsEmpty(true);
      return true;
    } else {
      setFieldsEmpty(false);
      return false;
    }
  }
  async function getData() {
    setIsLoading(true);

    const { field, sort } =
      sortModel.length > 0
        ? sortModel[0]
        : {
          field: 'createdAt',
          sort: 'desc',
        };

    const handleField = (field) => {
      const sortColumn = columns.filter((column) => column.field === field)[0];
      const res = sortColumn?.sortParameter
        ? sortColumn?.sortParameter
        : sortColumn?.field || 'createdAt';
      return res;
    };

    const handleTypeId = (field) => {
      const typeIdColumn = columns.find((column) => column.field === field);
      const typeId = typeIdColumn ? { typeId: typeIdColumn.typeId } : null;
      return { ...typeId };
    };

    const params = {
      ...handleTypeId(field),
      statusTypeId: '0FKN2BPR307C6',
      ...(filterData.param ? { param: filterData.param } : {}),
    };

    const pageable = new Pageable(
      page,
      rowSize,
      new Sort(handleField(field) || 'createdAt', sort),
    );

    const result = await service.getAllVehiclesWithDates(params, pageable);

    const data = result?.data;
    setCount(data?.totalPages);
    setVehicles(data?.content);
    setIsLoading(false);
  }
  /*
  async function getData() {
    setIsLoading(true);
    const handleField = (field) => {
      const sortColumn = columns.filter((column) => column.field === field)[0];
      const res = sortColumn?.sortParameter
        ? sortColumn?.sortParameter
        : sortColumn?.field || 'createdAt';
      return res;
    };

    const handleTypeId = (field) => {
      const typeIdColumn = columns.find((column) => column.field === field);
      const typeId = typeIdColumn ? { typeId: typeIdColumn.typeId } : null;
      return { ...typeId };
    };

    const { field, sort } =
      sortModel.length > 0
        ? sortModel[0]
        : {
            field: 'createdAt',
            sort: 'desc',
          };

    const params = {
      ...handleTypeId(field),
      statusTypeId: '0FFTEF9ZQT6M7',
    };

    const pageable = new Pageable(
      page,
      rowSize,
      new Sort(handleField(field) || 'createdAt', sort),
    );
    const result = await service.getAllVehiclesWithDates(params, pageable);
    const data = result?.data;
    setCount(data?.totalPages);
    setVehicles(data?.content);
    setIsLoading(false);
  }

  async function searchData() {
    setIsLoading(true);
    const pageable = new Pageable(page, rowSize, null);
    const result = await service.getAllVehicleWithParam(
      { param: filterData.param },
      pageable,
    );
    const data = result?.data;
    setCount(data?.totalPages);
    setVehicles(data?.content);
    setIsLoading(false);
  }
*/
  const handleExportExcelData = async () => {
    //SIRALAMAYI İNDİRMEK İÇİN
    console.log('vehicles', vehicles);
    const headers = [
      'Plaka',
      'Firma',
      'Marka',
      'Model',
      'Tipi',
      'Şasi',
      'Motor No',
      'Muayene Bitiş Tarihi',
      'Egzoz Muayene Bitiş Tarihi',
      'Trafik Sigorta Bitiş Tarihi',
      'Kasko Sigorta Bitiş Tarihi',
      'Koltuk Sigorta Bitiş Tarihi',
    ];

    const data = vehicles.map((vehicle) => {
      const inspection = vehicle.inspections.filter(
        (inspection) => inspection.typeId === '0F8AN274Q84FH',
      );
      const inspectionEndDate = inspection[0]?.validityEndsAt;
      const exhaustInspection = vehicle.inspections.filter(
        (inspection) => inspection.typeId === '0F8AN4NA384FJ',
      );
      const exhaustInspectionEndDate = exhaustInspection[0]?.validityEndsAt;
      const trafficInsurance = vehicle.insurances.filter(
        (insurance) => insurance.typeId === '0F8AMKSAV84FG',
      );
      const trafficInsuranceEndDate = trafficInsurance[0]?.validityEndsAt;
      const kaskoInsurance = vehicle.insurances.filter(
        (insurance) => insurance.typeId === '0F8AMHDTV84FF',
      );
      const kaskoInsuranceEndDate = kaskoInsurance[0]?.validityEndsAt;
      const seatInsurance = vehicle.insurances.filter(
        (insurance) => insurance.typeId === '0F8AJBREQ84FD',
      );
      const seatInsuranceEndDate = seatInsurance[0]?.validityEndsAt;
      return [
        vehicle.plate,
        vehicle.availableFirm,
        vehicle.brand,
        vehicle.model,
        vehicle.type,
        vehicle.chasis,
        vehicle.engineNumber,
        inspectionEndDate ? moment(inspectionEndDate).format('DD.MM.YYYY') : '',
        exhaustInspectionEndDate
          ? moment(exhaustInspectionEndDate).format('DD.MM.YYYY')
          : '',
        trafficInsuranceEndDate
          ? moment(trafficInsuranceEndDate).format('DD.MM.YYYY')
          : '',
        kaskoInsuranceEndDate
          ? moment(kaskoInsuranceEndDate).format('DD.MM.YYYY')
          : '',
        seatInsuranceEndDate
          ? moment(seatInsuranceEndDate).format('DD.MM.YYYY')
          : '',
      ];
    });

    const workbook = XLSX.utils.book_new();
    const worksheetData = [headers, ...data]; // Headers and data combined
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'Araçlar.xlsx');
  };
  const handleFilterInput = (newValue) => {
    setPage(0); // Reset page to 0
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      param: newValue,
    }));
  };
  const filter = async () => {
    searchData();
  };
  useEffect(() => {
    getData();
  }, [filterData, page, rowSize, sortModel, location, modalOpen]);
  useEffect(() => {
    checkChassisNumber();
  }, [vehicleFormData.chasis, vehicleFormData.modelYear]);
  useEffect(() => {
    isFieldsEmpty();
  }, [
    vehicleFormData.chasis,
    vehicleFormData.plate,
    vehicleFormData.engineNumber,
  ]);
  const checkInsuranceFields = (data) => {
    if (
      data.insuranceFirm != '' ||
      data.insurancePolicyNumber != '' ||
      data.insuranceValidityBeginsAt != null ||
      data.insuranceValidityEndsAt != null
    ) {
      return data;
    }
  };
  const checkInspectionFields = (data) => {
    if (
      data.inspectionFirm != '' ||
      data.inspectionPolicyNumber != '' ||
      data.inspectionValidityBeginsAt != null ||
      data.inspectionValidityEndsAt != null
    ) {
      return data;
    }
  };
  const setData = () => {
    const data = {
      ...vehicleFormData,
      insurances: [
        checkInsuranceFields(seatInsuranceFormData)
          ? checkInsuranceFields(seatInsuranceFormData)
          : null,
        checkInsuranceFields(kaskoInsuranceFormData)
          ? checkInsuranceFields(kaskoInsuranceFormData)
          : null,
        checkInsuranceFields(trafficInsuranceFormData)
          ? checkInsuranceFields(trafficInsuranceFormData)
          : null,
      ],
      inspections: [
        checkInspectionFields(vehicleInspectionFormData)
          ? checkInspectionFields(vehicleInspectionFormData)
          : null,
        checkInspectionFields(exhaustInspectionFormData)
          ? checkInspectionFields(exhaustInspectionFormData)
          : null,
      ],
    };

    setFormData({
      ...vehicleFormData,
      insurances: [
        checkInsuranceFields(seatInsuranceFormData)
          ? checkInsuranceFields(seatInsuranceFormData)
          : null,
        checkInsuranceFields(kaskoInsuranceFormData)
          ? checkInsuranceFields(kaskoInsuranceFormData)
          : null,
        checkInsuranceFields(trafficInsuranceFormData)
          ? checkInsuranceFields(trafficInsuranceFormData)
          : null,
      ],
      inspections: [
        checkInspectionFields(vehicleInspectionFormData)
          ? checkInspectionFields(vehicleInspectionFormData)
          : null,
        checkInspectionFields(exhaustInspectionFormData)
          ? checkInspectionFields(exhaustInspectionFormData)
          : null,
      ],
    });

    return data;
  };
  return (
    <>
      <div className="p-3 m-3 flex flex-col justify-center items-center">
        <div className="w-full 2xl:w-max flex flex-col items-center p-3 m-3">
          <div className=" py-3 w-max mx-3 my-2 flex flex-wrap max-lg:w-full justify-between max-lg:justify-center gap-3 items-center">
            <span className="text-lg sm:text-2xl max-sm:text-center">
              Satılmış Araçlar
            </span>
            <div className="mx-2 my-5 flex justify-between items-center">
              <div className="flex flex-wrap justify-center gap-5">
                <TextField
                  size="small"
                  placeholder="Arama"
                  onChange={(e) => handleFilterInput(e.target.value)}
                  value={filterData.query}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MATERIEL_ICONS.Search />
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: '8px',
                    },
                  }}
                />

                {/*<Button
                  variant="contained"
                  startIcon={<MATERIEL_ICONS.Add />}
                  color="primary"
                  onClick={() => {
                    setModalOpen(true);
                  }}
                  className="mx-2"
                >
                  YENİ ARAÇ EKLE
                </Button>*/}
                <Button
                  variant="contained"
                  startIcon={<MATERIEL_ICONS.SaveAsOutlined />}
                  color="primary"
                  onClick={() => {
                    handleExportExcelData(true);
                  }}
                  className="mx-2"
                >
                  EXCELE AKTAR
                </Button>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className="flex justify-center items-center w-[1200px]">
              <CircularProgress className="users-list-circular-progress" />
            </div>
          ) : (
            <div className="flex lg:w-max max-lg:w-full justify-center items-center">
              <div className="w-full   bg-white rounded-xl shadow-xl p-3 m-3">
                <TDataGrid
                  rows={vehicles || []}
                  columns={columns}
                  sortModel={sortModel}
                  setSortModel={setSortModel}
                />
                <div className="flex justify-center gap-10 items-center p-3 m-3">
                  <Pagination
                    page={page + 1}
                    onChange={paginationChange}
                    count={count}
                    color="primary"
                    size="small"
                  />
                  <Autocomplete
                    id="combo-box-demo"
                    options={[5, 10, 20, 50, 100, 200, 500, 1000]}
                    getOptionLabel={(option) => option.toString()}
                    style={{ width: 150 }}
                    onChange={(e, value) => setRowSize(value)}
                    value={rowSize}
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Satır sayısı" />
                    )}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <Box style={boxStyle}>
          <TStepper
            steps={['Araç Bilgileri', 'Sigorta Bilgileri', 'Muayene Bilgileri']}
            optionalSteps={[1, 2]}
            formData={formData}
            setData={setData}
            helperText={helperText}
            fieldsEmpty={fieldsEmpty}
            setModalOpen={setModalOpen}
          >
            {/* Araç Bilgileri */}
            <>
              <div className="mt-5">
                <div>
                  <Typography
                    variant="h5"
                    color="initial"
                    gutterBottom
                    textAlign={'center'}
                  >
                    Yeni Araç Ekle
                  </Typography>
                </div>

                <div className="flex flex-row flex-wrap justify-start items-center gap-3 w-full">
                  {fields().map((field, index) => {
                    return (
                      <div className=" flex  flex-wrap h-20" key={index}>
                        <CustomInput
                          key={index}
                          type={field.type}
                          label={field.placeHolder}
                          value={vehicleFormData[field.key]}
                          error={
                            (field.key === 'chasis' && helperText !== '') ||
                              ((field.key === 'engineNumber' ||
                                field.key === 'plate' ||
                                field.key === 'chasis') &&
                                vehicleFormData[field.key] === '')
                              ? true
                              : false
                          }
                          errorText={
                            (field.key === 'engineNumber' ||
                              field.key === 'plate' ||
                              field.key === 'chasis') &&
                              vehicleFormData[field.key] === ''
                              ? 'Bu alan boş bırakılamaz'
                              : field.key === 'chasis' && helperText
                          }
                          fullWidth={true}
                          sx={{ width: '284px' }}
                          items={field.items}
                          size={'medium'}
                          onChange={(key, value) => {
                            setVehicleFormData({
                              ...vehicleFormData,
                              [field.key]: value,
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
            {/* Sigorta Bilgileri */}
            <>
              <div className="mt-5 ">
                <div>
                  <Typography
                    variant="h5"
                    color="initial"
                    gutterBottom
                    textAlign={'center'}
                  >
                    Sigorta Bilgileri
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="h6"
                    color="initial"
                    gutterBottom
                    textAlign={'left'}
                  >
                    Trafik Sigortası
                  </Typography>
                </div>
                <div className="flex flex-row flex-wrap  items-center gap-3 w-full mb-3">
                  {insurance_fields().map((field, index) => {
                    return (
                      <div className=" flex  flex-wrap h-20" key={index}>
                        <CustomInput
                          key={index}
                          type={field.type}
                          label={field.placeHolder}
                          value={trafficInsuranceFormData[field.key]}
                          fullWidth={false}
                          size={'medium'}
                          sx={{ width: '284px' }}
                          onChange={(key, value) => {
                            setTrafficInsuranceFormData({
                              ...trafficInsuranceFormData,
                              [field.key]: value,
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                <div>
                  <Typography
                    variant="h6"
                    color="initial"
                    gutterBottom
                    textAlign={'left'}
                  >
                    Kasko
                  </Typography>
                </div>
                <div className="flex flex-row flex-wrap  items-center gap-3 w-full mb-3 ">
                  {insurance_fields().map((field, index) => {
                    return (
                      <div className=" flex  flex-wrap h-20" key={index}>
                        <CustomInput
                          key={index}
                          type={field.type}
                          label={field.placeHolder}
                          value={kaskoInsuranceFormData[field.key]}
                          fullWidth={false}
                          size={'medium'}
                          sx={{ width: '284px' }}
                          onChange={(key, value) => {
                            setKaskoInsuranceFormData({
                              ...kaskoInsuranceFormData,
                              [field.key]: value,
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                <div>
                  <Typography
                    variant="h6"
                    color="initial"
                    gutterBottom
                    textAlign={'left'}
                  >
                    Koltuk Sigortası
                  </Typography>
                </div>
                <div className="flex flex-row flex-wrap  items-center gap-3 w-full mb-3">
                  {insurance_fields().map((field, index) => {
                    return (
                      <div className=" flex  flex-wrap h-20" key={index}>
                        <CustomInput
                          key={index}
                          type={field.type}
                          label={field.placeHolder}
                          value={seatInsuranceFormData[field.key]}
                          fullWidth={false}
                          size={'medium'}
                          sx={{ width: '284px' }}
                          onChange={(key, value) => {
                            setSeatInsuranceFormData({
                              ...seatInsuranceFormData,
                              [field.key]: value,
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
            {/* Muayene Bilgileri */}
            <>
              <div className="mt-5">
                <Typography
                  variant="h5"
                  color="initial"
                  gutterBottom
                  textAlign={'center'}
                >
                  Muayene Bilgileri
                </Typography>
              </div>
              <div>
                <div>
                  <Typography
                    variant="h6"
                    color="initial"
                    gutterBottom
                    textAlign={'left'}
                  >
                    Zorunlu Araç Muayenesi
                  </Typography>
                </div>
                <div className="flex flex-row flex-wrap  items-center gap-3 w-full mb-3">
                  {inspection_fields().map((field, index) => {
                    return (
                      <div className=" flex  flex-wrap h-20" key={index}>
                        <CustomInput
                          key={index}
                          type={field.type}
                          label={field.placeHolder}
                          value={vehicleInspectionFormData[field.key]}
                          fullWidth={false}
                          size={'medium'}
                          sx={{ width: '284px' }}
                          onChange={(key, value) => {
                            setVehicleInspectionFormData({
                              ...vehicleInspectionFormData,
                              [field.key]: value,
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                <div>
                  <Typography
                    variant="h6"
                    color="initial"
                    gutterBottom
                    textAlign={'left'}
                  >
                    Zorunlu Araç Egzoz Muayenesi
                  </Typography>
                </div>
                <div className="flex flex-row flex-wrap  items-center gap-3 w-full mb-3">
                  {inspection_fields().map((field, index) => {
                    return (
                      <div className=" flex  flex-wrap h-20" key={index}>
                        <CustomInput
                          key={index}
                          type={field.type}
                          label={field.placeHolder}
                          value={exhaustInspectionFormData[field.key]}
                          fullWidth={false}
                          sx={{ width: '284px' }}
                          size={'medium'}
                          onChange={(key, value) => {
                            setExhaustInspectionFormData({
                              ...exhaustInspectionFormData,
                              [field.key]: value,
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          </TStepper>
        </Box>
      </Modal>
    </>
  );
};
export default Users;
