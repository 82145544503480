import httpHelper from '../../../../ui-sdk/core/service/httpHelper';
import { RequestParameters } from '../../../../ui-sdk/core/service/requestParameters';
const APP_CONFIG = JSON.parse(localStorage.getItem('appConfig'));

const service = {
  // async getDrivers(params) {
  //   const endPoint = `user-role/findAllByRoleId/KV_DRIVER`;
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     params,
  //     'Bearer',
  //   );
  //   return result;
  // },
  // async getDriversWithSearch(query) {
  //   const endpoint = '';
  // },
  async getDriversWithSearchAndKv(params, pageable) {
    const requestParameters = new RequestParameters(
      'GET',
      params,
      pageable,
      null,
    );
    const endpoint = 'kv/driver/search';
    const result = await httpHelper.makeRequest(
      APP_CONFIG,
      endpoint,
      requestParameters,
      'Bearer',
    );
    return result;
  },
  // async getDriversWithFilter(params) {
  //   const endpoint = 'user-role/findByLikeIgnoreCase';
  //   const result = await httpHelper.makeGetRequest(
  //     APP_CONFIG,
  //     endpoint,
  //     params,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async saveUser(payload) {
  //   const endPoint = `signup`;
  //   const result = await httpHelper.makePostRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },

  // async updateUser(payload) {
  //   const endPoint = `user`;
  //   const result = await httpHelper.makePutRequest(
  //     APP_CONFIG,
  //     endPoint,
  //     payload,
  //     'Bearer',
  //   );
  //   return result;
  // },
};

export default service;
