import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Pagination, CircularProgress, Button, Modal } from '@mui/material';
import * as MATERIEL_ICONS from '@mui/icons-material/';
import Template from '../../../../assets/xlsxs/UPLOADMULTIPLEINSURANCE.xlsx';
import service from '../service';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { toast } from 'react-toastify';
import TDataGrid from '../../../../components/Datagrid/Datagrid';
import { columns } from './constants/table_columns_data';

const UploadMultipleDocument = () => {
  const location = useLocation();
  const fileInputRef = useRef(null);

  const [filterData, setFilterData] = useState({ query: '' });
  const [sortModel, setSortModel] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const paginationChange = (_, e_page) => setPage(e_page - 1);
  const handleFileDownload = async () => {
    try {
      const excelFileUrl = Template;
      const response = await fetch(excelFileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Sigorta Ekleme Şablonu.xlsx';
      a.click();
    } catch (error) {
      console.error('Dosya indirme sırasında bir hata oluştu:', error);
    }
  };

  const handleFileUpload = () => {
    try {
      setIsLoading(true);
      fileInputRef.current.click();
      setIsLoading(false);
    } catch (error) {
      console.error('Dosya yükleme sırasında bir hata oluştu:', error);
    }
  };
  const parseExcelDate = (excelDate) => {
    // Verinin istenilen formatta olup olmadığını kontrol etmek için bir regex kullanalım
    if (
      excelDate === null ||
      excelDate === '' ||
      excelDate === undefined ||
      excelDate === 'undefined' ||
      excelDate === 'YOK' ||
      excelDate === 'yok' ||
      excelDate === 'Yok'
    ) {
      return null;
    } else {
      const dateFormatRegex = /^(\d{1,2})[\/.-](\d{1,2})[\/.-](\d{2}|\d{4})$/;
      // Eğer gelen veri null ya da boş ise direkt olarak null döndürelim
      if (
        !excelDate ||
        typeof excelDate !== 'string' ||
        !dateFormatRegex.test(excelDate)
      ) {
        return null;
      }

      const parts = excelDate.split(/[\/.-]/); // Gün, ay ve yıl parçalarına ayır
      let day = 0;
      let month = 0;
      let year = parseInt(parts[2]);
      if (parseInt(parts[1]) > 12) {
        day = parseInt(parts[1]);
        month = parseInt(parts[0]);
      } else {
        day = parseInt(parts[0]);
        month = parseInt(parts[1]);
      }
      // Eğer yıl sadece iki haneli ise 2000 yılına ekle
      if (year < 100) {
        year += 2000;
      }

      const DateExcel = new Date(year, month - 1, day);
      return DateExcel; // Yeni bir tarih nesnesi oluştur ve döndür
    }
  };

  const handleFileChange = (e) => {
    try {
      setIsLoading(true);
      const file = e.target.files[0];
      if (file && file.name.endsWith('.xlsx')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const fileContent = e.target.result;
          const workbook = XLSX.read(fileContent, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const range = XLSX.utils.decode_range(sheet['!ref']);
          const totalRows = range.e.r;
          const documents = [];

          for (let rowIndex = 1; rowIndex <= totalRows; rowIndex++) {
            try {
              const chassisNumber =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 0 })];
              const insuranceFirm =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 1 })];
              const insuranceType =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 2 })];
              const insurancePolicyNumber =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 3 })];
              const insuranceEndDate =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 4 })];

              if (!chassisNumber) {
                continue;
              }
              const getIsoDate = (date) => {
                return date ? parseExcelDate(date)?.toISOString() : null;
              };
              const insuranceEndIso = getIsoDate(insuranceEndDate?.v);
              const insuranceTypeId =
                (insuranceType?.v).toUpperCase() === 'KOLTUK'
                  ? '0F8AJBREQ84FD'
                  : insuranceType?.v.toUpperCase() === 'KASKO'
                    ? '0F8AMHDTV84FF'
                    : insuranceType?.v.toUpperCase() === 'ZORUNLU'
                      ? '0F8AMKSAV84FG'
                      : null;

              documents.push({
                chassisNumber: chassisNumber?.v,
                insurances: [
                  {
                    typeId: insuranceTypeId,
                    firm: insuranceFirm?.v,
                    policyNumber: insurancePolicyNumber?.v,
                    validityEndsAt: insuranceEndIso,
                  },
                ],
                inspections: [],
              });
            } catch (error) {
              console.error('Dosya içeriği okunurken bir hata oluştu:', error);
            }
          }
          console.log('documents', documents);
          setDocuments(documents);
          setIsLoading(false);
        };
        reader.onprogress = (event) => {
          if (event.lengthComputable) {
            // İlerleme takibi yapabilirsiniz
            const progress = (event.loaded / event.total) * 100;
            console.log('Yükleme ilerlemesi:', progress);
          }
        };
        reader.readAsBinaryString(file);
      }
    } catch (error) {
      console.error('Dosya yükleme sırasında bir hata oluştu:', error);
      setIsLoading(false);
    }
  };

  const handleSaveConfirm = async () => {
    setModalOpen(true);
    const unsuccessfulDocument = []; // Başarısız olan belgeleri izlemek için bir dizi oluşturuldu
    try {
      for (const document of documents) {
        try {
          const result =
            await service.postCollectionInsuranceInspectionData(document);
          if (result.status == 200) {
            toast.success(document.chassisNumber + ' muayene kaydedildi');
          } else {
            toast.error(
              document.chassisNumber + ' muayene kaydedilirken hata oluştu',
            );
            unsuccessfulDocument.push(document); // Başarısız olan aracı listeye ekle
          }
        } catch (err) {
          console.error('Muayene kaydedilirken bir hata oluştu:', err);
          unsuccessfulDocument.push(document); // Başarısız olan aracı listeye ekle
        }
      }

      handleDownloadUnsuccessfulDocument(unsuccessfulDocument);
    } catch (error) {
      console.error('Muayene kaydedilirken bir hata oluştu:', error);
    }
    setModalOpen(false);
  };
  const handleDownloadUnsuccessfulDocument = (unsuccessfulDocument) => {
    try {
      const XLSX = require('xlsx');
      if (unsuccessfulDocument.length > 0) {
        const headers = [
          'Şasi No',
          'Sigorta Firması',
          'Sigorta Tipi',
          'Sigorta Bitiş Tarihi',
        ];

        const data = unsuccessfulDocument.map((vehicle) => {
          return [
            vehicle.chassisNumber,
            vehicle.insuranceFirm,
            vehicle.insuranceTypeId,
            vehicle.insuranceEndDate,
          ];
        });

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'Kaydedilemeyen_Sigortalar.xlsx');
        toast.info('Başarısız kaydetmeler Excel dosyasına dönüştürüldü');
      }
    } catch (error) {
      console.error(
        'Başarısız olan muayeneleri Excel dosyasına dönüştürme sırasında bir hata oluştu:',
        error,
      );
    }
  };

  const handleFilterInput = (newValue) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      query: newValue,
    }));
  };

  useEffect(() => {
    moment.locale('tr');
    setPage(0);
  }, [filterData]);

  const filter = async () => {
    setPage(0);
  };

  useEffect(() => { }, [page, sortModel, location]);

  return (
    <div className="p-3 m-3 flex flex-col justify-center items-center">
      <div className="w-full max-lg:w-full p-3 m-3 flex flex-col items-center">
        <div className=" py-3 px-2 mx-3 flex max-w-[900px] w-full  flex-wrap justify-between max-sm:justify-center gap-3 items-center">
          <span className="text-lg sm:text-2xl max-sm:text-center">
            Toplu Belge Yükleme
          </span>
          <div className="mx-2 my-5 flex justify-between items-center">
            {/*  <Button variant="contained" color="primary" onClick={onChangeVehicle}>
              gönder
            </Button>*/}
            <div className="flex flex-wrap justify-center gap-5">
              <Button
                variant="contained"
                startIcon={<MATERIEL_ICONS.FileDownload />}
                color="primary"
                onClick={handleFileDownload}
                className="mx-2"
              >
                ŞABLONU İNDİR
              </Button>
              <Button
                variant="contained"
                startIcon={<MATERIEL_ICONS.FileUpload />}
                color="primary"
                onClick={handleFileUpload}
                className="mx-2"
              >
                <input
                  ref={fileInputRef}
                  type="file"
                  accept=".xlsx"
                  style={{ display: 'none' }} // Dosya seçme inputunu gizler
                  onChange={handleFileChange}
                />
                BELGELERİ EKLE
              </Button>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center w-[890px]">
            <CircularProgress className="users-list-circular-progress" />
          </div>
        ) : (
          <div className=" flex lg:w-max max-lg:w-full justify-center items-center">
            <div
              className="p-3 m-3 rounded-lg shadow-2xl bg-white w-full overflow-y-auto"
              style={{ maxHeight: '400px' }}
            >
              <TDataGrid
                rows={documents}
                columns={columns}
                sortModel={sortModel}
                setSortModel={setSortModel}
              />
            </div>
            {
              // SONRA AKTİF OLACAK

              documents.length > 0 && (
                <div className="fixed bottom-0 right-0 w-full flex flex-row-reverse  shadow-xl xl:px-18 lg:px-16 md:px-12 px-8 py-3">
                  <Button
                    variant="contained"
                    onClick={() => setModalOpen(true)}
                    color="primary"
                  >
                    Kayıt İşlemini Tamamla
                  </Button>
                </div>
              )
            }
            <Modal
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div className="fixed inset-0 flex justify-center items-center">
                <div className="fixed inset-0 flex justify-center items-center">
                  <div className="w-[400px] h-[150px] bg-white rounded-xl shadow-xl p-3 m-3 flex flex-col justify-around items-center">
                    <span className="text-md max-sm:text-center">
                      Kayıt işlemini tamamlamak istiyor musunuz?
                    </span>
                    <div className="w-full flex justify-end gap-2 px-4 ">
                      <Button
                        variant="outlined"
                        className="w-1/3 bg-red-400"
                        color="error"
                        onClick={() => setModalOpen(false)}
                      >
                        İptal
                      </Button>
                      <Button
                        variant="outlined"
                        className="w-1/3 "
                        color="success"
                        onClick={handleSaveConfirm}
                      >
                        Onayla
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadMultipleDocument;
