import { SortType } from "./sortType";

const DEFAULT_SORT_PARAMETERS = {
    SORT_TYPE: SortType.DESC
}

/**
 * @param key {!string}
 * @param sortType {?SortType}
 * @constructor
 */
function Sort(key, sortType) {
    if (key == null) {
        throw new Error("Sort::key undefined bir tür olamaz.")
    }

    const _key = key
    const _sortType = (sortType == null)
        ? DEFAULT_SORT_PARAMETERS.SORT_TYPE
        : (!SortType.isValid(sortType))
            ? (() => {
                console.log(sortType);
                throw new Error("sortType parametresi kabul edilebilir bir tür değil.")
            })()
            : sortType


    this.getKey = function () { return _key }
    this.getSortType = function () { return _sortType }
}

export { Sort }