import { Edit, Visibility } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import moment from 'moment';
import { Link } from 'react-router-dom';

function calculateDateDifference(startDateStr) {
  // Başlangıç tarihini moment objesine dönüştür
  var startDate = moment(startDateStr, 'DD.MM.YYYY');
  // console.log(startDate);
  // Bugünkü tarihi al
  var currentDate = moment();

  // İki tarih arasındaki farkı hesapla
  var difference = moment.duration(startDate.diff(currentDate));
  var daysDifference = difference.asDays();

  // Sonucu döndür
  return Math.floor(daysDifference);
}

export const columns = [
  {
    field: 'plate',
    headerName: 'Araç Plaka',
    width: 120,
  },
  { field: 'chasis', headerName: 'Şase No', width: 200 },
  { field: 'brand', headerName: 'Marka', width: 160 },
  { field: 'model', headerName: 'Model', width: 160 },
  {
    field: 'actions',
    headerName: '',
    width: 90,
    align: 'center',
    notFilter: true,
    renderCell: (e) => (
      <>
        <Link to={`/fleet/vehicles/edit/${e.row.id}`}>
          <IconButton size="small">
            <Edit />
          </IconButton>
        </Link>
        <Link to={`/fleet/vehicles/vehicle-license/${e.row.id}`}>
          <IconButton size="small">
            <Visibility />
          </IconButton>
        </Link>
      </>
    ),
  },
];
