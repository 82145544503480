import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Pagination, CircularProgress, Button } from '@mui/material';
import * as MATERIEL_ICONS from '@mui/icons-material/';
import TDataGrid from '../../../components/Datagrid/Datagrid';
import { TextField } from '@mui/material';
import { InputAdornment } from '@mui/material';
import { Pageable } from '../../../ui-sdk/core/service/pageable';

import { useBoundStore } from '../../../stores/index';
import service from './service';

import { columns } from './constants/table_columns_data';

const boxStyle = {
  outline: 'none',
  position: 'absolute',
  top: '50%',
  left: '50%',
  borderRadius: 30,
  transform: 'translate(-50%, -50%)',
  width: 937,
  height: 'auto',
  backgroundColor: 'white',
  boxShadow: 24,
  padding: 30,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

const Users = () => {
  const location = useLocation();

  const [filterData, setFilterData] = useState({ query: '' });
  const [sortModel, setSortModel] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [count, setCount] = useState(0);

  const toggleOpen = useBoundStore((state) => state.toggleOpen);

  const paginationChange = (_, e_page) => setPage(e_page - 1);

  async function getData() {
    setIsLoading(true);
    const { field, sort } =
      sortModel.length > 0 ? sortModel[0] : { field: '', sort: '' };
    const params = {
      ...filterData,
    };
    const pageable = new Pageable(page, 10);
    const result = await service.getAllInsurances({}, pageable);
    const data = result?.data?.content;
    setCount(result?.data?.totalPages);
    setVehicles(data);

    setIsLoading(false);
  }

  const handleFilterInput = (newValue) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      query: newValue,
    }));
  };

  useEffect(() => {
    setPage(0);
    //getData();
  }, [filterData]);

  useEffect(() => {
    getData();
  }, [page, sortModel, location]);

  return (
    <>
      <div className="p-3 m-3 flex flex-col justify-center items-center">
        <div className="w-full 2xl:w-max p-3 m-3 flex flex-col items-center">
          <div className="max-w-[700px] w-full py-3 px-2 mx-3 my-2 flex flex-wrap justify-between max-sm:justify-center gap-3 items-start">
            <span className="text-lg sm:text-2xl max-sm:text-center">
              Belgeler
            </span>
          </div>
          {isLoading ? (
            <div className="flex justify-center items-center w-[1200px]">
              <CircularProgress className="users-list-circular-progress" />
            </div>
          ) : (
            <div className="w-[900px] md:w-max max-md:w-full 2xl:w-max bg-white rounded-xl shadow-xl p-3 m-3">
              <TDataGrid
                rows={vehicles}
                columns={columns}
                sortModel={sortModel}
                setSortModel={setSortModel}
              />
              <div className="flex justify-center items-center p-3 m-3">
                <Pagination
                  page={page + 1}
                  onChange={paginationChange}
                  count={count}
                  color="primary"
                  size="small"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Users;
