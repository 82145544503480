export const fields = () => [

    {
        key: "plate",
        type: "text",
        validation: (value) => {
            if (!value) return true
            else false
        },
        placeHolder: "Plaka",
        defaultValue: null,
    },
    {
        key: "debitUserId",
        type: "text",
        validation: (value) => {
            if (!value) return true
            else false
        },
        placeHolder: "Zimmetli Şoför",
        defaultValue: null,
    },
    {
        key: "engineNumber",
        type: "text",
        validation: (value) => {
            if (!value) return true
            else false
        },
        placeHolder: "Motor No*",
        defaultValue: null,
    },
    {
        key: "chasis",
        type: "text",
        validation: (value) => {
            if (!value) return true
            else false
        },
        placeHolder: "Şasi No*",
        defaultValue: null,
    },
    {
        key: "brand",
        type: "text",
        validation: (value) => {
            if (!value) return true
            else false
        },
        placeHolder: "Marka",
        defaultValue: null,
    },
    {
        key: "model",
        type: "text",
        validation: (value) => {
            if (!value) return true
            else false
        },
        placeHolder: "Model",
        defaultValue: null,
    },
    {
        key: "modelYear",
        type: "number",
        validation: (value) => {
            if (!value) return true
            else false
        },
        placeHolder: "Model Yılı",
        defaultValue: null,
    },
    {
        key: "type",
        type: "text",
        validation: (value) => {
            if (!value) return true
            else false
        },
        placeHolder: "Tip",
        defaultValue: null,
    },
    {
        key: "load",
        type: "number",
        validation: (value) => {
            if (!value) return true
            else false
        },
        placeHolder: "Koltuk Sayısı",
        defaultValue: null,
    },
    {
        key: "fuelFactoryPerformance",
        type: "number",
        validation: (value) => {
            if (!value) return true
            else false
        },
        placeHolder: "Yakıt Fabrika Performansı",
        defaultValue: null,
    },
    {
        key: "description",
        type: "text",
        validation: (value) => {
            if (!value) return true
            else false
        },
        placeHolder: "Açıklama",
        defaultValue: null,
    },
    {
        key: "coolant",
        type: "select",
        items: [
            { value: 1, text: "Klima var" },
            { value: 0, text: "Klima yok" },
            { value: -1, text: "Bilinmiyor" }
        ],
        validation: (value) => {
            if (!value) return true
            else false
        },
        placeHolder: "Klima",
        defaultValue: null,
    },
    {
        key: "document",
        type: "text",
        validation: (value) => {
            if (!value) return true
            else false
        },
        placeHolder: "Belge",
        defaultValue: null,
    },
    {
        key: "documentSerialNo",
        type: "text",
        validation: (value) => {
            if (!value) return true
            else false
        },
        placeHolder: "Belge Seri No",
        defaultValue: null,
    },
    {
        key: "purposeOfUsage",
        type: "text",
        validation: (value) => {
            if (!value) return true
            else false
        },
        placeHolder: "Kullanım Amacı",
        defaultValue: null,
    },
    {
        key: "vehicleClass",
        type: "text",
        validation: (value) => {
            if (!value) return true
            else false
        },
        placeHolder: "Sınıf",
        defaultValue: null,
    },
    {
        key: "queueNo",
        type: "text",
        validation: (value) => {
            if (!value) return true
            else false
        },
        placeHolder: "Sıra No",
        defaultValue: null,
    },
    {
        key: "status",
        type: "select",
        items: [
            { value: true, text: "Aktif" },
            { value: false, text: "Pasif" },
        ],
        validation: (value) => {
            if (!value) return true
            else false
        },
        placeHolder: "Durum",
        defaultValue: null,
    },
    {
        key: "availableFirm",
        type: "text",
        validation: (value) => {
            if (!value) return true
            else false
        },
        placeHolder: "Kullanılabilir Firma",
        defaultValue: null,
    },
]