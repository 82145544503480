import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Pagination, CircularProgress, Button } from '@mui/material';
import * as MATERIEL_ICONS from '@mui/icons-material/';
import TDataGrid from '../../../components/Datagrid/Datagrid';
import { TextField } from '@mui/material';
import { InputAdornment } from '@mui/material';

import { columns } from './constants/table_columns_data';
import service from './service';
import { Pageable } from '../../../ui-sdk/core/service/pageable';
import { Sort } from '../../../ui-sdk/core/service/sort';

const Users = () => {
  const location = useLocation();

  const [filterData, setFilterData] = useState({ query: '' });
  const [sortModel, setSortModel] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [count, setCount] = useState(0);

  const paginationChange = (_, e_page) => setPage(e_page - 1);

  async function getData() {
    setIsLoading(true);
    const { field, sort } =
      sortModel.length > 0
        ? sortModel[0]
        : { field: 'createdAt', sort: 'desc' };
    const params = {
      ...filterData,
    };
    const pageable = new Pageable(page, 10, new Sort(field, sort));
    const result = await service.getDriversWithSearchAndKv(params, pageable);
    const data = result?.data?.data || [];
    const properties = result?.data?.properties || {};
    setCount(properties.totalPages);
    setDevices(data);
    setIsLoading(false);
  }

  const handleFilterInput = (newValue) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      query: newValue,
    }));
  };

  useEffect(() => {
    setPage(0);
    getData();
  }, [filterData]);

  const filter = async () => {
    setPage(0);
    getData();
  };

  useEffect(() => {
    getData();
  }, [page, sortModel, location]);

  return (
    <div className="p-3 m-3 flex flex-col justify-center items-center">
      <div className="w-max   max-lg:w-full p-3 m-3">
        <div className=" py-3 px-2 mx-3 my-2 flex flex-wrap justify-between max-sm:justify-center gap-3 items-center">
          <span className="text-lg sm:text-2xl max-sm:text-center">
            Sürücüler
          </span>
          <div className="mx-2 my-5 flex justify-between items-center">
            <div className="flex ">
              <TextField
                size="small"
                placeholder="Arama"
                onChange={(e) => handleFilterInput(e.target.value)}
                value={filterData.query}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MATERIEL_ICONS.Search />
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: '8px',
                    backgroundColor: '#fff',
                  },
                }}
              />
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center w-[890px]">
            <CircularProgress className="users-list-circular-progress" />
          </div>
        ) : (
          <div className="w-max max-lg:w-full bg-white rounded-xl shadow-xl p-3 m-3">
            <TDataGrid
              rows={devices}
              columns={columns}
              sortModel={sortModel}
              setSortModel={setSortModel}
            />
            <div className="flex justify-center items-center p-3 m-3">
              <Pagination
                page={page + 1}
                onChange={paginationChange}
                count={count}
                color="primary"
                size="small"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Users;
