import { Delete, Edit } from '@mui/icons-material';
import moment from 'moment';

export const columns = [
  { field: 'chassisNumber', headerName: 'Şase No', width: 180 },
  {
    field: 'inspectionTypeId',
    headerName: 'Muayene Tipi',
    width: 180,
    renderCell: (e) => {
      const inspectionTypeId = e.row.inspections.map((inspection) => {
        return inspection.typeId;
      });
      const inspectionType =
        inspectionTypeId[0] === '0F8AN274Q84FH'
          ? 'MUAYENE'
          : inspectionTypeId[0] === '0F8AN4NA384FJ'
            ? 'EGZOZ MUAYENESİ'
            : 'BİLİNMİYOR';
      return <div className="w-full pl-4">{inspectionType}</div>;
    },
  },
  {
    field: 'inspectionPolicyNumber',
    headerName: 'Poliçe No',
    width: 150,
    renderCell: (e) => {
      const inspectionPolicyNumber = e.row.inspections.map((inspection) => {
        return inspection.policyNumber;
      });
      return <div className="w-full pl-4">{inspectionPolicyNumber}</div>;
    },
  },
  {
    field: 'inspectionEndDate',
    headerName: 'Muayene Bitiş Tarihi',
    width: 200,
    renderCell: (e) => {
      const inspectionEndDate = e.row.inspections.map((inspection) => {
        return inspection.validityEndsAt;
      });
      return (
        <div className="w-full pl-4">
          {moment(inspectionEndDate).format('DD/MM/YYYY')}
        </div>
      );
    },
  },
  {
    field: 'inspectionVehicleMillage',
    headerName: 'Araç Km',
    width: 100,
    renderCell: (e) => {
      const inspectionVehicleMillage = e.row.inspections.map((inspection) => {
        return inspection.validityMileage;
      });
      return <div className="w-full pl-4">{inspectionVehicleMillage}</div>;
    },
  },
  {
    field: 'actions',
    headerName: '',
    width: 70,
    renderCell: (params) => {
      return (
        <div>
          <Edit />
          <Delete />
        </div>
      );
    },
  },
];
