export const plate_fields = (plates, vehicles) => {
  const platesText = plates?.map((plate) => plate.plate);
  const platesValue = plates?.map((plate) => plate.plateId);
  const platesItems = platesText?.map((text, index) => ({
    label: platesText[index],
    id: platesValue[index],
  }));
  const vehicleId = vehicles?.map((vehicle) => vehicle.id);
  const vehicleText = vehicles?.map(
    (vehicle) =>
      vehicle.brand +
      ' - ' +
      vehicle.model +
      ' - ' +
      vehicle.modelYear +
      ' - Şasi No : ' +
      vehicle.chasis,
  );
  const vehicleItems = vehicleText?.map((text, index) => ({
    id: vehicleId[index],
    label: vehicleText[index],
  }));
  return [
    {
      key: 'plateId',
      type: 'auto',
      options: platesItems,
      validation: (value) => {
        if (!value) return true;
        else false;
      },
      placeHolder: 'Plaka',
      defaultValue: null,
    },

    {
      key: 'vehicleId',
      type: 'auto',
      options: vehicleItems,
      validation: (value) => {
        if (!value) return true;
        else false;
      },
      placeHolder: 'Araç',
      defaultValue: null,
    },
  ];
};
