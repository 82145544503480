import React from 'react';
import moment from 'moment';
import vehicleImage from '../../../../../assets/images/busIcon.svg';

function InfoCard({ vehicle, key }) {
  console.log(vehicle);
  return (
    <div
      key={key}
      className=" text-[6px] md:text-[8px] lg:text-[10px] xl:text-[13px] w-full items-center flex flex-col md:flex-row bg-white rounded-xl shadow-xl "
    >
      <div className="flex h-[350px] w-full md:w-[400px]  md:rounded-s-xl bg-blue-500/30 md:border-r-2 md:border-r-black justify-center">
        <img
          src={vehicleImage}
          className="w-[400px] md:w-[400px] object-cover rounded-l-xl"
          alt="truck"
        />
      </div>

      <div className="flex w-full items-center flex-col p-3 m-3">
        <div className="flex flex-wrap w-full border-b border-gray-100 gap-3">
          <p>
            <strong>PLAKA : </strong> {vehicle.plate}
          </p>
          <p>
            <strong>ŞİRKET : </strong> {vehicle.availableFirm}
          </p>
          <p>
            <strong>ŞOFÖR : </strong> {vehicle.debitUserId}
          </p>
          <p>
            <strong>AÇIKLAMA : </strong> {vehicle.description}
          </p>
          <p>
            <strong>KULLANIM AMACI : </strong> {vehicle.purposeOfUsage}
          </p>
          <p>
            <strong>ARAÇ SINIFI : </strong> {vehicle.vehicleClass}
          </p>
          <p>
            <strong>BELGESİ : </strong> {vehicle.document}
          </p>
          <p>
            <strong>DURUM : </strong> {vehicle.status ? 'AKTİF' : 'PASİF'}
          </p>
        </div>
        <div className="flex flex-wrap w-full border-b border-gray-100 py-2 gap-3">
          <p>
            <strong>MARKA : </strong> {vehicle.brand}
          </p>
          <p>
            <strong>MODEL : </strong> {vehicle.model}
          </p>
          <p>
            <strong>MODEL YILI : </strong> {vehicle.modelYear}
          </p>
          <p>
            <strong>BELGE SERİ NO : </strong>
            {vehicle.documentSerialNo}
          </p>
          <p>
            <strong>MOTOR NO : </strong> {vehicle.engineNumber}
          </p>
          <p>
            <strong>ŞASE NO : </strong> {vehicle.chasis}
          </p>
          <p>
            <strong>TİPİ : </strong> {vehicle.type}
          </p>
          <p>
            <strong>KOLTUK : </strong> {vehicle.load}
          </p>
          <p>
            <strong>FABRİKA YAKIT VERİSİ : </strong>
            {vehicle.fuelFactoryPerformance}
          </p>
          <p>
            <strong>KLİMA : </strong> {vehicle.coolant == '1' ? 'VAR' : 'YOK'}
          </p>
        </div>
        {vehicle.inspections.map((inspection, idx) => (
          <div
            key={idx}
            className="flex flex-wrap w-full border-b border-gray-100 py-2 gap-3"
          >
            {inspection.inspectionTypeId === '0F8AN274Q84FH' && (
              <>
                <p>
                  <strong>MUAYENE FİRMASI : </strong>
                  {inspection.inspectionFirm}
                </p>
                <p>
                  <strong>MUAYENE BİTİŞİ : </strong>
                  {inspection.inspectionValidityEndsAt &&
                    moment(inspection.inspectionValidityEndsAt).format(
                      'DD.MM.YYYY',
                    )}
                </p>
                <p>
                  <strong>MUAYENE KM : </strong>
                  {inspection.inspectionValidityMileage}
                </p>
                <p>
                  <strong>MUAYENE EVRAK NO : </strong>
                  {inspection.inspectionPolicyNumber}
                </p>
              </>
            )}
            {inspection.inspectionTypeId === '0F8AN4NA384FJ' && (
              <>
                <p>
                  <strong>EGZOZ MUAYENE FİRMASI : </strong>
                  {inspection.inspectionFirm}
                </p>
                <p>
                  <strong>EGZOZ MUAYENE BİTİŞİ : </strong>
                  {inspection.inspectionValidityEndsAt &&
                    moment(inspection.inspectionValidityEndsAt).format(
                      'DD.MM.YYYY',
                    )}
                </p>

                <p>
                  <strong>EGZOZ MUAYENE EVRAK NO : </strong>
                  {inspection.inspectionPolicyNumber}
                </p>
              </>
            )}
          </div>
        ))}
        {vehicle.insurances.map((insurance, idx) => (
          <div
            key={idx}
            className="flex flex-wrap w-full border-b border-gray-100 py-2 gap-3"
          >
            {insurance.insuranceTypeId === '0F8AMKSAV84FG' ? (
              <>
                <p>
                  <strong>ZORUNLU TRAFİK POLİÇESİ FİRMASI : </strong>
                  {insurance.insuranceFirm}
                </p>

                <p>
                  <strong>ZORUNLU TRAFİK POLİÇESİ BİTİŞ TARİHİ : </strong>
                  {insurance.insuranceValidityEndsAt &&
                    moment(insurance.insuranceValidityEndsAt).format(
                      'DD.MM.YYYY',
                    )}
                </p>

                <p>
                  <strong>ZORUNLU TRAFİK POLİÇE NO : </strong>
                  {insurance.insurancePolicyNumber}
                </p>
              </>
            ) : insurance.insuranceTypeId === '0F8AJBREQ84FD' ? (
              <>
                <p>
                  <strong>KOLTUK SİGORTASI FİRMASI : </strong>
                  {insurance.insuranceFirm}
                </p>
                <p>
                  <strong>KOLTUK SİGORTASI BİTİŞ TARİHİ : </strong>
                  {insurance.insuranceValidityEndsAt &&
                    moment(insurance.insuranceValidityEndsAt).format(
                      'DD.MM.YYYY',
                    )}
                </p>

                <p>
                  <strong>KOLTUK SİGORTASI POLİÇE NO : </strong>
                  {insurance.insurancePolicyNumber}
                </p>
              </>
            ) : insurance.insuranceTypeId === '0F8AMHDTV84FF' ? (
              <>
                <p>
                  <strong>KASKO FİRMASI : </strong>
                  {insurance.insuranceFirm}
                </p>
                <p>
                  <strong>KASKO BİTİŞ TARİHİ : </strong>
                  {insurance.insuranceValidityEndsAt  &&
                    moment(insurance.insuranceValidityEndsAt).format(
                      'DD.MM.YYYY',
                    )}
                </p>

                <p>
                  <strong>KASKO POLİÇE NO : </strong>
                  {insurance.insurancePolicyNumber}
                </p>
              </>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
}

export default InfoCard;
