const HeaderTypes = {
    ContentType: "content-type",
    Authorization: "Authorization",
    Expires: "Expires",
    Cookie: "Cookie",
    Date: "Date"
}

function Header(header, value) {
    if (header == null) {
        throw new Error("Header null olamaz.")
    }

    let isValid = false
    let headerKey = null
    for (let key in HeaderTypes) {
        if (key === header) {
            isValid = true
            headerKey = HeaderTypes[key]
            break
        } else if (HeaderTypes[key] === header) {
            isValid = true
            headerKey = HeaderTypes[key]
            break
        }
    }

    if (!isValid) {
        throw new Error("Geçerli bir header değil: " + header)
    }

    this.getKey = function () { return headerKey }
    this.getValue = function () { return value }

    this.asObject = function () {
        const object = {}
        object[headerKey] = value
        return object
    }
}

/**
 *
 * @param headers {Array<Header>}
 * @constructor
 */
function Headers(headers) {
    console.log(headers);
    for (let header in headers) {
        console.log(header instanceof Header);
        if (!(headers[header] instanceof Header)) {
            throw new Error("Parametre Header türünde değil.")
        }
    }

    const _headers = headers
    this.asObjectList = function () {
        const object = {}
        _headers.forEach(h => object[h.getKey()] = h.getValue())
        return object
    }

    this.newWith = function (hs) {
        const list = _headers
        list.push(hs)
        return new Headers(list)
    }
}

export { Header, HeaderTypes, Headers }