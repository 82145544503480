import React from 'react';
import { useState, useEffect } from 'react';
import services from './service';
import { Modal, Box, Typography, IconButton } from '@mui/material';

import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Close } from '@mui/icons-material';

const boxStyle = {
  outline: 'none',
  position: 'absolute',
  top: '50%',
  left: '50%',
  borderRadius: 30,
  transform: 'translate(-50%, -50%)',
  width: 1100,
  height: '100%',
  backgroundColor: 'white',
  boxShadow: 24,
  padding: 30,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  overflowY: 'auto',
};

function VehicleLicense() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [vehicleFormData, setVehicleFormData] = React.useState({
    plate: '',
    debitUserId: '',
    engineNumber: '',
    chasis: '',
    brand: '',
    model: '',
    modelYear: '',
    type: '',
    load: '',
    fuelFactoryPerformance: '',
    coolant: '',
    description: '',
    status: '',
    availableFirm: '',
    documentSerialNo: '',
    document: '',
    purposeOfUsage: '',
    vehicleClass: '',
    queueNo: '',
  });

  const [modalOpen, setModalOpen] = useState(true);

  const getCoolant = (value) => {
    if (value == '1') {
      return 'Var';
    } else if (value == '0') {
      return 'Yok';
    } else if (value == '-1') {
      return 'Bilinmiyor';
    }
  };

  const getStatus = (value) => {
    if (value === true) {
      return 'Aktif';
    } else if (value === false) {
      return 'Pasif';
    }
  };

  const getVehicleData = async () => {
    setIsLoading(true);
    const response = await services.getVehicleByVehicleId(id);
    if (response) {
      setVehicleFormData(response.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getVehicleData();
  }, []);

  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          navigate(-1);
        }}
      >
        <div
          className='flex flex-col outline-none justify-center items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[900px] max-2xl:w-full  max-2xl:h-full bg-white p-12 w-[1280px] overflow-y-auto'
        >
          <div className='w-full flex justify-between items-center'>
            <div className='flex justify-center items-center '>
              <span className='max-sm:text-sm' >
                (~) Ruhsatta bulunmayıp, eklenen alanlar.
              </span>
            </div>
            <IconButton onClick={() => { setModalOpen(false); navigate(-1); }}>
              <Close />
            </IconButton>
          </div>

          <Typography variant="h5" textAlign={'center'} gutterBottom>
            {vehicleFormData?.plate} Ruhsat Bilgileri
          </Typography>
          <div className="flex flex-wrap justify-center items-start w-full h-full">
            <table className="border-[#A7B5F1] border-[15px] max-w-[500px] w-full flex flex-wrap">
              <tr className="w-full flex max-sm:flex-col">
                <td className=" h-12 w-full flex justify-center items-center border-[1px] border-black">
                  <input
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] "
                    placeholder="(Y.1) VERİLDİĞİ İL/İLÇE"
                    disabled
                  ></input>
                </td>
              </tr>
              <tr className="w-full flex max-sm:flex-col">
                <td className=" h-12 w-1/2 max-sm:w-full flex flex-col justify-center items-center border-[1px] border-black">
                  <p
                    htmlFor="plate"
                    className="w-full h-full outline-none border-none text-[12px] "
                  >
                    {' '}
                    (A) PLAKA
                  </p>
                  <input
                    name="plate"
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px]"
                    placeholder="(A) PLAKA"
                    value={vehicleFormData.plate}
                    onChange={(e) =>
                      setVehicleFormData({
                        ...vehicleFormData,
                        plate: e.target.value,
                      })
                    }
                    disabled
                  ></input>
                </td>
                <td className=" h-12 w-1/2 max-sm:w-full  flex justify-center items-center border-[1px] border-black">
                  <input
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] "
                    placeholder="(B) İLK TESCİL TARİHİ"
                    disabled
                  ></input>
                </td>
              </tr>
              <tr className="w-full flex max-sm:flex-col">
                <td className=" h-12 w-1/2 max-sm:w-full flex flex-col justify-center items-center border-[1px] border-black">
                  <label
                    htmlFor="queueNo"
                    className="w-full h-full outline-none border-none text-[12px] "
                  >
                    {' '}
                    (Y.2) TESCİL SIRA NO
                  </label>
                  <input
                    name="queueNo"
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] "
                    placeholder="(Y.2) TESCİL SIRA NO"
                    value={vehicleFormData.queueNo}
                    onChange={(e) =>
                      setVehicleFormData({
                        ...vehicleFormData,
                        queueNo: e.target.value,
                      })
                    }
                    disabled
                  ></input>
                </td>
                <td className=" h-12 w-1/2 max-sm:w-full flex justify-center items-center border-[1px] border-black">
                  <input
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] "
                    placeholder="(I) TESCİL TARİHİ"
                    disabled
                  ></input>
                </td>
              </tr>
              <tr className="w-full flex max-sm:flex-col">
                <td className=" h-12 w-1/2 max-sm:w-full flex flex-col justify-center items-center border-[1px] border-black">
                  <label
                    htmlFor="brand"
                    className="w-full h-full outline-none border-none text-[12px] "
                  >
                    {' '}
                    (D.1) MARKASI
                  </label>
                  <input
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] "
                    placeholder="(D.1) MARKASI"
                    value={vehicleFormData.brand}
                    onChange={(e) =>
                      setVehicleFormData({
                        ...vehicleFormData,
                        brand: e.target.value,
                      })
                    }
                    disabled
                  ></input>
                </td>
                <td className=" h-12 w-1/2 max-sm:w-full flex flex-col justify-center items-center border-[1px] border-black">
                  <label
                    htmlFor="type"
                    className="w-full h-full outline-none border-none text-[12px] "
                  >
                    {' '}
                    (D.2) TİPİ
                  </label>
                  <input
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] "
                    placeholder="(D.2) TİPİ"
                    value={vehicleFormData.type}
                    onChange={(e) =>
                      setVehicleFormData({
                        ...vehicleFormData,
                        type: e.target.value,
                      })
                    }
                    disabled
                  ></input>
                </td>
              </tr>
              <tr className="w-full flex max-sm:flex-col">
                <td className=" h-12 w-1/2 max-sm:w-full flex flex-col justify-center items-center border-[1px] border-black">
                  <label
                    htmlFor="model"
                    className="w-full h-full outline-none border-none text-[12px] "
                  >
                    {' '}
                    (~) MODELİ
                  </label>
                  <input
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] "
                    placeholder="(~) MODELİ"
                    value={vehicleFormData.model}
                    onChange={(e) =>
                      setVehicleFormData({
                        ...vehicleFormData,
                        model: e.target.value,
                      })
                    }
                    disabled
                  ></input>
                </td>
                <td className=" h-12 w-1/2 max-sm:w-full flex justify-center items-center border-[1px] border-black">
                  <td className=" h-12 w-1/2 max-sm:w-full flex flex-col justify-center items-center border-[1px] border-black">
                    <label
                      htmlFor="modelYear"
                      className="w-full h-full outline-none border-none text-[12px] "
                    >
                      {' '}
                      (D.4) MODEL YILI
                    </label>
                    <input
                      type="text"
                      className="w-full h-full outline-none border-none p-2 text-[12px] "
                      placeholder="(D.4) MODEL YILI"
                      value={vehicleFormData.modelYear}
                      onChange={(e) =>
                        setVehicleFormData({
                          ...vehicleFormData,
                          modelYear: e.target.value,
                        })
                      }
                      disabled
                    ></input>
                  </td>
                  <td className=" h-12 w-1/2 max-sm:w-full flex flex-col justify-center items-center border-[1px] border-black">
                    <label
                      htmlFor="vehicleClass"
                      className="w-full h-full outline-none border-none text-[12px] "
                    >
                      {' '}
                      (J) ARAÇ SINIFI
                    </label>
                    <input
                      type="text"
                      className="w-full h-full outline-none border-none p-2 text-[12px] "
                      placeholder="(J) ARAÇ SINIFI"
                      value={vehicleFormData.vehicleClass}
                      onChange={(e) =>
                        setVehicleFormData({
                          ...vehicleFormData,
                          vehicleClass: e.target.value,
                        })
                      }
                      disabled
                    ></input>
                  </td>
                </td>
              </tr>
              <tr className="w-full flex max-sm:flex-col">
                <td className=" h-12 w-1/2 max-sm:w-full flex justify-center items-center border-[1px] border-black">
                  <input
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] "
                    placeholder="(D.5) CİNSİ"
                    disabled
                  ></input>
                </td>
                <td className=" h-12 w-1/2 max-sm:w-full flex justify-center items-center border-[1px] border-black">
                  <input
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] "
                    placeholder="(R) RENGİ"
                    disabled
                  ></input>
                </td>
              </tr>
              <tr className="w-full flex max-sm:flex-col">
                <td className=" h-12 w-full flex flex-col justify-center items-center border-[1px] border-black">
                  <label
                    htmlFor="engineNumber"
                    className="w-full h-full outline-none border-none text-[12px] "
                  >
                    {' '}
                    (P.5) MOTOR NO*
                  </label>
                  <input
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] "
                    placeholder="(P.5) MOTOR NO*"
                    required
                    value={vehicleFormData.engineNumber}
                    onChange={(e) =>
                      setVehicleFormData({
                        ...vehicleFormData,
                        engineNumber: e.target.value,
                      })
                    }
                    disabled
                  ></input>
                </td>
              </tr>
              <tr className="w-full flex max-sm:flex-col">
                <td className=" h-12 w-full flex flex-col justify-center items-center border-[1px] border-black">
                  <label
                    htmlFor="chasis"
                    className="w-full h-full outline-none border-none text-[12px] "
                  >
                    {' '}
                    (E) ŞASE NO*
                  </label>
                  <input
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] "
                    placeholder="(E) ŞASE NO*"
                    required
                    value={vehicleFormData.chasis}
                    onChange={(e) =>
                      setVehicleFormData({
                        ...vehicleFormData,
                        chasis: e.target.value,
                      })
                    }
                    disabled
                  ></input>
                </td>
              </tr>
              <tr className="w-full flex max-sm:flex-col">
                <td className=" h-12 w-1/2 max-sm:w-full flex flex-col justify-center items-center border-[1px] border-black">
                  <label
                    htmlFor="fuelFactoryPerformance"
                    className="w-full h-full outline-none border-none text-[12px] "
                  >
                    {' '}
                    (~) YAKIT FABRİKA PERFORMANSI
                  </label>
                  <input
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] "
                    placeholder="(~) YAKIT FABRİKA PERFORMANSI"
                    value={vehicleFormData.fuelFactoryPerformance}
                    onChange={(e) =>
                      setVehicleFormData({
                        ...vehicleFormData,
                        fuelFactoryPerformance: e.target.value,
                      })
                    }
                    disabled
                  ></input>
                </td>
                <td className=" h-12 w-1/2 max-sm:w-full flex flex-col justify-center items-center border-[1px] border-black">
                  <label
                    htmlFor="coolant"
                    className="w-full h-full outline-none border-none text-[12px] "
                  >
                    {' '}
                    (~) KLİMA BİLGİSİ
                  </label>
                  <input
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] "
                    placeholder="(~) KLİMA BİLGİSİ"
                    value={getCoolant(vehicleFormData.coolant)}
                    onChange={(e) =>
                      setVehicleFormData({
                        ...vehicleFormData,
                        coolant: e.target.value,
                      })
                    }
                    disabled
                  ></input>
                </td>
              </tr>
              <tr className="w-full flex max-sm:flex-col">
                <td className=" h-12 w-1/2 max-sm:w-full flex justify-center items-center border-[1px] border-black">
                  <input
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] "
                    placeholder="(G) KATAR AĞIRLIĞI"
                    disabled
                  ></input>
                </td>
                <td className=" h-12 w-1/2 max-sm:w-full flex justify-center items-center border-[1px] border-black">
                  <input
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] "
                    placeholder="(G.2) RÖMORK AZAMİ YÜKLÜ AĞIRLIĞI"
                    disabled
                  ></input>
                </td>
              </tr>
              <tr className="w-full flex max-sm:flex-col">
                <td className=" h-12 w-1/2 max-sm:w-full flex flex-col justify-center items-center border-[1px] border-black">
                  <label
                    htmlFor="load"
                    className="w-full h-full outline-none border-none text-[12px] "
                  >
                    {' '}
                    (S.1) KOLTUK SAYISI (SÜR. DAHİL)
                  </label>
                  <input
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] "
                    placeholder="(S.1) KOLTUK SAYISI (SÜR. DAHİL)"
                    value={vehicleFormData.load}
                    onChange={(e) =>
                      setVehicleFormData({
                        ...vehicleFormData,
                        load: e.target.value,
                      })
                    }
                    disabled
                  ></input>
                </td>
                <td className=" h-12 w-1/2 max-sm:w-full flex justify-center items-center border-[1px] border-black">
                  <input
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] "
                    placeholder="(S.2) AYAKTA YOLCU SAYISI"
                    disabled
                  ></input>
                </td>
              </tr>
              <tr className="w-full flex max-sm:flex-col">
                <td className=" h-12 w-1/2 max-sm:w-full flex justify-center items-center border-[1px] border-black">
                  <input
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] "
                    placeholder="(P.1) SİLİNDİR HACMİ"
                    disabled
                  ></input>
                </td>
                <td className=" h-12 w-1/2 max-sm:w-full flex justify-center items-center border-[1px] border-black">
                  <input
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] "
                    placeholder="(P.2) MOTOR GÜCÜ"
                    disabled
                  ></input>
                </td>
              </tr>
              <tr className="w-full flex max-sm:flex-col">
                <td className=" h-12 w-1/2 max-sm:w-full flex flex-col justify-center items-center border-[1px] border-black">
                  <label
                    htmlFor="availableFirm"
                    className="w-full h-full outline-none border-none text-[12px] "
                  >
                    {' '}
                    (~) KULLANABİLİR FİRMA
                  </label>
                  <input
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] "
                    placeholder="(~) KULLANABİLİR FİRMA"
                    value={vehicleFormData.availableFirm}
                    onChange={(e) =>
                      setVehicleFormData({
                        ...vehicleFormData,
                        availableFirm: e.target.value,
                      })
                    }
                    disabled
                  ></input>
                </td>
                <td className=" h-12 w-1/2 max-sm:w-full flex justify-center items-center border-[1px] border-black">
                  <input
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] "
                    placeholder="(Q) GÜÇ AĞIRLIK ORANI (Motosiklet)"
                    disabled
                  ></input>
                </td>
              </tr>
              <tr className="w-full flex max-sm:flex-col">
                <td className=" h-12 w-1/2 max-sm:w-full flex flex-col justify-center items-center border-[1px] border-black">
                  <label
                    htmlFor="purposeOfUsage"
                    className="w-full h-full outline-none border-none text-[12px] "
                  >
                    {' '}
                    (Y.3) KULLANIM AMACI
                  </label>
                  <input
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] "
                    placeholder="(Y.3) KULLANIM AMACI"
                    value={vehicleFormData.purposeOfUsage}
                    onChange={(e) =>
                      setVehicleFormData({
                        ...vehicleFormData,
                        purposeOfUsage: e.target.value,
                      })
                    }
                    disabled
                  ></input>
                </td>
                <td className=" h-12 w-1/2 max-sm:w-full flex flex-col justify-center items-center border-[1px] border-black">
                  <label
                    htmlFor="status"
                    className="w-full h-full outline-none border-none text-[12px] "
                  >
                    {' '}
                    (~) DURUM
                  </label>
                  <input
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] "
                    placeholder="(~) DURUM"
                    value={getStatus(vehicleFormData.status)}
                    onChange={(e) =>
                      setVehicleFormData({
                        ...vehicleFormData,
                        status: e.target.value,
                      })
                    }
                    disabled
                  ></input>
                </td>
              </tr>
            </table>
            <table className="border-[#A7B5F1] border-[15px] max-w-[500px] w-full">
              <tr className="w-full flex max-sm:flex-col">
                <td className=" h-12 w-full flex justify-center items-center border-[1px] border-black">
                  <input
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] "
                    placeholder="(Y.4) TC KİMLİK NO / VERGİ NO"
                    disabled
                  ></input>
                </td>
              </tr>
              <tr className="w-full flex max-sm:flex-col">
                <td className=" h-12 w-full flex justify-center items-center border-[1px] border-black">
                  <input
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] "
                    placeholder="(C.1.1) SOYADI / TİCARİ ÜNVANI"
                    disabled
                  ></input>
                </td>
              </tr>
              <tr className="w-full flex max-sm:flex-col">
                <td className=" h-12 w-full flex flex-col justify-center items-center border-[1px] border-black">
                  <label
                    htmlFor="debitUserId"
                    className="w-full h-full outline-none border-none text-[12px] "
                  >
                    {' '}
                    (C.1.2) ADI
                  </label>
                  <input
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] "
                    placeholder="(C.1.2) ADI"
                    value={vehicleFormData.debitUserId}
                    onChange={(e) =>
                      setVehicleFormData({
                        ...vehicleFormData,
                        debitUserId: e.target.value,
                      })
                    }
                    disabled
                  ></input>
                </td>
              </tr>
              <tr className="w-full flex max-sm:flex-col">
                <td className=" h-24 w-full flex justify-center items-center border-[1px] border-black">
                  <textarea
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] resize-none"
                    placeholder="(C.1.2) ADRESİ"
                    disabled
                  ></textarea>
                </td>
              </tr>
              <tr className="w-full flex max-sm:flex-col">
                <td className="  w-1/2 max-sm:w-full flex justify-center items-center border-[1px] border-black">
                  <textarea
                    type="text"
                    className="w-full h-full outline-none border-none p-2 text-[12px] resize-none"
                    placeholder="(Z.1) ARAÇ ÜZERİNDE HAK VE MENFAATİ BULUNANLAR"
                    disabled
                  ></textarea>
                </td>
                <td className=" h-48 w-1/2 max-sm:w-full flex flex-col justify-center items-center border-[1px] border-black">
                  <tr className="w-full flex max-sm:flex-col">
                    <td className=" h-12 w-full flex justify-center items-center border-[1px] border-black">
                      <input
                        type="text"
                        className="w-full h-full outline-none border-none p-2 text-[12px] "
                        placeholder="(Z.3.1) NOTER SATIŞ TARİHİ"
                        disabled
                      ></input>
                    </td>
                  </tr>
                  <tr className="w-full flex max-sm:flex-col">
                    <td className=" h-12 w-full flex justify-center items-center border-[1px] border-black">
                      <input
                        type="text"
                        className="w-full h-full outline-none border-none p-2 text-[12px] "
                        placeholder="(Z.3.2) NOTER SATIŞ NO"
                        disabled
                      ></input>
                    </td>
                  </tr>
                  <tr className="w-full flex max-sm:flex-col">
                    <td className=" h-24 w-full flex justify-center items-center border-[1px] border-black">
                      <textarea
                        type="text"
                        className="w-full h-full outline-none border-none p-2 text-[12px] resize-none"
                        placeholder="(Z.3.3) NOTERİN ADI"
                        disabled
                      ></textarea>
                    </td>
                  </tr>
                </td>
              </tr>
              <tr className="w-full flex h-60">
                <td className="   w-1/2 max-sm:w-full flex flex-col justify-center items-center border-[1px] border-black">
                  <tr className="w-full flex max-sm:flex-col">
                    <td className=" h-36 w-full flex flex-col justify-center items-center border-[1px] border-black">
                      <label
                        htmlFor="description"
                        className="w-full  outline-none border-none text-[12px] "
                      >
                        {' '}
                        (Z.2) DİĞER BİLGİLER
                      </label>
                      <textarea
                        type="text"
                        className="w-full h-full outline-none border-none p-2 text-[12px] resize-none"
                        placeholder="(Z.2) DİĞER BİLGİLER"
                        value={vehicleFormData.description}
                        onChange={(e) =>
                          setVehicleFormData({
                            ...vehicleFormData,
                            description: e.target.value,
                          })
                        }
                        disabled
                      ></textarea>
                    </td>
                  </tr>
                  <tr className="w-full flex max-sm:flex-col">
                    <td className=" h-24 w-full flex justify-center items-center border-[1px] border-black">
                      <textarea
                        type="text"
                        className="w-full h-full outline-none border-none p-2 text-[12px] resize-none"
                        placeholder="(Z.4) ONAYLAYAN SİCİL-İMZA"
                        disabled
                      ></textarea>
                    </td>
                  </tr>
                </td>
                <td className=" w-1/2 max-sm:w-full flex flex-col justify-center items-center border-[1px] border-black">
                  <tr className="w-full flex max-sm:flex-col">
                    <td className=" h-48 w-full flex flex-col justify-start items-start border-[1px] border-black">
                      <label
                        htmlFor="document"
                        className="w-full outline-none border-none text-[12px] "
                      >
                        {' '}
                        (~) BELGE
                      </label>
                      <textarea
                        type="text"
                        className="w-full h-full outline-none border-none p-2 text-[12px] resize-none"
                        placeholder="(~) BELGE"
                        value={vehicleFormData.document}
                        onChange={(e) =>
                          setVehicleFormData({
                            ...vehicleFormData,
                            document: e.target.value,
                          })
                        }
                        disabled
                      ></textarea>
                    </td>
                  </tr>
                  <tr className="w-full flex max-sm:flex-col">
                    <td className=" h-12 w-full flex flex-col justify-center items-center border-[1px] border-black">
                      <label
                        htmlFor="documentSerialNo"
                        className="w-full outline-none border-none text-[12px] "
                      >
                        {' '}
                        (~) BELGE SERİ NO
                      </label>
                      <input
                        type="text"
                        className="w-full h-full outline-none border-none p-2 text-[12px] "
                        placeholder="BELGE SERİ NO"
                        value={vehicleFormData.documentSerialNo}
                        onChange={(e) =>
                          setVehicleFormData({
                            ...vehicleFormData,
                            documentSerialNo: e.target.value,
                          })
                        }
                        disabled
                      ></input>
                    </td>
                  </tr>
                </td>
              </tr>
            </table>
          </div>

        </div>
      </Modal>
    </div>
  );
}

export default VehicleLicense;
