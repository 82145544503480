import { Edit, Visibility, Forward30 } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import moment from 'moment';
import { Link } from 'react-router-dom';

function calculateDateDifference(startDateStr) {
  // Başlangıç tarihini moment objesine dönüştür
  var startDate = moment(startDateStr, 'DD.MM.YYYY');
  // console.log(startDate);
  // Bugünkü tarihi al
  var currentDate = moment();

  // İki tarih arasındaki farkı hesapla
  var difference = moment.duration(startDate.diff(currentDate));
  var daysDifference = difference.asDays();

  // Sonucu döndür
  return Math.floor(daysDifference);
}

export const columns = [
  {
    field: 'plate',
    headerName: 'Araç Plaka',
    width: 100,
  },
  { field: 'brand', headerName: 'Marka', width: 130 },
  { field: 'model', headerName: 'Model', width: 130 },
  { field: 'modelYear', headerName: 'Yıl', width: 70 },
  { field: 'availableFirm', headerName: 'Firma', width: 130 },
  { field: 'documentSerialNo', headerName: 'Seri No', width: 130 },
  { field: 'type', headerName: 'Tipi', width: 130 },
  {
    field: 'activeJobs',
    headerName: 'Görev Yeri',
    width: 130,
    renderCell: (e) => {
      const data = e.row.activeJobs;
      let activeJob = [];

      try {
        activeJob = data ? JSON.parse(data) : [];
      } catch (error) {
        console.error('Invalid JSON format:', error);
        activeJob = [];
      }

      return (
        <div
          className={`flex-col w-full px-2 font-[Inter] rounded-md p-2 flex items-center justify-center`}
        >
          {activeJob?.length > 0 ? (
            activeJob.map((job) => (
              <span key={job.id} className="px-2">
                {job.label}
              </span>
            ))
          ) : (
            <span className="text-gray-500">Yok</span>
          )}
        </div>
      );
    },
  },

  {
    field: 'inspectionEndDate',
    sortParameter: 'i.validityEndsAt',
    typeId: '0F8AN274Q84FH',
    headerName: 'Muayene',
    width: 130,
    renderCell: (e) => {
      const data = e.row.inspections.filter(
        (inspection) => inspection.typeId === '0F8AN274Q84FH',
      );

      const inspection = data[0];
      const date = moment(inspection?.validityEndsAt).format('DD.MM.YYYY');

      var difference = calculateDateDifference(date);
      if (typeof difference !== 'number' || data.length === 0) {
        difference = 'YOK';
      }

      var bgColor =
        difference > 30
          ? 'bg-green-600'
          : difference > 0
            ? 'bg-yellow-500'
            : difference <= 0
              ? 'bg-red-600'
              : 'bg-gray-600';

      return (
        <div
          className={`${bgColor} flex-col text-white w-full text-xs px-2 font-[Inter] rounded-md p-2 flex items-center justify-center`}
        >
          <span className="text-[10px] px-2">
            {difference > 0
              ? difference + ' GÜN KALDI'
              : difference < 0
                ? Math.abs(difference) + ' GÜN GEÇTİ'
                : difference === 0
                  ? 'SON GÜN'
                  : 'YOK'}
          </span>
        </div>
      );
    },
  },
  {
    field: 'mendotoryTrafficEndDate',
    sortParameter: 'i.validityEndsAt',
    typeId: '0F8AMKSAV84FG',
    headerName: 'Z. Trafik Poliçesi',
    width: 130,
    renderCell: (e) => {
      const data = e.row.insurances.filter(
        (insurances) => insurances.typeId === '0F8AMKSAV84FG',
      );
      const insurances = data[0];
      const date = moment(insurances?.validityEndsAt).format('DD.MM.YYYY');
      var difference = calculateDateDifference(date);
      if (typeof difference !== 'number' || data.length === 0) {
        difference = 'YOK';
      }
      var bgColor =
        difference > 30
          ? 'bg-green-600'
          : difference > 0
            ? 'bg-yellow-500'
            : difference <= 0
              ? 'bg-red-600'
              : 'bg-gray-600';

      return (
        <div
          className={`${bgColor} text-white w-full text-[10px]px-2 font-[Inter] rounded-md p-2 flex items-center justify-center`}
        >
          <span className="text-[10px] px-2">
            {difference > 0
              ? difference + ' GÜN KALDI'
              : difference < 0
                ? Math.abs(difference) + ' GÜN GEÇTİ'
                : difference === 0
                  ? 'SON GÜN'
                  : 'YOK'}
          </span>
        </div>
      );
    },
  },

  {
    field: 'actions',
    headerName: '',
    width: 120,
    align: 'center',
    sortable: false,
    notFilter: true,
    renderCell: (e) => (
      <>
        <Link to={`/fleet/vehicles/edit/${e.row.id}`}>
          <IconButton size="small">
            <Edit />
          </IconButton>
        </Link>
        <Link to={`/fleet/vehicles/vehicle-license/${e.row.id}`}>
          <IconButton size="small">
            <Visibility />
          </IconButton>
        </Link>
      </>
    ),
  },
];
