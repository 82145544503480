import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Pagination, CircularProgress, Button } from '@mui/material';
import * as MATERIEL_ICONS from '@mui/icons-material/';
import TDataGrid from '../../../components/Datagrid/Datagrid';
import { Pageable } from '../../../ui-sdk/core/service/pageable';
import { Sort } from '../../../ui-sdk/core/service/sort';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CustomInput from '../../../components/CustomInput';
import { fields } from './constants/create_vehicle_fields';
import { insurance_fields } from './constants/create_insurance_fields';
import { inspection_fields } from './constants/create_inspection_fields';
import TStepper from './components/Stepper';
import service from './service';
import moment from 'moment';
import { columns } from './constants/table_columns_data';
import { Autocomplete, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { toast } from 'react-toastify';

const Users = () => {
  const location = useLocation();
  const [filterData, setFilterData] = useState({ param: '' });
  const [sortModel, setSortModel] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [count, setCount] = useState(0);
  const [helperText, setHelperText] = useState('');
  const [fieldsEmpty, setFieldsEmpty] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [rowSize, setRowSize] = useState(50);
  const [companies, setCompanies] = useState([]);
  const [addCompanyModalOpen, setAddCompanyModalOpen] = useState(false);
  const [addCompanyFormData, setAddCompanyFormData] = useState({
    name: '',
  });

  const [vehicleFormData, setVehicleFormData] = React.useState({
    plate: '',
    debitUserId: '',
    engineNumber: '',
    chasis: '',
    brand: '',
    model: '',
    modelYear: '',
    type: '',
    load: '',
    fuelFactoryPerformance: '',
    coolant: '',
    description: '',
    status: '',
    availableFirm: '',
    documentSerialNo: '',
    document: '',
    purposeOfUsage: '',
    vehicleClass: '',
    queueNo: '',
  });

  const [statusType, setStatusType] = React.useState({
    statusTypeId: '',
  });

  const [seatInsuranceFormData, setSeatInsuranceFormData] = React.useState({
    insuranceTypeId: '0F8AJBREQ84FD',
    insuranceFirm: '',
    // insuranceValidityMileage: null,
    insurancePolicyNumber: '',
    // insuranceValidityBeginsAt: null,
    insuranceValidityEndsAt: null,
  });

  const [kaskoInsuranceFormData, setKaskoInsuranceFormData] = React.useState({
    insuranceTypeId: '0F8AMHDTV84FF',
    insuranceFirm: '',
    // insuranceValidityMileage: null,
    insurancePolicyNumber: '',
    // insuranceValidityBeginsAt: null,
    insuranceValidityEndsAt: null,
  });
  const [trafficInsuranceFormData, setTrafficInsuranceFormData] =
    React.useState({
      insuranceTypeId: '0F8AMKSAV84FG',
      insuranceFirm: '',
      // insuranceValidityMileage: null,
      insurancePolicyNumber: '',
      // insuranceValidityBeginsAt: null,
      insuranceValidityEndsAt: null,
    });

  const [vehicleInspectionFormData, setVehicleInspectionFormData] =
    React.useState({
      inspectionTypeId: '0F8AN274Q84FH',
      inspectionFirm: '',
      inspectionValidityMileage: null,
      inspectionPolicyNumber: '',
      // inspectionValidityBeginsAt: null,
      inspectionValidityEndsAt: null,
    });
  const [exhaustInspectionFormData, setExhaustInspectionFormData] =
    React.useState({
      inspectionTypeId: '0F8AN4NA384FJ',
      inspectionFirm: '',
      inspectionValidityMileage: null,
      inspectionPolicyNumber: '',
      // inspectionValidityBeginsAt: null,
      inspectionValidityEndsAt: null,
    });
  const [activeJobs, setActiveJobs] = React.useState('');
  const [formData, setFormData] = React.useState({
    ...vehicleFormData,
    activeJobs: JSON.stringify(activeJobs),
    insurances: {
      ...seatInsuranceFormData,
      ...kaskoInsuranceFormData,
      ...trafficInsuranceFormData,
    },
    inspections: {
      ...vehicleInspectionFormData,
      ...exhaustInspectionFormData,
    },
  });

  async function getAllCompany() {
    setIsLoading(true);
    const { field, sort } =
      sortModel.length > 0
        ? sortModel[0]
        : {
            field: 'createdAt',
            sort: 'desc',
          };

    const params = {};

    const pageable = new Pageable(
      0,
      9999999999999,
      new Sort('createdAt', sort),
    );

    try {
      const result = await service.getAllCompany(params, pageable);
      const data = result?.data;

      // "name" alanını "label" olarak değiştiriyoruz
      const transformedData = data.map((item) => ({
        id: item.id,
        label: item.name,
      }));

      setIsLoading(false);
      setCompanies(transformedData);
    } catch (error) {
      console.error('Error fetching companies: ', error);
      setIsLoading(false);
    }
  }

  const paginationChange = (_, e_page) => setPage(e_page - 1);
  const rowSizeChange = (_, e_rowSize) => {
    setRowSize(e_rowSize);
    setPage(0);
  };
  const XLSX = require('xlsx');

  async function checkChassisNumber() {
    const result = await service.checkChassisNumber({
      chassisNumber: vehicleFormData.chasis,
    });
    result?.data?.Results?.map((item) => {
      if (item?.Variable === 'Error Code') {
        if (item?.Value?.includes('6')) {
          setHelperText('Eksik / Hatalı Şasi Numarası, kontrol ediniz.');
          return true;
        } else {
          setHelperText('');
          return false;
        }
      }
    });
  }

  function isFieldsEmpty() {
    if (
      vehicleFormData.chasis === '' ||
      vehicleFormData.plate === '' ||
      vehicleFormData.documentSerialNo === '' ||
      vehicleFormData.engineNumber === ''
    ) {
      setFieldsEmpty(true);
      return true;
    } else {
      setFieldsEmpty(false);
      return false;
    }
  }

  async function getData() {
    setIsLoading(true);
    const { field, sort } =
      sortModel.length > 0
        ? sortModel[0]
        : {
            field: 'createdAt',
            sort: 'desc',
          };
    const handleField = (field) => {
      const sortColumn = columns.filter((column) => column.field === field)[0];
      const res = sortColumn?.sortParameter
        ? sortColumn?.sortParameter
        : sortColumn?.field || 'createdAt';
      return res;
    };
    const handleTypeId = (field) => {
      const typeIdColumn = columns.find((column) => column.field === field);
      const typeId = typeIdColumn ? { typeId: typeIdColumn.typeId } : null;
      return { ...typeId };
    };
    const params = {
      ...handleTypeId(field),
      statusTypeId: '0FFTEF9ZQT6M7',
      ...(filterData.param ? { param: filterData.param } : {}),
    };

    const pageable = new Pageable(
      page,
      rowSize,
      new Sort(handleField(field) || 'createdAt', sort),
    );
    const result = await service.getAllVehiclesWithDates(params, pageable);
    const data = result?.data;
    const content = data?.content;
    setCount(data?.totalPages);
    setVehicles(content);
    setIsLoading(false);
  }
  /*
  async function getData() {
    setIsLoading(true);
    const handleField = (field) => {
      const sortColumn = columns.filter((column) => column.field === field)[0];
      const res = sortColumn?.sortParameter
        ? sortColumn?.sortParameter
        : sortColumn?.field || 'createdAt';
      return res;
    };

    const handleTypeId = (field) => {
      const typeIdColumn = columns.find((column) => column.field === field);
      const typeId = typeIdColumn ? { typeId: typeIdColumn.typeId } : null;
      return { ...typeId };
    };

    const { field, sort } =
      sortModel.length > 0
        ? sortModel[0]
        : {
            field: 'createdAt',
            sort: 'desc',
          };

    const params = {
      ...handleTypeId(field),
      statusTypeId: '0FFTEF9ZQT6M7',
    };

    const pageable = new Pageable(
      page,
      rowSize,
      new Sort(handleField(field) || 'createdAt', sort),
    );
    const result = await service.getAllVehiclesWithDates(params, pageable);
    const data = result?.data;
    setCount(data?.totalPages);
    setVehicles(data?.content);
    setIsLoading(false);
  }

  async function searchData() {
    setIsLoading(true);
    const pageable = new Pageable(page, rowSize, null);
    const result = await service.getAllVehicleWithParam(
      { param: filterData.param },
      pageable,
    );
    const data = result?.data;
    setCount(data?.totalPages);
    setVehicles(data?.content);
    setIsLoading(false);
  }
*/
  const handleExportExcelData = async () => {
    //SIRALAMAYI İNDİRMEK İÇİN
    const headers = [
      'Plaka',
      'Firma',
      'Marka',
      'Model',
      'Tipi',
      'Şasi',
      'Motor No',
      'Muayene Bitiş Tarihi',
      'Egzoz Muayene Bitiş Tarihi',
      'Trafik Sigorta Bitiş Tarihi',
      'Kasko Sigorta Bitiş Tarihi',
      'Koltuk Sigorta Bitiş Tarihi',
    ];

    const data = vehicles.map((vehicle) => {
      const inspection = vehicle.inspections.filter(
        (inspection) => inspection.typeId === '0F8AN274Q84FH',
      );
      const inspectionEndDate = inspection[0]?.validityEndsAt;
      const exhaustInspection = vehicle.inspections.filter(
        (inspection) => inspection.typeId === '0F8AN4NA384FJ',
      );
      const exhaustInspectionEndDate = exhaustInspection[0]?.validityEndsAt;
      const trafficInsurance = vehicle.insurances.filter(
        (insurance) => insurance.typeId === '0F8AMKSAV84FG',
      );
      const trafficInsuranceEndDate = trafficInsurance[0]?.validityEndsAt;
      const kaskoInsurance = vehicle.insurances.filter(
        (insurance) => insurance.typeId === '0F8AMHDTV84FF',
      );
      const kaskoInsuranceEndDate = kaskoInsurance[0]?.validityEndsAt;
      const seatInsurance = vehicle.insurances.filter(
        (insurance) => insurance.typeId === '0F8AJBREQ84FD',
      );
      const seatInsuranceEndDate = seatInsurance[0]?.validityEndsAt;
      return [
        vehicle.plate,
        vehicle.availableFirm,
        vehicle.brand,
        vehicle.model,
        vehicle.type,
        vehicle.chasis,
        vehicle.engineNumber,
        inspectionEndDate ? moment(inspectionEndDate).format('DD.MM.YYYY') : '',
        exhaustInspectionEndDate
          ? moment(exhaustInspectionEndDate).format('DD.MM.YYYY')
          : '',
        trafficInsuranceEndDate
          ? moment(trafficInsuranceEndDate).format('DD.MM.YYYY')
          : '',
        kaskoInsuranceEndDate
          ? moment(kaskoInsuranceEndDate).format('DD.MM.YYYY')
          : '',
        seatInsuranceEndDate
          ? moment(seatInsuranceEndDate).format('DD.MM.YYYY')
          : '',
      ];
    });

    const workbook = XLSX.utils.book_new();
    const worksheetData = [headers, ...data]; // Headers and data combined
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'Araçlar.xlsx');
  };

  const handleFilterInput = (newValue) => {
    setPage(0); // Reset page to 0
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      param: newValue,
    }));
  };

  const filter = async () => {
    searchData();
  };

  useEffect(() => {
    getData();
  }, [filterData, page, rowSize, sortModel, location, modalOpen]);

  useEffect(() => {
    getAllCompany();
  }, []);

  useEffect(() => {
    checkChassisNumber();
  }, [vehicleFormData.chasis, vehicleFormData.modelYear]);

  useEffect(() => {
    isFieldsEmpty();
  }, [
    vehicleFormData.chasis,
    vehicleFormData.plate,
    vehicleFormData.engineNumber,
    vehicleFormData.documentSerialNo,
  ]);

  const checkInsuranceFields = (data) => {
    if (
      data.insuranceFirm != '' ||
      data.insurancePolicyNumber != '' ||
      data.insuranceValidityBeginsAt != null ||
      data.insuranceValidityEndsAt != null
    ) {
      return data;
    }
  };

  const checkInspectionFields = (data) => {
    if (
      data.inspectionFirm != '' ||
      data.inspectionPolicyNumber != '' ||
      data.inspectionValidityMileage != null ||
      data.inspectionValidityBeginsAt != null ||
      data.inspectionValidityEndsAt != null
    ) {
      return data;
    }
  };
  const handleChangeActiveJobs = (value) => {
    setActiveJobs(value);
  };
  const handleFieldChange = (key, value) => {
    if (key === 'activeJobs') {
      handleChangeActiveJobs(value);
    } else if (key === 'statusType') {
      setStatusType({
        statusTypeId: value,
      });
      setVehicleFormData({
        ...vehicleFormData,
        statusType: {
          statusTypeId: value,
        },
      });
    } else {
      setVehicleFormData({
        ...vehicleFormData,
        [key]: value,
      });
    }
  };

  const setData = () => {
    const data = {
      ...vehicleFormData,
      statusType: statusType,
      activeJobs: JSON.stringify(activeJobs),
      insurances: [
        checkInsuranceFields(seatInsuranceFormData)
          ? checkInsuranceFields(seatInsuranceFormData)
          : null,
        checkInsuranceFields(kaskoInsuranceFormData)
          ? checkInsuranceFields(kaskoInsuranceFormData)
          : null,
        checkInsuranceFields(trafficInsuranceFormData)
          ? checkInsuranceFields(trafficInsuranceFormData)
          : null,
      ],
      inspections: [
        checkInspectionFields(vehicleInspectionFormData)
          ? checkInspectionFields(vehicleInspectionFormData)
          : null,
        checkInspectionFields(exhaustInspectionFormData)
          ? checkInspectionFields(exhaustInspectionFormData)
          : null,
      ],
    };

    setFormData({
      ...vehicleFormData,
      statusType: statusType,
      activeJobs: JSON.stringify(activeJobs),
      insurances: [
        checkInsuranceFields(seatInsuranceFormData)
          ? checkInsuranceFields(seatInsuranceFormData)
          : null,
        checkInsuranceFields(kaskoInsuranceFormData)
          ? checkInsuranceFields(kaskoInsuranceFormData)
          : null,
        checkInsuranceFields(trafficInsuranceFormData)
          ? checkInsuranceFields(trafficInsuranceFormData)
          : null,
      ],
      inspections: [
        checkInspectionFields(vehicleInspectionFormData)
          ? checkInspectionFields(vehicleInspectionFormData)
          : null,
        checkInspectionFields(exhaustInspectionFormData)
          ? checkInspectionFields(exhaustInspectionFormData)
          : null,
      ],
    });

    return data;
  };

  const handleAddCompany = async () => {
    const data = {
      name: addCompanyFormData.name,
      description: addCompanyFormData.name,
    };
    const result = await service.saveCompany(data);
    if (result?.status === 200) {
      toast.success('Firma başarıyla eklendi');
      setAddCompanyModalOpen(false);
      getAllCompany();
    }
  };

  return (
    <>
      <div className="p-3 m-3 flex flex-col justify-center items-center">
        <div className="w-full 2xl:w-max flex flex-col items-center p-3 m-3">
          <div className="py-3 w-full mx-3 my-2 flex flex-wrap justify-between max-lg:justify-center gap-3 items-center">
            <span className="text-lg sm:text-2xl max-sm:text-center">
              Güncel Araçlar
            </span>
            <div className="mx-2 my-5 max-sm:flex-wrap max-sm:justify-center  flex gap-5 justify-between items-center">
              <TextField
                size="small"
                placeholder="Arama"
                onChange={(e) =>
                  handleFilterInput(e.target.value.toLocaleUpperCase())
                }
                value={filterData.query}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MATERIEL_ICONS.Search />
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: '8px',
                  },
                }}
              />

              <div className="flex flex-wrap gap-2 justify-center">
                <Button
                  variant="contained"
                  startIcon={<MATERIEL_ICONS.Add />}
                  color="primary"
                  onClick={() => {
                    setAddCompanyModalOpen(true);
                  }}
                  className="mx-2"
                >
                  GÖREV YERİ EKLE
                </Button>
                <Button
                  variant="contained"
                  startIcon={<MATERIEL_ICONS.Add />}
                  color="primary"
                  onClick={() => {
                    setModalOpen(true);
                  }}
                  className="mx-2"
                >
                  YENİ ARAÇ EKLE
                </Button>
                <Button
                  variant="contained"
                  startIcon={<MATERIEL_ICONS.SaveAsOutlined />}
                  color="primary"
                  onClick={() => {
                    handleExportExcelData(true);
                  }}
                  className="mx-2"
                >
                  EXCELE AKTAR
                </Button>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className="flex justify-center items-center w-[1200px]">
              <CircularProgress className="users-list-circular-progress" />
            </div>
          ) : (
            <div className="flex w-full justify-center items-center">
              <div className="w-full bg-white rounded-xl shadow-xl p-3 m-3">
                <TDataGrid
                  rows={vehicles}
                  columns={columns}
                  sortModel={sortModel}
                  setSortModel={setSortModel}
                />
                <div className="flex flex-wrap justify-center gap-10 items-center p-3 m-3">
                  <Pagination
                    page={page + 1}
                    onChange={paginationChange}
                    count={count}
                    color="primary"
                    size="small"
                  />
                  <Autocomplete
                    id="combo-box-demo"
                    options={[5, 10, 20, 50, 100]}
                    getOptionLabel={(option) => option.toString()}
                    style={{ width: 150 }}
                    onChange={(e, value) => rowSizeChange(e, value)}
                    value={rowSize}
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Satır sayısı" />
                    )}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <Modal
          open={addCompanyModalOpen}
          onClose={() => setAddCompanyModalOpen(false)}
        >
          <div className="flex flex-col outline-none justify-center items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-auto max-2xl:w-full max-2xl:h-full bg-white p-10 w-[1280px] overflow-y-auto">
            {/* Modal Close Button */}
            <div className="w-full flex justify-end items-center">
              <IconButton onClick={() => setAddCompanyModalOpen(false)}>
                <Close />
              </IconButton>
            </div>
            {/* Modal Title */}
            <div className="w-full flex justify-center items-center">
              <Typography variant="h5" color="initial" gutterBottom>
                Görev Yeri Ekle
              </Typography>
            </div>
            {/* Form Inputs */}
            <div className="flex flex-row flex-wrap justify-center items-center gap-2 w-full">
              <div className="flex flex-wrap min-h-20">
                {/* Firma Adı Input */}
                <CustomInput
                  type="text"
                  label="Görev Yeri"
                  value={addCompanyFormData.name}
                  error={false}
                  errorText={''}
                  fullWidth={true}
                  sx={{ width: '284px' }}
                  size={'medium'}
                  onChange={(key, value) => {
                    setAddCompanyFormData({ name: value });
                  }}
                />
              </div>
            </div>
            {/* Save Button */}
            <div className="flex justify-center items-center w-full mt-2">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleAddCompany();
                }}
              >
                Kaydet
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
        >
          <div className="flex flex-col outline-none justify-center items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-auto max-2xl:w-full max-2xl:h-full bg-white p-10 w-[1280px] overflow-y-auto">
            <div className="w-full flex justify-end items-center">
              <IconButton
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                <Close />
              </IconButton>
            </div>
            <TStepper
              steps={[
                'Araç Bilgileri',
                'Sigorta Bilgileri',
                'Muayene Bilgileri',
              ]}
              optionalSteps={[1, 2]}
              formData={formData}
              setData={setData}
              helperText={helperText}
              fieldsEmpty={fieldsEmpty}
              setModalOpen={setModalOpen}
            >
              {/* Araç Bilgileri */}
              <>
                <div className="mt-5">
                  <div>
                    <Typography
                      variant="h5"
                      color="initial"
                      gutterBottom
                      textAlign={'center'}
                    >
                      Yeni Araç Ekle
                    </Typography>
                  </div>

                  <div className="flex flex-row flex-wrap justify-start items-center gap-2 w-full ">
                    {fields(companies).map((field, index) => {
                      return (
                        <div className=" flex flex-wrap min-h-20" key={index}>
                          <CustomInput
                            key={index}
                            type={field.type}
                            label={field.placeHolder}
                            multiple={field.key === 'activeJobs' ? true : false}
                            value={
                              field.key === 'activeJobs'
                                ? activeJobs
                                : field.key === 'statusType'
                                  ? statusType.statusTypeId
                                  : vehicleFormData[field.key]
                            }
                            error={
                              (field.key === 'chasis' && helperText !== '') ||
                              ((field.key === 'engineNumber' ||
                                field.key === 'documentSerialNo' ||
                                field.key === 'plate' ||
                                field.key === 'chasis') &&
                                vehicleFormData[field.key] === '')
                                ? true
                                : false
                            }
                            errorText={
                              (field.key === 'engineNumber' ||
                                field.key === 'documentSerialNo' ||
                                field.key === 'plate' ||
                                field.key === 'chasis') &&
                              vehicleFormData[field.key] === ''
                                ? 'Bu alan boş bırakılamaz'
                                : field.key === 'chasis' && helperText
                            }
                            fullWidth={true}
                            sx={{ width: '284px' }}
                            items={field.items}
                            options={field.options}
                            size={'medium'}
                            onChange={(key, value) => {
                              handleFieldChange(field.key, value);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
              {/* Sigorta Bilgileri */}
              <>
                <div className="mt-5 ">
                  <div>
                    <Typography
                      variant="h5"
                      color="initial"
                      gutterBottom
                      textAlign={'center'}
                    >
                      Sigorta Bilgileri
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="h6"
                      color="initial"
                      gutterBottom
                      textAlign={'left'}
                    >
                      Trafik Sigortası
                    </Typography>
                  </div>
                  <div className="flex flex-row flex-wrap  items-center gap-3 w-full mb-3">
                    {insurance_fields().map((field, index) => {
                      return (
                        <div className=" flex  flex-wrap h-20" key={index}>
                          <CustomInput
                            key={index}
                            type={field.type}
                            label={field.placeHolder}
                            value={trafficInsuranceFormData[field.key]}
                            fullWidth={false}
                            size={'medium'}
                            sx={{ width: '284px' }}
                            onChange={(key, value) => {
                              setTrafficInsuranceFormData({
                                ...trafficInsuranceFormData,
                                [field.key]: value,
                              });
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    <Typography
                      variant="h6"
                      color="initial"
                      gutterBottom
                      textAlign={'left'}
                    >
                      Kasko
                    </Typography>
                  </div>
                  <div className="flex flex-row flex-wrap  items-center gap-3 w-full mb-3 ">
                    {insurance_fields().map((field, index) => {
                      return (
                        <div className=" flex  flex-wrap h-20" key={index}>
                          <CustomInput
                            key={index}
                            type={field.type}
                            label={field.placeHolder}
                            value={kaskoInsuranceFormData[field.key]}
                            fullWidth={false}
                            size={'medium'}
                            sx={{ width: '284px' }}
                            onChange={(key, value) => {
                              setKaskoInsuranceFormData({
                                ...kaskoInsuranceFormData,
                                [field.key]: value,
                              });
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    <Typography
                      variant="h6"
                      color="initial"
                      gutterBottom
                      textAlign={'left'}
                    >
                      Koltuk Sigortası
                    </Typography>
                  </div>
                  <div className="flex flex-row flex-wrap  items-center gap-1 w-full mb-3">
                    {insurance_fields().map((field, index) => {
                      return (
                        <div className=" flex  flex-wrap h-20" key={index}>
                          <CustomInput
                            key={index}
                            type={field.type}
                            label={field.placeHolder}
                            value={seatInsuranceFormData[field.key]}
                            fullWidth={false}
                            size={'medium'}
                            sx={{ width: '284px' }}
                            onChange={(key, value) => {
                              setSeatInsuranceFormData({
                                ...seatInsuranceFormData,
                                [field.key]: value,
                              });
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
              {/* Muayene Bilgileri */}
              <>
                <div className="mt-5">
                  <Typography
                    variant="h5"
                    color="initial"
                    gutterBottom
                    textAlign={'center'}
                  >
                    Muayene Bilgileri
                  </Typography>
                </div>
                <div>
                  <div>
                    <Typography
                      variant="h6"
                      color="initial"
                      gutterBottom
                      textAlign={'left'}
                    >
                      Zorunlu Araç Muayenesi
                    </Typography>
                  </div>
                  <div className="flex flex-row flex-wrap  items-center gap-3 w-full mb-3">
                    {inspection_fields().map((field, index) => {
                      return (
                        <div className=" flex  flex-wrap h-20" key={index}>
                          <CustomInput
                            key={index}
                            type={field.type}
                            label={field.placeHolder}
                            value={vehicleInspectionFormData[field.key]}
                            fullWidth={false}
                            size={'medium'}
                            sx={{ width: '284px' }}
                            onChange={(key, value) => {
                              setVehicleInspectionFormData({
                                ...vehicleInspectionFormData,
                                [field.key]: value,
                              });
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    <Typography
                      variant="h6"
                      color="initial"
                      gutterBottom
                      textAlign={'left'}
                    >
                      Zorunlu Araç Egzoz Muayenesi
                    </Typography>
                  </div>
                  <div className="flex flex-row flex-wrap  items-center gap-3 w-full mb-3">
                    {inspection_fields().map((field, index) => {
                      return (
                        <div className=" flex  flex-wrap h-20" key={index}>
                          <CustomInput
                            key={index}
                            type={field.type}
                            label={field.placeHolder}
                            value={exhaustInspectionFormData[field.key]}
                            fullWidth={false}
                            sx={{ width: '284px' }}
                            size={'medium'}
                            onChange={(key, value) => {
                              setExhaustInspectionFormData({
                                ...exhaustInspectionFormData,
                                [field.key]: value,
                              });
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            </TStepper>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Users;
