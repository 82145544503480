import React, { useState, useEffect } from 'react';

import FullLayout from '../../../layouts/fullLayout';
import * as MATERIEL_ICONS from '@mui/icons-material';
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import service from './service';
import moment from 'moment';
import 'moment/locale/tr';
import { Avatar } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRef } from 'react';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import CircularProgress from '@mui/material/CircularProgress';

moment.locale("tr");

function Messagges() {

    const hash = useLocation();
    const navigate = useNavigate();
    const [ticketList, setTicketList] = useState([]);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [chat, setChat] = useState([]);
    const [chatMessage, setChatMessage] = useState('');
    const [chatMessages, setChatMessages] = useState([]);
    const [seen, setSeen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const containerRef = useRef(null);

    const scrollToBottom = () => {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
    };

    const getChatList = async () => {
        setIsLoading(true);
        const result = await service.getChatListByUserId({ userId: "0GT82VJC4EDT5" });
        setTicketList(result.data);
        setIsLoading(false);
    }

    const setAsRead = async (ticketId) => {
        await service.setAsRead({ ticketId: hash.hash.slice(1), seen: false });
        getChatList();
    }

    const getChatbyTicketId = async () => {
        setSeen(false);
        const result = await service.getChatListByTicketId({ ticketId: hash.hash.slice(1) });
        setChat(result.data);
        setChatMessages(
            result.data.map((item) => {
                return {
                    message: item.message,
                    time: item.time,
                    user: item.userId.firstName + ' ' + item.userId.lastName,
                    userType: item.userId.userType
                }
            }
            ).reverse()
        );
    }

    const sendMessage = async () => {
        const result = await service.sendMessage({
            ticketId: { id: hash.hash.slice(1) },
            message: chatMessage,
            userId: { id: '0GT82VJC4EDT5' }
        }).then(() => {
            setChatMessage('');
            getChatbyTicketId();
            getChatList();
        });

    };

    useEffect(() => {
        getChatList();
    }, [])

    useEffect(() => {
        getChatbyTicketId();
    }, [selectedTicket, hash])

    useEffect(() => {
        scrollToBottom();
    }, [chatMessages])

    return (
        <FullLayout>
            <div className="w-full h-full flex justify-center items-center max-[1210px]:p-2">
                <div className="w-max h-full flex max-[1210px]:flex-wrap max-[1210px]:w-full gap-2">
                    <div className="w-[350px] h-[600px] relative max-[1210px]:w-full flex flex-col bg-white rounded-2xl shadow-xl sm:m-3 p-3">
                        <span className="text-lg  mb-2">Son Mesajlar</span>
                        <div className="absolute top-1 right-2 p-2  text-black rounded-full  z-50">
                            <IconButton
                                style={{ backgroundColor: '#F0F0F0' }}
                                size="small"
                                onClick={getChatList}
                            >
                                <MATERIEL_ICONS.Refresh />
                            </IconButton>
                        </div>
                        {
                            isLoading ? (
                                <div className='w-full h-full flex justify-center items-center'>
                                    <CircularProgress />
                                </div>
                            ) : (
                                <div className='w-full flex flex-col gap-2 p-2 overflow-y-auto'>
                                    {
                                        ticketList?.map((item, index) => (
                                            <div className={` 
                                    ${item.id === selectedTicket?.id ? 'bg-[#ECFEFF]' : 'bg-white'}
                                    min-h-28 rounded-md w-full cursor-pointer flex flex-col justify-between p-2 shadow-lg`
                                            }

                                                onClick={() => {
                                                    setSelectedTicket(item);
                                                    navigate(`/kayral/messages#${item.ticketId.id}`)
                                                }}
                                                key={index}
                                            >
                                                <div className="flex  justify-start items-center w-full gap-3">
                                                    <Avatar sx={{ width: 30, height: 30, }} />
                                                    <span className="text-[13px] font-bold">{item.ticketId.userId.firstName} {item.ticketId.userId.lastName}</span>
                                                </div>
                                                <div className="flex justify-between items-center text-[#777777]">
                                                    <div>
                                                        <span className="text-[12px] font-semibold mr-1">{
                                                            item.userId.id === '0GT82VJC4EDT5' ? 'Siz' : item.userId.firstName
                                                        }:</span>
                                                        <span className="text-[12px] font-semibold"> {
                                                            item.message.length > 30 ? item.message.slice(0, 30) + '...' : item.message
                                                        }</span>
                                                    </div>
                                                    {
                                                        (item.isRead)
                                                            ? null
                                                            : item.userId.id !== '0GT82VJC4EDT5' && (<IconButton
                                                                style={{ backgroundColor: '#03C949', color: "white", }}
                                                                size='small'
                                                            >
                                                                <MATERIEL_ICONS.Notifications
                                                                    style={{ fontSize: 15 }}
                                                                />
                                                            </IconButton>)
                                                    }

                                                </div>
                                                <div className="flex justify-end items-center text-[#777777]">
                                                    <span className="text-[10px] font-semibold mt-2">{
                                                        moment(item.time).fromNow()}</span>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            )
                        }
                    </div>
                    <div className="w-[800px] h-[600px] relative max-[1210px]:w-full flex flex-col justify-center bg-white rounded-2xl shadow-xl p-3 sm:m-3 ">
                        <span className="text-lg flex gap-2 mb-2">
                            {selectedTicket ? <Avatar sx={{ width: 30, height: 30 }} /> : null}
                            {selectedTicket ? `${selectedTicket.ticketId.userId.firstName} ${selectedTicket.ticketId.userId.lastName}` : 'Mesajlar'}
                        </span>
                        <div
                            className="w-full h-full flex flex-col border-[1px] border-[#C4C4C4] rounded-md overflow-y-auto bg-yerlemLogo bg-[length:700px_500px] bg-no-repeat bg-center"
                            ref={containerRef}
                        >
                            <div className="absolute top-[250px] left-[270px] -z-0 bg-logoIcon flex max-[1210px]:hidden justify-center items-center w-[232px] h-[65px] bg-no-repeat bg-center bg-cover mb-10">
                                {/* <img src={YerlemLogo} alt="Yerlem Logo" className='-z-50' /> */}
                            </div>
                            {chatMessages?.map((item, index) => (
                                <div
                                    className={`w-full flex flex-col z-50 justify-center ${item.userType === 'ADMIN' ? 'items-end' : 'items-start'} p-2`}
                                    key={index}
                                >
                                    <span className="font-bold ">
                                        {item.userType === 'ADMIN' ? 'Siz' : item.user}
                                    </span>
                                    <div
                                        className={`relative flex w-max max-w-full min-w-24 p-4 border-[1px]  rounded-b-2xl ${item.userType === 'ADMIN' ? 'bg-[#ECFEFF] shadow-lg rounded-tl-2xl' : 'bg-white shadow-lg rounded-tr-2xl'}`}
                                    >
                                        <span className="flex flex-col justify-center">{item.message}</span>
                                        <span className="text-[10px] absolute bottom-1 right-2">
                                            {moment(item.time).format('HH:mm')}
                                        </span>
                                    </div>
                                </div>
                            ))}
                            <div className="absolute top-1 right-2 p-2  text-black rounded-full  z-50">
                                <IconButton
                                    style={{ backgroundColor: '#F0F0F0' }}
                                    size="small"
                                    onClick={scrollToBottom}
                                >
                                    <KeyboardDoubleArrowDownIcon />
                                </IconButton>
                            </div>
                        </div>

                        <div className=' w-full flex justify-center items-center mt-2'>
                            <form
                                onSubmit={async (e) => {
                                    e.preventDefault();
                                    await sendMessage();
                                    setChatMessage('');
                                }}
                                className='w-full flex justify-center items-center'
                            >
                                <div className='w-full '>
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Mesaj"
                                        value={chatMessage}
                                        onChange={(e) => setChatMessage(e.target.value)}
                                        disabled={!selectedTicket || hash.hash === ''}
                                        fullWidth
                                        onFocus={() => { setAsRead() }}

                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        type='submit'
                                                        aria-label=''
                                                        disabled={!selectedTicket || hash.hash === ''}
                                                    >
                                                        <MATERIEL_ICONS.Send />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </FullLayout>
    );
}
export default Messagges;
