import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../../ui-sdk/core/utils/auth';
import checkPermission from '../../ui-sdk/core/utils/checkPermission';
import useAuthStore from '../../stores/auth-store';

import { ReactComponent as YerlemLogo } from '../../assets/images/yerlem-dark-logo.svg';
import logoSmall from '../../assets/images/logoSmall.svg';

import { useBoundStore } from '../../stores/index';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, useTheme, Drawer, Slide } from '@mui/material';
import { items } from './constants/header_items';

import './styles.css';
import TDrawer from '../Drawer';

import { AccountCircle } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SideMenu from './components/sideMenu';

const tabScrollButtonProps = {
  sx: {
    width: '2rem',
    height: '2rem',
    backgroundColor: 'white',
    borderRadius: '100%',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
};

const tabStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
};
const absoluteHeaderUrls = ['/atlas/dashboard'];

function Header({ children }) {
  const [firstValue, setFirstValue] = useState(0);
  const [secondValue, setSecondValue] = useState(0);
  const [thirdValue, setThirdValue] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [sideMenuOpen, setSideMenuOpen] = useState(false);

  const { setIsLoggedIn, setUser, setPermissions } = useAuthStore();
  const sidebarOpen = useBoundStore((state) => state.open);
  const toggleSidebarOpen = useBoundStore((state) => state.toggleOpen);
  const setAppConfig = useBoundStore((state) => state.setAppConfig);

  function conditionForTabs(firstTab, secondTab, thirdTab) {
    return items[firstValue]?.child[secondValue]?.child
      ? thirdTab // EFFECT THIRD LEVEL TABS
      : items[firstValue]?.child.length === 0
        ? firstTab // EFFECT FIRST LEVEL TABS
        : secondTab; // EFFECT SECOND LEVEL TABS
  }

  const firstTabStyle = {
    '&.Mui-selected': {
      color: 'black',
      fontWeight: 'bold',
      backgroundColor: '#f2f2f2',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
    },
    height: conditionForTabs('5rem', '36px', '36px'),
    minHeight: conditionForTabs('2rem', '2rem', '2rem'),
    marginTop: conditionForTabs('2rem', '1rem', '1rem'),
    paddingBottom: conditionForTabs('2rem', '1rem', '1rem'),
    fontSize: '13px',
    textTransform: 'none',
    fontWeight: 'normal', // Apply bold font for active tab
    borderTopLeftRadius: '0', // Apply rounded top-left corner for active tab
    borderTopRightRadius: '0', // Apply rounded top-right corner for active tab
    backgroundColor: 'transparent',
  };

  const secondTabStyle = {
    '&.Mui-selected': {
      color: 'black',
      fontWeight: 'bold',
      backgroundColor: '#ffffff',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
    },
    height: conditionForTabs('5rem', '36px', '36px'),
    minHeight: conditionForTabs('2rem', '2rem', '2rem'),
    marginTop: conditionForTabs('2rem', '1rem', '1rem'),
    paddingBottom: conditionForTabs('2rem', '1rem', '1rem'),
    fontSize: '13px',
    textTransform: 'none',
    fontWeight: 'normal', // Apply bold font for active tab
    borderTopLeftRadius: '0', // Apply rounded top-left corner for active tab
    borderTopRightRadius: '0', // Apply rounded top-right corner for active tab
    backgroundColor: 'transparent',
  };

  const thirdTabStyle = {
    '&.Mui-selected': {
      color: 'white',
      backgroundImage:
        'linear-gradient(90deg, rgba(69,188,240,1) 0%, rgba(108,210,255,1) 63%)',
      fontWeight: 'bold',
      borderRadius: '10px',
    },
    height: conditionForTabs('5rem', '36px', '36px'),
    minHeight: conditionForTabs('2rem', '2rem', '2rem'),
    marginTop: conditionForTabs('2rem', '1rem', ''),
    paddingBottom: conditionForTabs('2rem', '1rem', ''),
    fontSize: '13px',
    textTransform: 'none',
  };

  function findIndexes(path) {
    items.map((item, index) => {
      if (item.to === path) {
        setFirstValue(index);
        setSecondValue(0);
        setThirdValue(0);
        return;
      }
      item.child?.map((child, index) => {
        if (child.to === path) {
          setFirstValue(item.id);
          setSecondValue(child.id);
          setThirdValue(0);
          return;
        }
        child.child?.map((chi, index) => {
          if (chi.to === path) {
            setFirstValue(item.id);
            setSecondValue(child.id);
            setThirdValue(chi.id);
            return;
          }
        });
      });
    });
  }

  useEffect(() => {
    findIndexes(location.pathname);
  }, [location.pathname]);

  const handleDrawerOpen = () => {
    setOpen(true);
    toggleSidebarOpen();
  };

  const handleDrawerClose = () => {
    setOpen(false);
    toggleSidebarOpen();
  };

  const handleSideMenuOpen = () => {
    setSideMenuOpen(true);
  };

  const handleSideMenuClose = () => {
    setSideMenuOpen(false);
  };

  function headerPosition() {
    return absoluteHeaderUrls.includes(location.pathname);
  }

  const logoutMerchant = () => {
    auth.logOut();
    setIsLoggedIn(false);
    setUser({});
    setPermissions([]);
    setAppConfig(null);
    navigate('/');
  };

  return (
    <div
      className={`${headerPosition() && 'absolute z-[99999]'
        } w-full flex max-xl:justify-center max-md:justify-normal items-center hiddenForPrint`}
    >
      <div className="xl:w-3/12 w-2/12 h-[10rem] flex justify-start items-start p-4 max-md:hidden">
        {/* <img
          src={logoSmall}
          alt=""
          className="w-32 z-40 cursor-pointer"
          onClick={() => navigate('/')}
        /> */}
      </div>
      <div className="max-md:hidden xl:w-6/12 w-8/12 max-md:w-full h-[10rem] flex flex-col justify-center items-center relative">
        <div
          className={` absolute 
          ${conditionForTabs(
            'top-7 left-16  2xl:left-24 3xl:left-32',
            'top-7 left-16 2xl:left-24 3xl:left-32',
            'top-6 left-16 max-lg:left-12 2xl:left-24 3xl:left-36',
          )}  
          h-12 flex justify-start items-center p-4`}
        >
          <YerlemLogo
            className={`z-40 cursor-pointer ${conditionForTabs(
              'w-20 h-20',
              'w-20 h-20',
              'w-20 h-20',
            )}  bg-[#ECF3F8] rounded-full p-1 m-1`}
            onClick={() => navigate('/')}
          />
        </div>

        <div className="flex w-3/4 justify-center items-center ">
          <div
            className={`
            w-full absolute top-0 flex justify-end
            ${conditionForTabs(
              'h-[7rem] items-center',
              'h-[3rem] items-end',
              'h-[44px] items-end',
            )}  
            z-20 bg-[#e5e5e5] max-md:rounded-none px-20  shadow-sm custom-border-radius-10 tab-transition custom-drop-shadow
            `}
          >
            <Box
              sx={{
                width: '80%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Tabs
                value={firstValue}
                onChange={(e, newValue) => setFirstValue(newValue)}
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{ sx: { display: 'none' } }}
                TabScrollButtonProps={tabScrollButtonProps}
                sx={tabStyle}
              >
                {items?.map((item, index) => {
                  return item.instanceCanView ||
                    item.roleCanView ||
                    item.permissionCanView ? (
                    (checkPermission.hasInstance(item.instanceCanView) ||
                      checkPermission.hasRole(item.roleCanView) ||
                      checkPermission.hasPermission(
                        item.permissionCanView,
                      )) && (
                      <Tab
                        key={index}
                        sx={firstTabStyle}
                        label={item.parent}
                        // iconPosition="end"
                        // icon={item.icon}
                        onClick={() => {
                          if (item.to) {
                            navigate(item.to);
                            if (item.to.includes('dashboard'))
                              setSecondValue(0);
                          }
                        }}
                      />
                    )
                  ) : (
                    <Tab
                      key={index}
                      sx={firstTabStyle}
                      // icon={item.icon}
                      // iconPosition="end"
                      label={item.parent}
                      onClick={() => {
                        if (item.to) {
                          navigate(item.to);
                          if (item.to.includes('dashboard')) setSecondValue(0);
                        }
                      }}
                    />
                  );
                })}
              </Tabs>
            </Box>
          </div>
          <div
            className={`w-full absolute top-0 
            ${items[firstValue]?.child[secondValue]?.child
                ? 'h-[5.5rem]'
                : 'h-[6.5rem]'
              } 
            ${items[firstValue]?.child.length === 0 ? 'hidden' : ''} 
            flex justify-end items-end z-10 bg-[#f2f2f2] custom-border-radius-15 tab-transition px-24 max-md:rounded-none shadow-sm custom-drop-shadow`}
          >
            <Box
              sx={{
                width: '80%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Tabs
                value={secondValue}
                onChange={(e, newValue) => setSecondValue(newValue)}
                variant="scrollable"
                scrollButtons="auto"
                sx={tabStyle}
                TabIndicatorProps={{ sx: { display: 'none' } }}
                TabScrollButtonProps={tabScrollButtonProps}
              >
                {items[firstValue]?.child?.map((item, index) => {
                  return item.instanceCanView ||
                    item.roleCanView ||
                    item.permissionCanView ? (
                    checkPermission.hasInstance(
                      item.instanceCanView ||
                      checkPermission.hasRole(item.roleCanView) ||
                      checkPermission.hasPermission(item.permissionCanView),
                    ) && (
                      <Tab
                        key={index}
                        // icon={item.icon}
                        sx={secondTabStyle}
                        // iconPosition="end"
                        label={item.name ? item.name : item.parent}
                        onClick={() => {
                          if (item.to) {
                            navigate(item.to);
                            setThirdValue(0);
                          }
                        }}
                      />
                    )
                  ) : (
                    <Tab
                      key={index}
                      // icon={item.icon}
                      sx={secondTabStyle}
                      // iconPosition="end"
                      label={item.name ? item.name : item.parent}
                      onClick={() => {
                        if (item.to) {
                          navigate(item.to);
                          setThirdValue(0);
                        }
                      }}
                    />
                  );
                })}
              </Tabs>
            </Box>
          </div>
          <div
            className={`w-full absolute top-0 h-[10rem] 
              ${items[firstValue]?.child[secondValue]?.child ? '' : 'hidden'} 
              flex justify-center items-end z-0 bg-[#ffffff] custom-border-radius-18 tab-transition max-md:rounded-none px-5  custom-drop-shadow`}
          >
            <Box
              sx={{
                width: '80%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '1rem',
              }}
            >
              <Tabs
                value={thirdValue}
                onChange={(e, newValue) => setThirdValue(newValue)}
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{ sx: { display: 'none' } }}
                sx={tabStyle}
                TabScrollButtonProps={tabScrollButtonProps}
              >
                {items[firstValue]?.child[secondValue]?.child?.map(
                  (item, index) => {
                    return item.instanceCanView ||
                      item.roleCanView ||
                      item.permissionCanView ? (
                      (checkPermission.hasInstance(item.instanceCanView) ||
                        checkPermission.hasRole(item.roleCanView) ||
                        checkPermission.hasPermission(
                          item.permissionCanView,
                        )) && (
                        <Tab
                          // icon={item.icon}
                          // iconPosition="end"
                          key={index}
                          sx={thirdTabStyle}
                          label={item.name}
                          onClick={() => {
                            if (item.to) {
                              navigate(item.to);
                            }
                          }}
                        />
                      )
                    ) : (
                      <Tab
                        // icon={item.icon}
                        key={index}
                        // iconPosition="end"
                        sx={thirdTabStyle}
                        label={item.name}
                        onClick={() => {
                          if (item.to) {
                            navigate(item.to);
                          }
                        }}
                      />
                    );
                  },
                )}
              </Tabs>
            </Box>
          </div>
        </div>
      </div>

      <div
        className={`xl:w-3/12 w-2/12 h-[10rem] flex justify-end items-start p-4 ml-5 max-md:hidden  hiddenForPrint`}
      >
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          sx={{ ...(open && { display: 'none' }) }}
        >
          <AccountCircle fontSize="large" />
        </IconButton>
      </div>

      {/* Mobile Header */}
      <div className=" max-md:w-full h-[4.5rem] justify-center items-center hidden max-md:flex hiddenForPrint">
        <div className="w-1/3 h-12 flex justify-start items-center p-4 ">
          <IconButton
            onClick={handleSideMenuOpen}
            sx={{ ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <div className=" w-1/3 h-12 flex justify-center items-center p-4">
          <img
            src={logoSmall}
            alt=""
            onClick={() => navigate('/')}
            className={`w-32 z-40 cursor-pointer`}
          />
        </div>
        <div className="w-1/3 h-12 flex justify-end items-center mr-5">
          <IconButton
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: 'none' }) }}
          >
            <AccountCircle fontSize="large" />
          </IconButton>
        </div>
      </div>

      <TDrawer
        open={sidebarOpen}
        setOpen={setOpen}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        logoutMerchant={logoutMerchant}
        anchor={'right'}
      >
        {children}
      </TDrawer>

      <Drawer
        open={sideMenuOpen}
        onClose={handleSideMenuClose}
        TransitionComponent={Slide}
        transitionDuration={400}
        variant="persistent"
        sx={{
          '& .MuiDrawer-paper': {
            width: '25%',
            boxSizing: 'border-box',
            backgroundColor: '#F3F4F6',
            border: 'none',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',

            // [theme.breakpoints.between('md', 'xl')]: {
            //   display: 'none',
            // },
            [theme.breakpoints.down('md')]: {
              width: '100%',
            },
            [theme.breakpoints.up('md')]: {
              display: 'none',
            },
          },
        }}
        anchor={'left'}
      >
        <div className="flex justify-between items-center px-10 my-2 ">
          <YerlemLogo
            className={`z-40 cursor-pointer ${conditionForTabs(
              'w-20 h-20',
              'w-20 h-20',
              'w-20 h-20',
            )}  bg-[#ECF3F8] rounded-full p-1 m-1`}
            onClick={() => navigate('/')}
          />
          <IconButton onClick={handleSideMenuClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <SideMenu />
      </Drawer>
    </div>
  );
}

export default Header;
