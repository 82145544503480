
export const columns = [
  {
    field: 'plate',
    headerName: 'Araç Plaka',
    width: 120,
  },
  { field: 'chasis', headerName: 'Şase No', width: 200 },
  { field: 'brand', headerName: 'Marka', width: 160 },
  { field: 'model', headerName: 'Model', width: 160 },
];
