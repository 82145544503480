import React from 'react';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import services from './service/index';
import { useBoundStore } from '../../../stores';
import { Modal, Box, Typography } from '@mui/material';
import TStepper from './components/Stepper';
import CustomInput from '../../../components/CustomInput';
import { fields } from './constants/create_vehicle_fields';
import { plate_fields } from './constants/create_insurance_fields';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Pageable } from '../../../ui-sdk/core/service/pageable';
import { Sort } from '../../../ui-sdk/core/service/sort';
import { columns } from './constants/table_columns_data';
import { toast } from 'react-toastify';

const boxStyle = {
  outline: 'none',
  position: 'absolute',
  top: '50%',
  left: '50%',
  borderRadius: 30,
  transform: 'translate(-50%, -50%)',
  width: 937,
  height: 'auto',
  backgroundColor: 'white',
  boxShadow: 24,
  padding: 30,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

function EditVehicle() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [modalOpen, setModalOpen] = useState(true);
  const [fieldsEmpty, setFieldsEmpty] = useState(false);
  const [plates, setPlates] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [page, setPage] = useState(0);
  const [sortModel, setSortModel] = useState([]);
  const [count, setCount] = useState(0);
  const [plateFormData, setPlateFormData] = React.useState({
    plateId: '',
    plate: '',
    contrat: '',
    capacity: '',
    purchased: '',
    status: '',
    selfOwned: '',
    contratEndDate: null,
    description: '',
    plateType: '',
  });

  const [realitonData, setRelationData] = React.useState({
    plateId: '',
    vehicleId: '',
  });
  const [formData, setFormData] = React.useState({
    ...plateFormData,
  });

  const XLSX = require('xlsx');

  async function getPlateData() {
    setIsLoading(true);
    try {
      const handleField = (field) => {
        const sortColumn = columns.filter(
          (column) => column.field === field,
        )[0];
        const res = sortColumn?.sortParameter
          ? sortColumn?.sortParameter
          : sortColumn?.field || 'createdAt';
        return res;
      };

      const { field, sort } =
        sortModel.length > 0
          ? sortModel[0]
          : {
            field: 'createdAt',
            sort: 'desc',
          };

      const params = {};

      const pageable = new Pageable(
        page,
        10,
        new Sort(handleField(field) || 'createdAt', sort),
      );
      const result = await services.getAllPlate(params, pageable);
      const data = result?.data;
      setCount(data?.totalPages);
      setPlates(data?.content);
    } catch {
      toast.error('Plakalar getirilirken hata oluştu.');
    }

    setIsLoading(false);
  }
  async function getVehicleData() {
    setIsLoading(true);
    try {
      const pageableAllVehicle = new Pageable(0, 9999999, null);
      const resultVehicle = await services.findAllVehicle(pageableAllVehicle);
      setVehicles(resultVehicle?.data?.content);
    } catch (e) {
      toast.error('Araçlar getirilirken hata oluştu.');
    }
    setIsLoading(false);
  }
  async function getPlateDataById() {
    setIsLoading(true);
    try {
      const result = await services.getPlateByPlateId(id);
      const data = result?.data;
      setPlateFormData({
        plateId: data.plateId,
        plate: data.plate,
        contrat: data.contrat,
        capacity: data.capacity,
        purchased: data.purchased,
        status: data.status,
        selfOwned: data.selfOwned,
        contratEndDate: data.contratEndDate,
        description: data.description,
        plateType: data.plateType.plateTypeId,
        createdAt: data.createdAt,
      });
    } catch (e) {
      toast.error('Plaka getirilirken hata oluştu.');
    }
    setIsLoading(false);
  }
  const getDatas = async () => {
    getPlateData();
    getVehicleData();
  };
  useEffect(() => {
    getPlateData();
    getVehicleData();
    getPlateDataById();
  }, []);

  const setData = () => {
    const data = {
      ...plateFormData,
    };

    setFormData({
      ...plateFormData,
    });

    return data;
  };
  const hanldeUpdateRow = async (row) => { };

  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          navigate(-1);
        }}
      >
        <Box style={boxStyle}>
          <TStepper
            steps={['Plaka Bilgileri', 'Plaka Araç Eşleştirme']}
            optionalSteps={[1]}
            formData={formData}
            setData={setData}
            getDatas={getDatas}
            getVehicleData={getVehicleData}
            realitonData={realitonData}
            helperText={helperText}
            fieldsEmpty={fieldsEmpty}
            setModalOpen={setModalOpen}
          >
            {/* Plaka Bilgileri */}
            <>
              <div className="mt-5">
                <div>
                  <Typography
                    variant="h5"
                    color="initial"
                    gutterBottom
                    textAlign={'center'}
                  >
                    Yeni Plaka Ekle
                  </Typography>
                </div>

                <div className="flex flex-row flex-wrap justify-start items-center gap-3 w-full">
                  {fields().map((field, index) => {
                    return (
                      <div className=" flex  flex-wrap h-20" key={index}>
                        <CustomInput
                          key={index}
                          type={field.type}
                          label={field.placeHolder}
                          value={plateFormData[field.key]}
                          fullWidth={true}
                          sx={{ width: '284px' }}
                          items={field.items}
                          size={'medium'}
                          onChange={(key, value) => {
                            setPlateFormData({
                              ...plateFormData,
                              [field.key]: value,
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
            {/* Eşleşme Bilgileri */}
            <>
              <div className="mt-5 ">
                <div>
                  <Typography
                    variant="h5"
                    color="initial"
                    gutterBottom
                    textAlign={'center'}
                  >
                    Plaka araç eşleşmesi
                  </Typography>
                </div>

                <div className="flex flex-row justify-evenly items-center gap-3 w-full mb-3 mt-5">
                  {plate_fields(plates, vehicles).map((field, index) => {
                    return (
                      <div className=" flex  flex-wrap h-20" key={index}>
                        <CustomInput
                          key={index}
                          type={field.type}
                          label={field.placeHolder}
                          value={realitonData[field.key]}
                          loading={isLoading}
                          fullWidth={true}
                          sx={{ width: '284px' }}
                          options={field.options}
                          items={field.items}
                          size={'medium'}
                          onChange={(key, value) => {
                            setRelationData({
                              ...realitonData,
                              [field.key]: value,
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                {/*<div>
                  <Typography
                    variant="h6"
                    color="initial"
                    gutterBottom
                    textAlign={'left'}
                  >
                    Kasko
                  </Typography>
                </div>
                <div className="flex flex-row flex-wrap  items-center gap-3 w-full mb-3 ">
                  {insurance_fields().map((field, index) => {
                    return (
                      <div className=" flex  flex-wrap h-20" key={index}>
                        <CustomInput
                          key={index}
                          type={field.type}
                          label={field.placeHolder}
                          value={kaskoInsuranceFormData[field.key]}
                          fullWidth={false}
                          size={'medium'}
                          sx={{ width: '284px' }}
                          onChange={(key, value) => {
                            setKaskoInsuranceFormData({
                              ...kaskoInsuranceFormData,
                              [field.key]: value,
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                <div>
                  <Typography
                    variant="h6"
                    color="initial"
                    gutterBottom
                    textAlign={'left'}
                  >
                    Koltuk Sigortası
                  </Typography>
                </div>
                <div className="flex flex-row flex-wrap  items-center gap-3 w-full mb-3">
                  {insurance_fields().map((field, index) => {
                    return (
                      <div className=" flex  flex-wrap h-20" key={index}>
                        <CustomInput
                          key={index}
                          type={field.type}
                          label={field.placeHolder}
                          value={seatInsuranceFormData[field.key]}
                          fullWidth={false}
                          size={'medium'}
                          sx={{ width: '284px' }}
                          onChange={(key, value) => {
                            setSeatInsuranceFormData({
                              ...seatInsuranceFormData,
                              [field.key]: value,
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>*/}
              </div>
            </>
            {/* Muayene Bilgileri 
            <>
              <div className="mt-5">
                <Typography
                  variant="h5"
                  color="initial"
                  gutterBottom
                  textAlign={'center'}
                >
                  Muayene Bilgileri
                </Typography>
              </div>
              <div>
                <div>
                  <Typography
                    variant="h6"
                    color="initial"
                    gutterBottom
                    textAlign={'left'}
                  >
                    Zorunlu Araç Muayenesi
                  </Typography>
                </div>
                <div className="flex flex-row flex-wrap  items-center gap-3 w-full mb-3">
                  {inspection_fields().map((field, index) => {
                    return (
                      <div className=" flex  flex-wrap h-20" key={index}>
                        <CustomInput
                          key={index}
                          type={field.type}
                          label={field.placeHolder}
                          value={vehicleInspectionFormData[field.key]}
                          fullWidth={false}
                          size={'medium'}
                          sx={{ width: '284px' }}
                          onChange={(key, value) => {
                            setVehicleInspectionFormData({
                              ...vehicleInspectionFormData,
                              [field.key]: value,
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                <div>
                  <Typography
                    variant="h6"
                    color="initial"
                    gutterBottom
                    textAlign={'left'}
                  >
                    Zorunlu Araç Egzoz Muayenesi
                  </Typography>
                </div>
                <div className="flex flex-row flex-wrap  items-center gap-3 w-full mb-3">
                  {inspection_fields().map((field, index) => {
                    return (
                      <div className=" flex  flex-wrap h-20" key={index}>
                        <CustomInput
                          key={index}
                          type={field.type}
                          label={field.placeHolder}
                          value={exhaustInspectionFormData[field.key]}
                          fullWidth={false}
                          sx={{ width: '284px' }}
                          size={'medium'}
                          onChange={(key, value) => {
                            setExhaustInspectionFormData({
                              ...exhaustInspectionFormData,
                              [field.key]: value,
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
            */}
          </TStepper>
        </Box>
      </Modal>
    </div>
  );
}

export default EditVehicle;
